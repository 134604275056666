import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { useHistory } from "react-router-dom";

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';
import ConsultantAllocations from './ConsultantAllocations';

const useStyles = makeStyles((theme) => ({
    page: {
        padding: 20,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
}));

const tabProps = (index) => {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tab-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const Settings = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [tab, setTab] = useState(history && history.location && history.location.state ? history.location.state.tab : 0);

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    return (
        <React.Fragment>
            <Paper square>
                <AppBar position="static">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="Settings tabs"
                    >
                        {isAccessGranted([Privileges.CONSULTANT_ALLOCATIONS_REPORT]) &&
                            <Tab label={
                                <Button className={classes.createNewButton} startIcon={<AssessmentIcon />} color="secondary">
                                    Consultant Allocations
                                </Button>
                            } {...tabProps(0)} />
                        }

                    </Tabs>
                </AppBar>
                {isAccessGranted([Privileges.CONSULTANT_ALLOCATIONS_REPORT]) &&
                    <TabPanel value={tab} index={0}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <ConsultantAllocations setMessageBar={setMessageBar} />
                            </Grid>
                        </div>}>
                    </TabPanel>
                }

                <Portal>
                    <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                        <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </Portal>

            </Paper>
        </React.Fragment>
    );
};

export default Settings;