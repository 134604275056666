import { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ColorPicker } from 'material-ui-color';
import { MuiColorInput } from 'mui-color-input'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { AppConfig } from '../../Config';
import useHttp from '../utils/http';
import { useFlatList } from '../OrgStructureContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '80%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 3px 12px #00000017',
        padding: theme.spacing(2, 4, 3),
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    page: {
        padding: 20,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AddAllocationType = ({ title, data, open, handleAllocationTypeData, handleFormAction, isEditMode, isSubmitBtnClicked, setMessageBar }) => {
    const classes = useStyles();
    let flatList = useFlatList();

    const [engagementTypes, setEngagementTypes] = useState([]);
    const [departments, setDepartments] = useState()

    const { handleRequest } = useHttp();

    const getEngagementTypes = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementTypes;

        handleRequest(endPointUrl, 'GET', null, (resData) => {
            setEngagementTypes(resData);
        }, () => {
            setMessageBar("Error occurred in loading Engagement Types", true, "error");
        })
    }, []);

    useEffect(() => {
        getEngagementTypes();
    }, []);


    useEffect(() => {
        let departments = [];

        if (flatList && flatList.length > 1) {
            departments = flatList[1] ? flatList[1].sort() : []; // This is the current org structure
        }

        setDepartments(departments);
    }, [flatList]);

    // Used to validate each required field and add an error in each if not valid
    const isFieldValueValid = (field) => {
        // Check if isSubmitBtnClicked is true and needed value is available for the field
        return !(isSubmitBtnClicked && (data[field] === "" || data[field] === null || data[field] === undefined));
    };

    const handleInputChange = (e) => {
        handleAllocationTypeData(e.target.name, e.target.value);
    };

    const handleColorChange = (color) => {
        handleAllocationTypeData("color", color);
    }

    const handleSubmitDialog = () => {
        handleFormAction(true, isEditMode);
    };

    const handleClose = () => {
        handleFormAction(false);
    };

    const handleDepartment = (event, value) => {
        handleAllocationTypeData("department", value);
    };

    return (
        <Modal
            aria-labelledby="alloc-type-popup-title"
            aria-describedby="alloc-type-popup-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper square className={classes.paper}>
                    <Typography variant="h5" display="block" gutterBottom>
                        {title}
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl} variant="outlined" required error={!isFieldValueValid("engagementTypeId")} >
                            <InputLabel id="demo-simple-select-helper-label">Engagement Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={data.engagementTypeId}
                                name='engagementTypeId'
                                onChange={handleInputChange}
                                label="Engagement Type"
                            >
                                {engagementTypes && engagementTypes.length > 0 ? (engagementTypes.map(type => (
                                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                ))) :
                                    <MenuItem value="" disabled>No options</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="alloc-type-text"
                                name="allocationTypeName"
                                label="Allocation Type"
                                variant="outlined"
                                value={data.allocationTypeName}
                                onChange={handleInputChange}
                                required
                                error={!isFieldValueValid("allocationTypeName")}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="def-eng-code-text"
                                name="defaultEngagementCode"
                                label="Default Engagement Code"
                                variant="outlined"
                                value={data.defaultEngagementCode}
                                onChange={handleInputChange}
                                required
                                error={!isFieldValueValid("defaultEngagementCode")}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="engagement-code-suffix-text"
                                name="engagementCodeSuffix"
                                label="Engagement Code Suffix"
                                variant="outlined"
                                value={data.engagementCodeSuffix}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                options={departments}
                                getOptionLabel={(option) => option}
                                id="department"
                                value={data.department}
                                onChange={handleDepartment}
                                renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl} variant="outlined" >
                            <InputLabel id="demo-simple-select-helper-label">Notify to Allocations</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={data.emailToAllocations}
                                name='emailToAllocations'
                                onChange={handleInputChange}
                                label="Notify to Allocations"
                            >
                                <MenuItem value="No">No</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl} variant="outlined" >
                            {/* <MuiColorInput defaultValue='#000' /> */}
                            {/* <TextField type={"color"} name="color" value={data.color} onChange={handleInputChange} variant="outlined"></TextField> */}
                            <ColorPicker className={classes.formControl} variant="outlined" name="color" value={data.color} onChange={handleColorChange} sx={{width: '500px'}} />
                        </FormControl>
                    </Grid>

                    {/* TODO: Color Code: Update styles to adhere to other fields */}

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        margin="10"
                    >
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" margin="20px" color="secondary" onClick={() => { handleSubmitDialog() }}>
                                {isEditMode ? "Update" : "Add"}
                            </Button>
                        </FormControl>
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" margin="20px" color="primary" onClick={() => { handleClose() }}>
                                Cancel
                            </Button>
                        </FormControl>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
};

export default AddAllocationType;