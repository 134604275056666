import { Fragment, useEffect, useState, useCallback } from "react";

import Paper from '@material-ui/core/Paper';
import AllocationsBy from "./AllocationsBy";
import { AppConfig, FormConstants } from "../../Config";
import { getDateFromString, getDateObjectFromString, isPastDateForAlloc, getAllowedPastDateForAlloc, isInternal } from "../utils/utils";
import useHttp from '../utils/http';
import { PRE_ENGAGEMENT_RELAX_PERIOD_DAYS, POST_ENGAGEMENT_RELAX_PERIOD_DAYS, PRE_ENGAGEMENT_OVERHEAD_DAYS, POST_ENGAGEMENT_OVERHEAD_DAYS } from '../utils/AppConstants';

import { useAllocationTypes } from "../DataContext";

const Allocations = (props) => {
    let allocationTypes = useAllocationTypes();

    const [data, setData] = useState({});
    const { handleRequest } = useHttp();

    const [requestStatus, setRequestStatus] = useState("");

    const getEngagementData = useCallback(async () => {
        const engagementId = props.match.params.id;
        if (engagementId) {
            const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementById.replace("$id", engagementId);

            handleRequest(endPointUrl, 'GET', null, (engagement) => {
                let formattedData = {
                    engagementId: engagement.id,
                    customerName: engagement.customerName,
                    opportunity: engagement.opportunityName,
                    engagementCode: engagement.engagementCode,
                    allocationTypeName: getAllocationTypeNameById(engagement.allocationType),
                    engagementNature: engagement.engagementNature,
                    country: engagement.country,
                    startDate: getDateObjectFromString(getDateFromString(engagement.startDate)),
                    endDate: getDateObjectFromString(getDateFromString(engagement.endDate)),
                    productName: engagement.productName,
                    description: engagement.description,
                    address: engagement.address,
                    customerContact: engagement.customerContact,
                    businessDomain: engagement.businessDomain,
                    opportunityOwner: engagement.opportunityOwner,
                    technicalOwner: engagement.technicalOwner,
                    isContractsSigned: engagement.isContractsSigned,
                    engagementScope: engagement.engagementScope,
                    comment: engagement.comment,
                    requestStatus: engagement.requestStatus,
                    engagementStatus: engagement.engagementStatus,
                };

                let minStartDate = getDateObjectFromString(engagement.startDate);
                let maxEndDate = getDateObjectFromString(engagement.endDate);

                minStartDate.setDate(minStartDate.getDate() - PRE_ENGAGEMENT_RELAX_PERIOD_DAYS);
                maxEndDate.setDate(maxEndDate.getDate() + POST_ENGAGEMENT_RELAX_PERIOD_DAYS);

                let minPrePrepStartDate = getDateObjectFromString(engagement.startDate);
                let maxPostPrepEndDate = getDateObjectFromString(engagement.endDate);

                minPrePrepStartDate.setDate(minPrePrepStartDate.getDate() - PRE_ENGAGEMENT_OVERHEAD_DAYS);
                maxPostPrepEndDate.setDate(maxPostPrepEndDate.getDate() + POST_ENGAGEMENT_OVERHEAD_DAYS);

                setData({
                    ...data,
                    ...formattedData,
                    productId: engagement.productId,
                    engagementTypeId: engagement.type,
                    allocationTypeObj: allocationTypes && allocationTypes.find(type => (type.id === engagement.allocationType)),

                    // startDateObj: getDateObjectFromString(getDateFromString(engagement.startDate)),
                    // endDateObj: getDateObjectFromString(getDateFromString(engagement.endDate)),

                    minStartDate: isPastDateForAlloc(new Date(minStartDate)) ? getAllowedPastDateForAlloc() : new Date(minStartDate),
                    maxStartDate: getDateObjectFromString(getDateFromString(engagement.endDate)),
                    minEndDate: isPastDateForAlloc(getDateObjectFromString(getDateFromString(engagement.startDate))) ? getAllowedPastDateForAlloc() : getDateObjectFromString(getDateFromString(engagement.startDate)),
                    maxEndDate: new Date(maxEndDate),
                    minPrePrepStartDate: isPastDateForAlloc(new Date(minPrePrepStartDate)) ? getAllowedPastDateForAlloc() : new Date(minPrePrepStartDate),
                    maxPostPrepEndDate: new Date(maxPostPrepEndDate),
                });

                setRequestStatus(engagement.requestStatus);
            });
        }
    }, [allocationTypes]);

    const getAllocationTypeNameById = (id) => {
        return (allocationTypes && allocationTypes.find(type => (type.id === id))) ? allocationTypes.find(type => (type.id === id)).allocationTypeName : id;
    };

    useEffect(() => {
        if (allocationTypes && allocationTypes.length > 0) {
            getEngagementData();
        }
    }, [props.match.params.id, allocationTypes]);

    return (
        <Fragment>
            <Paper square>
                <AllocationsBy
                    data={data} engagementId={props.match.params.id} isInternalEngagement={isInternal(data.engagementTypeId)}
                    isMarketingEvent={data.engagementTypeId === FormConstants.EngagementTypes.MarketingEvent.id}
                    initRequestStatus={requestStatus} isHourlyBasis="false" isEngagementDataPanelAvail={true}
                    isTravelRequiredAvail={true} allocRecordsTableAvail={true} isByPartnerEnabled={true}
                    searchByMailHeader="Allocations" searchByTeamHeader="Allocations" searchByPartnerHeader="Allocations"
                    headerActionBtnVisible={true} getEngagementData={getEngagementData} isTimelineTimeZoneEnabled={true} />
            </Paper>
        </Fragment>
    );
};

export default Allocations;