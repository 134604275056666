import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useOrgStructure } from './OrgStructureContext';
import BackdropProgress from './BackdropProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%'
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const OrgStructureDropdowns = ({ dropdownSize = 3, size = "medium", selectedValues, setSelectedValues, isPartnerIncluded = false }) => {
    const classes = useStyles();
    let { orgStructureData, orgLevelCount, levelData, isOrgStructureLoading } = useOrgStructure();

    let [filteredOrgStructureData, setFilteredOrgStructureData] = useState([]);

    const updateDropdownLists = (orgStructureData, selectedValues, isPartnerIncluded) => {
        let filteredItems = [];
        let distinctFilteredItems = [];
        let filteredOrgStructureData = [];

        let fullOrgStructureData = JSON.parse(JSON.stringify(orgStructureData));

        if (isPartnerIncluded) {
            let name = 'PARTNER';
            let parents = [];
            let level = 0;
            let child;

            fullOrgStructureData.forEach(item => {
                if (level !== orgLevelCount - 1) {
                    child = {
                        level: level,
                        name: name,
                        parents: parents
                    };

                    item.options.push(child);

                    parents.push({
                        level: level,
                        type: item.labelId,
                        name: name
                    });

                    level++;
                }
            });
        }

        fullOrgStructureData.forEach((item) => {
            filteredItems = item.options.filter(option => {
                let level = option.level;
                let parents = option.parents;

                if (level > 0) {
                    let isMatching = true;
                    let parent;

                    while (level > 0) {
                        level = level - 1;

                        parent = parents.find(pItem => pItem.level === level);

                        if (parent && parent.type && selectedValues[parent.type] !== undefined && selectedValues[parent.type] !== "-1" && selectedValues[parent.type] !== "" && selectedValues[parent.type] !== parent.name) {
                            isMatching = false;
                            break;
                        }
                    }

                    return isMatching;
                } else {
                    return true;
                }
            });

            distinctFilteredItems = [...new Map(filteredItems.map((item) => [item["name"], item])).values()].sort((a, b) => -b.name.localeCompare(a.name));

            filteredOrgStructureData.push({
                label: item.label,
                labelId: item.labelId,
                options: distinctFilteredItems
            });
        });

        setFilteredOrgStructureData(filteredOrgStructureData);
    };

    useEffect(() => {
        updateDropdownLists(orgStructureData, selectedValues, isPartnerIncluded);
    }, [orgStructureData, selectedValues, isPartnerIncluded]);

    const handleInputChange = (e, level) => {
        let totalLevels = orgLevelCount;
        let levelName;
        let newSelectedValues = {};

        for (let i = level + 1; i < totalLevels; i++) {
            levelName = levelData.find(item => i === item.level).levelName;
            newSelectedValues[levelName] = "-1";
        };

        setSelectedValues({
            ...selectedValues,
            [e.target.name]: e.target.value,
            ...newSelectedValues
        });
    };

    return (
        <React.Fragment>

            {filteredOrgStructureData && filteredOrgStructureData.map((item, structure_index) => (
                <Grid item xs={dropdownSize}>

                    <FormControl key={'form-' + structure_index} className={classes.formControl} variant="outlined" size={size}>
                        <InputLabel id={"label-id-" + item.label} key={'label-' + structure_index}>{item.label} </InputLabel>
                        <Select
                            labelId={"label-id-" + item.labelId}
                            id={item.labelId}
                            name={item.labelId}
                            label={item.label}
                            key={'select-' + structure_index}
                            value={selectedValues[item.labelId] ? selectedValues[item.labelId] : ""}
                            onChange={(e) => { handleInputChange(e, structure_index) }}
                        >
                            <MenuItem key={"all-" + structure_index} value="-1">
                                All
                            </MenuItem>

                            {item.options.map((option, index) => (
                                <MenuItem key={index + "-" + structure_index} value={option.name}>{option.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            ))}

            <BackdropProgress open={isOrgStructureLoading} />

        </React.Fragment>
    );
};

export default OrgStructureDropdowns;