import React, { useState, useReducer, useCallback, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AllocationsBy from './AllocationsBy';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 3px 12px #00000017',
        padding: theme.spacing(2, 4, 3),
        maxWidth: '90%',
        width: '90%',
        maxHeight: '90%',
        'overflow-y': 'scroll',
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    page: {
        padding: 20,
    },
    btnControl: {
        textAlign: "right"
    },
    icon: {
        // display: 'block',
        // textAlign: 'right',
        float: 'right'
    }
}));

const AllocationsModalPopup = ({ data, isInternalEngagement, isMarketingEvent, requestStatus, open, handleFormAction, isHourlyBasis, onCloseModalPopup}) => {
    const classes = useStyles();

    const handleClose = () => {
        handleFormAction(false);
        onCloseModalPopup();
    };

    return (
        <Fragment>
            <Modal
                aria-labelledby="allocation-modal-title"
                aria-describedby="allocation-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                // disableScrollLock={true}
                BackdropProps={{
                    timeout: 500,
                }}
                // onClose={handleClose}
            >
                <Fragment>
                    <Fade in={open}>
                        <Paper square className={classes.paper}>
                            <Typography variant="h5" display="block" gutterBottom>
                                Internal Allocations
                                <IconButton aria-label="close" size="small" className={classes.icon}>
                                    <HighlightOffIcon fontSize="medium"  onClick={() => { handleClose() }} />
                                </IconButton>
                            </Typography>
                            <AllocationsBy data={data} engagementId={data?.engagementId} isInternalEngagement={isInternalEngagement} isMarketingEvent={isMarketingEvent} initRequestStatus={requestStatus} isHourlyBasis={isHourlyBasis} allocRecordsTableAvail={true} isMainTabs={false} isTravelRequiredAvail={true} isTimelineTimeZoneEnabled={true} />
                        </Paper>
                    </Fade>
                </Fragment>
            </Modal>
        </Fragment>


    );
};

export default AllocationsModalPopup;