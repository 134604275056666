import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import AllocationsTimeline from './AllocationsTimeline';
import { AppConfig, DATE_DISPLAY_FORMAT } from '../../Config';
import EngagementDataPanel from "../engagements/EngagementDataPanel";

import 'react-calendar-timeline/lib/Timeline.css';
import AllocationsHeaderAction from './AllocationsHeaderAction';
import OrgStructureDropdowns from '../OrgStructureDropdowns';
import useHttp from '../utils/http';
import { useAllocationTypes, useJobBandData } from "../DataContext";
import { useOrgStructure } from '../OrgStructureContext';
import timezones from 'timezones-list';
import AllocationsTimelineExtended from './AllocationsTimelineExtended';
import { JOB_ROLE_DEFAULT_COLOR } from '../utils/AppConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%',
    },

    btnControl: {
        margin: theme.spacing(2),
        minWidth: 200,
        float: 'right'
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControlTimezone: {
        margin: theme.spacing(1),
        marginBottom: 0,
        minWidth: 300,
    }
}));

const AllocationsByTeam = ({ isInternalEngagement, isMarketingEvent, timelineStartDate, timelineEndDate, engStartDate, engEndDate, requestStatus, onClickAddBtn, isSearchByAllocTypeEnabled = false, isSearchByDatesEnabled = false, allocationsItemListTeam, isEngagementDataPanelAvail, data, header, headerActionBtnVisible, getAllocationsByEmail, setMessageBar, onAllocationItemDoubleClick, setAllocationsItemListTeam, onClickUpdateRequestStatus, isTimelineTimeZoneEnabled = false, timelineTimeZoneObj, handleTimelineTimeZoneChange, setTimelineStartDate, setTimelineEndDate, isTeamAllocPage, updatePageAuto, setUpdatePageAuto, selectedStartDateAuto, selectedEndDateAuto, setOpenBackDropProgress }) => {
    const classes = useStyles();
    let timer;

    let allocationTypes = useAllocationTypes();
    let jobBandData = useJobBandData();

    let { loadingErrMsg } = useOrgStructure();

    const { handleComponentEncodedRequest } = useHttp();

    const [emails, setEmails] = useState([{}]); // Emails shown in timeline
    const [selectedValues, setSelectedValues] = useState({}); // Selected values for org structure dropdowns
    const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(false);
    const [selectedAllocationTypes, setSelectedAllocationTypes] = useState([]);
    const [hideTimeline, setHideTimeline] = useState(false);
    const [isTimelineRefreshed, setIsTimelineRefreshed] = useState(false);

    const getEmailsFromOrgStructure = useCallback(async () => {
        let emailOptions = [];

        const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployees;
        let paramsArr = [`employeeStatuses=${encodeURIComponent("Active,Marked Leaver")}`, `excludedEmploymentTypes=Internship`];
        let orgStructureItems = ["bu", "department", "team"];

        for (let item of orgStructureItems) {
            if (selectedValues[item] && selectedValues[item] != "-1") {
                paramsArr.push(`${item}=${encodeURIComponent(selectedValues[item])}`);
            }
        }

        let params = paramsArr.join('&');

        // TODO: This is temporary until the org structure API is updated to use type businessUnit instead of type bu
        params = params.replace("bu", "businessUnit");

        const requestUrl = `${endPointUrl}?${params}`;

        handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
            if (data.length > 0) {
                data.forEach(employee => {
                    if (!emailOptions.find(emailOption => emailOption.id === employee.email)) {
                        emailOptions.push({
                            id: employee.workEmail,
                            title: employee.workEmail,
                            tooltip: (employee.firstName && employee.lastName) ? `${employee.firstName} ${employee.lastName}` : employee.workEmail,
                            color: jobBandData.find(item => item.roleName === employee.jobRole) && jobBandData.find(item => item.roleName === employee.jobRole).color ?
                                jobBandData.find(item => item.roleName === employee.jobRole).color : JOB_ROLE_DEFAULT_COLOR
                        })
                    }
                });
            } else {
                setMessageBar("No records found for the search", true, 'info');
            }

            setEmails(emailOptions.sort((a, b) => -b.title.localeCompare(a.title)));
            setAllocationsItemListTeam([]);

            getAllocationsByEmail(emailOptions.map(email => email.id), timelineStartDate, timelineEndDate, selectedAllocationTypes, undefined, true);

        }, () => {
            setMessageBar("An error occurred in search", true, 'error');
        }, setOpenBackDropProgress);
    }, [selectedValues, selectedAllocationTypes, timelineStartDate, timelineEndDate]);

    const onRefreshTimeline = (startDate, endDate) => {
        setIsTimelineRefreshed(true);
        setOpenBackDropProgress(true);

        if (emails && emails.length > 0 && Object.keys(emails[0]).length !== 0) {
            getAllocationsByEmail(emails.map(email => email.id), startDate, endDate, selectedAllocationTypes, () => {
                setIsTimelineRefreshed(false);
            }, true);
        } else {
            timer = setTimeout(() => {
                setIsTimelineRefreshed(false);
                setOpenBackDropProgress(false);
            }, 100)
        }
    }


    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (loadingErrMsg) {
            setMessageBar(loadingErrMsg, true, 'error');
        }
    }, [loadingErrMsg]);

    useEffect(() => {
        if (updatePageAuto && selectedStartDateAuto && selectedEndDateAuto) {
            setSelectedAllocationTypes([]);

            setTimelineStartDate(selectedStartDateAuto);
            setTimelineEndDate(selectedEndDateAuto);


            getAllocationsByEmail(emails.map(email => email.id), selectedStartDateAuto, selectedEndDateAuto, [], undefined, true);
            setUpdatePageAuto(false);
        }
    }, [updatePageAuto, selectedStartDateAuto, selectedEndDateAuto]);


    const onClickSearchBtn = () => {
        getEmailsFromOrgStructure();
    };

    const onItemDoubleClick = (itemId, e, time) => {
        onAllocationItemDoubleClick(itemId);
    }

    const handleStartDateChange = (date) => {
        setTimelineStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setTimelineEndDate(date);
    };

    const onAllocationTypeChange = (event, values) => {
        setSelectedAllocationTypes(values)
    };

    return (
        <React.Fragment>
            <Grid container spacing={1}>

                {header &&
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" gutterBottom>
                            {header}
                            {headerActionBtnVisible &&
                                <AllocationsHeaderAction isInternalEngagement={isInternalEngagement} requestStatus={requestStatus} onClickUpdateRequestStatus={onClickUpdateRequestStatus} />
                            }
                        </Typography>
                    </Grid>
                }

                {isEngagementDataPanelAvail &&
                    <EngagementDataPanel data={data} isInternalEngagement={isInternalEngagement} isMarketingEvent={isMarketingEvent} />
                }
                <Grid container item xs={12} spacing={1}>

                    <OrgStructureDropdowns dropdownSize={isSearchByAllocTypeEnabled ? 2 : 3} selectedValues={selectedValues} setSelectedValues={setSelectedValues} />

                    {isSearchByAllocTypeEnabled && (
                        <Grid item xs={4}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    options={allocationTypes.sort((a, b) => -b.engagementTypeName.localeCompare(a.engagementTypeName))}
                                    groupBy={(option) => option.engagementTypeName}
                                    getOptionLabel={(option) => option.allocationTypeName}
                                    id="alloc-type"
                                    multiple
                                    value={selectedAllocationTypes}
                                    onChange={onAllocationTypeChange}
                                    renderInput={(params) => <TextField {...params} label="By Allocation Types" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                    )}



                    <Grid item xs={2}>
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" color="secondary" disabled={isSearchBtnDisabled} onClick={() => { onClickSearchBtn() }}>
                                Search
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                {!hideTimeline && !updatePageAuto &&
                    <AllocationsTimelineExtended startDate={timelineStartDate} endDate={timelineEndDate} engStartDate={!isTeamAllocPage && engStartDate} engEndDate={!isTeamAllocPage && engEndDate} onClickAddBtn={onClickAddBtn} onItemDoubleClick={onItemDoubleClick} groups={emails} items={allocationsItemListTeam} isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled} timezones={timezones} handleTimelineTimeZoneChange={handleTimelineTimeZoneChange} timelineTimeZoneObj={timelineTimeZoneObj}
                        isSearchByDatesEnabled={true} timelineStartDate={timelineStartDate} timelineEndDate={timelineEndDate} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} onRefreshTimeline={onRefreshTimeline} isTimelineRefreshed={isTimelineRefreshed} setMessageBar={setMessageBar} />
                }
            </Grid>

        </React.Fragment>
    );
};

export default AllocationsByTeam;