import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useHttp from '../utils/http';
import { AppConfig } from '../../Config';
import { DIALOG_TYPES, ROLE_SETTINGS } from "../utils/AppConstants";
import ConfirmationDialog from '../ConfirmationDialog';
import BackdropProgress from '../BackdropProgress';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },
    page: {
        padding: 20,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //boxShadow: theme.shadows[5],
        boxShadow: '0px 3px 12px #00000017',
        padding: theme.spacing(2, 4, 3),
        minWidth: 1000,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    btnControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
}));

const ACTIONS = {
    ADD_NEW_ROLE: 'ADD_NEW_ROLE',
    UPDATE_ROLE_PRIVILEGES: 'UPDATE_ROLE_PRIVILEGES',
    SHOW_PAGE_INFO: 'SHOW_PAGE_INFO'
}

const RoleSettings = ({ setMessageBar }) => {
    const { handleRequest } = useHttp();

    const classes = useStyles();

    const [roles, setRoles] = useState([]);
    const [privileges, setPrivileges] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [isBackdropProgressOpen, setIsBackdropProgressOpen] = useState(false);

    const roleReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.ADD_NEW_ROLE:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.CONFIRM_DIALOG
                }
            case ACTIONS.UPDATE_ROLE_PRIVILEGES:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.CONFIRM_DIALOG
                }
            case ACTIONS.SHOW_PAGE_INFO:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.INFO_DIALOG
                }
            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails, dialogType }, dispatchForm] = useReducer(roleReducer, { confirmDialogDetails: { open: false }, dialogType: '' });


    const getRoles = useCallback(async (newRoleName) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getRoles;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            setRoles(data);

            if (data.length > 0) {
                if (newRoleName && data.find(item => item.name === newRoleName)) {
                    setSelectedRole(data.find(item => item.name === newRoleName));
                } else if (!selectedRole) {
                    setSelectedRole(data[0]);
                }
            }
        })
    }, []);

    const getPrivileges = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getPrivileges;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            let checkboxes = [];

            data.forEach(privilege => {
                checkboxes.push({ id: privilege.id, name: privilege.name, isChecked: false });
            })

            setPrivileges(checkboxes);
        })
    }, []);

    const getPrivilegesByRole = useCallback(async (selectedRole) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getPrivilegesByRole.replace("$id", selectedRole.id);

        handleRequest(endPointUrl, 'GET', null, (data) => {
            // setMessageBar("Successfully retrieved privileges for the role", true, "success");

            setPrivileges(prevState => {
                let privilegesNewState = [...prevState];

                let privilegeIdList = [];

                data.forEach(privilege => {
                    privilegeIdList.push(privilege.id);
                })

                privilegesNewState.forEach(privilege => {
                    if (privilegeIdList.includes(privilege.id)) {
                        privilege.isChecked = true;
                    } else {
                        privilege.isChecked = false;
                    }
                });

                return privilegesNewState;
            });
        }, () => {
            setMessageBar("An error occurred in retrieving privileges for the selected role", true, "error");
        }, setIsBackdropProgressOpen);
    }, []);

    const addNewRole = useCallback(async (newRoleName) => {
        let confirmDialogDetails = ROLE_SETTINGS.MESSAGES.ADD_NEW_ROLE;

        dispatchForm({
            type: ACTIONS.ADD_NEW_ROLE,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message.replace('$role', newRoleName),
                data: {
                    newRoleName: newRoleName
                }
            },
        });
    }, []);

    useEffect(() => {
        getRoles();
        getPrivileges();
    }, []);

    useEffect(() => {
        if (selectedRole) {
            getPrivilegesByRole(selectedRole);
        }
    }, [selectedRole]);

    const onSelectedRoleChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setSelectedRole({
                name: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            addNewRole(newValue.inputValue);
        } else {
            setSelectedRole(newValue);
        }
    }

    const onClickSave = () => {
        let confirmDialogDetails = ROLE_SETTINGS.MESSAGES.UPDATE_ROLE_PRIVILEGES;

        dispatchForm({
            type: ACTIONS.UPDATE_ROLE_PRIVILEGES,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message,
            },
        });
    }

    const handleDialogBoxAction = (isConfirm) => {
        switch (confirmDialogDetails.id) {
            case 'updateRolePrivileges':
                return handleUpdateRolePrivileges(isConfirm);
            case 'addNewRole':
                return handleAddNewRole(isConfirm);
            case 'showInfo':
                return handleShowInfo();
            default:
                return;
        }
    };

    const handleUpdateRolePrivileges = (isConfirm) => {
        if (isConfirm) {
            const endPointUrl = AppConfig.baseUrl + AppConfig.updatePrivilegesForRole.replace("$id", selectedRole.id.toString());
            const body = {
                // roleId: selectedRole.id.toString(),
                privileges: privileges.filter((privilege) => (privilege.isChecked === true)).map(privilege => privilege.id.toString())
            }

            handleRequest(endPointUrl, 'PATCH', body, (data) => {
                setMessageBar("Privileges updated successfully", true, "success");
            });
        }

        dispatchForm({
            type: ACTIONS.UPDATE_ROLE_PRIVILEGES,
            confirmDialogDetails: {
            },
        });
    }

    const handleAddNewRole = (isConfirm) => {
        if (isConfirm) {
            let newRoleName = confirmDialogDetails.data.newRoleName;

            const endPointUrl = AppConfig.baseUrl + AppConfig.addNewRole;
            const body = {
                name: newRoleName
            }

            handleRequest(endPointUrl, 'POST', body, (data) => {
                setMessageBar("Role added successfully", true, "success");
                getRoles(newRoleName);
            });
        }

        dispatchForm({
            type: ACTIONS.ADD_NEW_ROLE,
            confirmDialogDetails: {
            },
        });
    }

    const onClickReset = () => {
        getPrivilegesByRole(selectedRole);
    }

    const handleChange = index => event => {
        let checkBoxesCurrent = [...privileges];
        checkBoxesCurrent[index].isChecked = event.target.checked;

        setPrivileges(checkBoxesCurrent);
    };

    const showPageInfo = () => {
        let confirmDialogDetails = ROLE_SETTINGS.MESSAGES.SHOW_PAGE_INFO;

        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const handleShowInfo = () => {
        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
            },
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        Role Settings
                        <IconButton aria-label="info" color="secondary" onClick={() => { showPageInfo() }}>
                            <InfoIcon />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
            <Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Paper square className={classes.paper}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    value={selectedRole}
                                    onChange={onSelectedRoleChange}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                name: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disableClearable
                                    id="free-solo-with-text-demo"
                                    options={roles}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(option) => option.name}
                                    style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Role" variant="outlined" />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {privileges && privileges.map((privilege, index) => (
                                    <FormControlLabel
                                        control={<Checkbox checked={privilege.isChecked} onChange={handleChange(index)} key={privilege.id} name={privilege.id} />}
                                        label={privilege.name}
                                        key={privilege.id}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormControl className={classes.btnControl}>
                                <Button variant="contained" size="large" color="secondary" onClick={onClickSave}>
                                    Save
                                </Button>
                            </FormControl>
                            <FormControl className={classes.btnControl}>
                                <Button variant="contained" size="large" color="primary" onClick={onClickReset}>
                                    Reset
                                </Button>
                            </FormControl>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <BackdropProgress open={isBackdropProgressOpen} />

            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={dialogType} />
        </React.Fragment>
    );
};

export default RoleSettings;