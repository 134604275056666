import { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: '0px 3px 12px #00000017'
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%'
    },
    page: {
        padding: 20,
        flexGrow: 1,
    },
    textStyles: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const EngagementDataPanel = ({name, value}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Typography variant="h6">
                {name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom className={classes.textStyles}>
                {value ? value : "-"}
            </Typography>
        </Fragment>
    )
}

export default EngagementDataPanel;