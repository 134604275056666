import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { AppConfig, AllocationStatus, FormConstants, DATE_DISPLAY_FORMAT } from '../../Config';
import useHttp from '../utils/http';
import { useAllocationTypes } from "../DataContext";
import { isInternal } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%',

    },
    page: {
        padding: 20,
        flexGrow: 1,
        boxShadow: '0px 3px 12px #00000017',

    },
    resize: {
        fontSize: 13,
    }
}));

const AllocationFilterBySearch = ({ fetchDataOnSearch, isInternalEngagement, searchFilters, setSearchFilters, initialSearchFiltersState }) => {
    const classes = useStyles();
    const { handleRequest } = useHttp();

    let allAllocationTypes = useAllocationTypes();

    const [accounts, setAccounts] = useState([]);
    const [allocationTypes, setAllocationTypes] = useState([]);

    const [allocationStatusList, setAllocationStatusList] = useState([
        AllocationStatus.TENTATIVE,
        AllocationStatus.READY_FOR_CLEARANCE,
        AllocationStatus.CLEARANCE_IN_PROGRESS,
        AllocationStatus.ACCEPTED_BY_CONSULTANT,
        AllocationStatus.REJECTED_BY_CONSULTANT,
        AllocationStatus.REJECTED_VISA_ISSUES,
        AllocationStatus.REJECTED_OTHER,
        AllocationStatus.CONFIRMED,
        AllocationStatus.CONFIRMED_VISA_PENDING,
        AllocationStatus.ALLOCATION_CANCELLED
    ]);

    const [consultants, setConsultants] = useState([]);

    const setAllocationTypesDropdown = (account) => {
        let filteredAllocationTypes = [];

        if (account) {
            if (account.customerName.trim() === FormConstants.Wso2InternalLegacyAccount || account.customerName.trim() == FormConstants.Wso2InternalAccount) {
                filteredAllocationTypes = allAllocationTypes.filter(type => isInternal(type.engagementTypeId));
            } else {
                filteredAllocationTypes = allAllocationTypes.filter(type => !isInternal(type.engagementTypeId));
            }
        } else {
            filteredAllocationTypes = isInternalEngagement ? allAllocationTypes.filter(type => isInternal(type.engagementTypeId)) : allAllocationTypes.filter(type => !isInternal(type.engagementTypeId));
        }

        setAllocationTypes(filteredAllocationTypes);

        if (searchFilters.allocationType && !filteredAllocationTypes.find(type => type.id === searchFilters.allocationType.id)) {
            setSearchFilters({
                ...searchFilters,
                allocationType: ""
            });
        }
    }

    const getAccounts = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementAccounts;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            setAccounts(data.filter(item => item.customerName.trim() !== FormConstants.Wso2InternalAccount && item.customerName.trim() !== FormConstants.Wso2InternalLegacyAccount));
        });
    }, []);

    const getConsultants = useCallback(async () => {
        let allEmployeeEmails = [];
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployees;
        const params = `excludedEmploymentTypes=Internship`;
        const requestUrl = `${endPointUrl}?${params}`;

        handleRequest(requestUrl, 'GET', null, (data) => {
            data.forEach(employee => {
                allEmployeeEmails.push(employee.workEmail)
            });

            setConsultants(allEmployeeEmails);
        });
    }, []);

    useEffect(() => {
        setAllocationTypesDropdown(searchFilters.account);
    }, [allAllocationTypes, searchFilters.account]);

    useEffect(() => {
        getConsultants();

        if (!isInternalEngagement) {
            getAccounts();
        }
    }, []);

    const onSearchClick = () => {
        fetchDataOnSearch();
    }

    const resetFilters = () => {
        setSearchFilters(initialSearchFiltersState);
    };

    const onAccountChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                account: value
            })
        }
    };

    const onAllocationTypeChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                allocationType: value
            })
        }
    };

    const onConsultantChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                consultant: value
            })
        }
    };

    const handleInputChange = (e) => {
        setSearchFilters({
            ...searchFilters,
            [e.target.name]: e.target.value
        })
    };

    const handleStartDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            startDate: date
        })
    };

    const handleEndDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            endDate: date
        })
    };

    return (
        <React.Fragment>
            <Paper square className={classes.page}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        {isInternalEngagement ? null :
                            <Grid item xs={2}>
                                <FormControl className={classes.formControl} size="small">
                                    <Autocomplete
                                        options={accounts.sort((a, b) => -b.customerName.localeCompare(a.customerName))}
                                        getOptionLabel={(option) => option.customerName}
                                        id="account"
                                        size="small"
                                        value={searchFilters.account}
                                        onChange={onAccountChange}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { fontSize: 13 } }} label="By Account" variant="outlined" />}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} size="small">
                                <Autocomplete
                                    options={allocationTypes.sort((a, b) => -b.allocationTypeName.localeCompare(a.allocationTypeName))}
                                    getOptionLabel={(option) => option.allocationTypeName}
                                    id="alloc-type"
                                    size="small"
                                    value={searchFilters.allocationType}
                                    onChange={onAllocationTypeChange}
                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { fontSize: 13 } }} label="By Allocation Type" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} size="small">
                                <Autocomplete
                                    options={consultants.sort((a, b) => -b.localeCompare(a))}
                                    getOptionLabel={(option) => option}
                                    id="consultant"
                                    size="small"
                                    value={searchFilters.consultant}
                                    onChange={onConsultantChange}
                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { fontSize: 13 } }} label="By Consultant" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} variant="outlined" size="small">
                                <InputLabel id="eng-status-select-helper-label" className={classes.resize}>By Allocation Status</InputLabel>
                                <Select
                                    labelId="eng-status-select-helper-label"
                                    id="eng-status-simple-select-helper"
                                    name='clearanceStatus'
                                    label="By Allocation Status"
                                    value={searchFilters.clearanceStatus}
                                    onChange={handleInputChange}
                                >
                                    {allocationStatusList && allocationStatusList.map(status => (
                                        <MenuItem key={status} value={status}>{status}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        {/* </Grid>
                        <Grid container item xs={12} spacing={1}> */}
                        <Grid item xs={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    size="small"
                                    format={DATE_DISPLAY_FORMAT}
                                    className={classes.formControl}
                                    label="Start Date"
                                    id="date-picker-inline"
                                    value={searchFilters.startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    okLabel=''
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    size="small"
                                    format={DATE_DISPLAY_FORMAT}
                                    className={classes.formControl}
                                    label="End Date"
                                    id="date-picker-inline"
                                    value={searchFilters.endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    okLabel=''
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    {/* </Grid>
                    <Grid container item xs={12} spacing={1}> */}
                         <Grid item xs={8}/>
                        <Grid item xs={ 2} >

                            <FormControl className={classes.formControl}>
                                <Button variant="contained" size="medium" color="secondary" onClick={() => { onSearchClick() }}>
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>


                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} >
                                <Button variant="contained" size="medium" color="primary" onClick={() => { resetFilters() }}>
                                    Reset
                                </Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export default AllocationFilterBySearch;