import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../utils/AppData';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '80%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 3px 12px #00000017',
        padding: theme.spacing(2, 4, 3),
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    page: {
        padding: 20,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AddPartnerPopup = ({ title, data, open, handlePartnerData, handleFormAction, isEditMode, isSubmitBtnClicked }) => {
    const classes = useStyles();

    const onCountryChange = (event, value) => {
        if (value) {
            handlePartnerData("country", value);
        }
    };

        // Used to validate each required field and add an error in each if not valid
        const isFieldValueValid = (field) => {
            // Check if isSubmitBtnClicked is true and needed value is available for the field
            return !(isSubmitBtnClicked && (data[field] === "" || data[field] === null || data[field] === undefined));
        };

    const handleInputChange = (e) => {
        handlePartnerData(e.target.name, e.target.value);
    };

    const handleSubmitDialog = () => {
        handleFormAction(true, isEditMode);
    };

    const handleClose = () => {
        handleFormAction(false);
    };

    return (
        <Modal
            aria-labelledby="partner-popup-title"
            aria-describedby="partner-popup-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper square className={classes.paper}>
                    <Typography variant="h5" display="block" gutterBottom>
                        {title}
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormControl className={classes.formControl} variant="outlined">
                            <Autocomplete
                                options={countries}
                                getOptionLabel={(option) => option[1]}
                                id="eng-country"
                                value={data.country}
                                onChange={onCountryChange}
                                renderInput={(params) => <TextField {...params} required error={!isFieldValueValid("country")} label="Country" variant="outlined" />}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="partner-text"
                                name="partner"
                                label="Partner"
                                variant="outlined"
                                value={data.partner}
                                onChange={handleInputChange}
                                required
                                error={!isFieldValueValid("partner")}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="firstName-text"
                                name="firstName"
                                label="First Name"
                                variant="outlined"
                                value={data.firstName}
                                onChange={handleInputChange}
                                required
                                error={!isFieldValueValid("firstName")}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="lastName-text"
                                name="lastName"
                                label="Last Name"
                                variant="outlined"
                                value={data.lastName}
                                onChange={handleInputChange}
                                required
                                error={!isFieldValueValid("lastName")}
                                inputProps={{ maxLength: 90 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="email-text"
                                name="email"
                                label="Email" // Email (Optional) readonly
                                variant="outlined"
                                value={data.email}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 100 }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                id="phoneNumber-text"
                                name="mobileNumber"
                                label="Phone Number" // Phone Number (Optional)
                                variant="outlined"
                                value={data.mobileNumber}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </FormControl>
                    </Grid>

                    {isEditMode &&
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel id="demo-simple-select-helper-label">Consultant Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={data.status}
                                    name="status"
                                    onChange={handleInputChange}
                                    label="Consultant Status"
                                >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Left">Left</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        margin= "10"
                    >
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" margin= "20px" color="secondary" onClick={() => { handleSubmitDialog() }}>
                                {isEditMode ? "Update" : "Add"}
                            </Button>
                        </FormControl>
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" margin= "20px" color="primary" onClick={() => { handleClose() }}>
                                Cancel
                            </Button>
                        </FormControl>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
};

export default AddPartnerPopup;