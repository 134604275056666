import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormLabel, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%',   
    },
    page: {
        padding: 20,
        flexGrow: 1,
        boxShadow: '0px 3px 12px #00000017',
    },
    formLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',        
    },
    checkBoxes: {
        color: theme.palette.secondary,
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FilterByCheckBoxes = ({ formGroupLabel, checkBoxes, setCheckboxesState, fetchData }) => {
    const classes = useStyles();

    const handleChange = index => event => {
        let checkBoxesCurrent = [...checkBoxes];
        checkBoxesCurrent[index].isChecked = event.target.checked;

        setCheckboxesState(checkBoxesCurrent);
        fetchData?.();
    };

    return (
        <React.Fragment>        
            <Paper square className={classes.page}>
                <Grid container spacing={2}>

                    <FormGroup row className={classes.formGroup}>
                        <FormLabel className={classes.formLabel}>
                            {formGroupLabel}
                        </FormLabel>
                    </FormGroup>
                </Grid>
            </Paper>
            <Divider />
            <Paper square className={classes.page}>
                <Grid container spacing={2}>

                    <FormGroup row className={classes.formGroup}>
                        <FormLabel className={classes.formLabel}>
                        </FormLabel>


                        {checkBoxes && checkBoxes.map((checkBox, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checkBox.isChecked}
                                        onChange={handleChange(index)}
                                        name={checkBox.name}
                                        color="secondary"
                                    />
                                }
                                label={checkBox.label}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export default FilterByCheckBoxes;