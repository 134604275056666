import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';

import AllocationsTimeline from './AllocationsTimeline';
import { AppConfig } from '../../Config';
import EngagementDataPanel from "../engagements/EngagementDataPanel";
import useHttp from '../utils/http';
import AllocationsHeaderAction from './AllocationsHeaderAction';
import timezones from 'timezones-list';
import { isPastDateForAlloc } from '../utils/utils';
import AllocationsTimelineExtended from './AllocationsTimelineExtended';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
        padding: 10,
        
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    formControlTimezone: {
        margin: theme.spacing(1),
        marginBottom: 0,
        minWidth: 300,
    }
}));

const AllocationsByMail = ({ isInternalEngagement, isMarketingEvent, timelineStartDate, timelineEndDate, engStartDate, engEndDate, requestStatus, onClickAddBtn, selectedEmail, onSelectedEmailChange, selectedEmails, allocationsItemList, isEngagementDataPanelAvail, data, header, headerActionBtnVisible, onClickUpdateRequestStatus, isTimelineTimeZoneEnabled = false, timelineTimeZoneObj, handleTimelineTimeZoneChange, setTimelineStartDate, setTimelineEndDate, getAllocationsByEmail, setOpenBackDropProgress, setMessageBar, resetPage }) => {
    const classes = useStyles();
    const { handleComponentEncodedRequest } = useHttp();
    let timer;

    const [emails, setEmails] = useState([]);
    const [isTimelineRefreshed, setIsTimelineRefreshed] = useState(false);

    const getEmails = useCallback(async () => {
        let emailOptions = [];

        const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployees;
        const params = [`employeeStatuses=${encodeURIComponent("Active,Marked Leaver")}`, `excludedEmploymentTypes=Internship`].join('&');
        const requestUrl = `${endPointUrl}?${params}`;

        handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
            data.forEach(dataItem => {
                emailOptions.push({ id: dataItem.workEmail, title: dataItem.workEmail })
            })

            setEmails(emailOptions.sort((a, b) => -b.title.localeCompare(a.title)));
        });
    }, []);

    const handleStartDateChange = (date) => {
        setTimelineStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setTimelineEndDate(date);
    };

    useEffect(() => {
        getEmails();
    }, [getEmails]);

    useEffect(() => {
        resetPage();
    }, []);

    const onEmailChange = (event, value) => {
        onSelectedEmailChange(value);
    };

    const onRefreshTimeline = (startDate, endDate) => {
        setIsTimelineRefreshed(true);
        setOpenBackDropProgress(true);

        if (selectedEmails && selectedEmails.length > 0 && Object.keys(selectedEmails[0]).length !== 0) {
            getAllocationsByEmail(selectedEmails.map(email => email.id), startDate, endDate, [], () => {
                setIsTimelineRefreshed(false);
            }, true);
        } else {
            timer = setTimeout(() => {
                setIsTimelineRefreshed(false);
                setOpenBackDropProgress(false);
            }, 100)
        }
    };

    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    return (
        <React.Fragment>

            {header &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" gutterBottom>
                            {header}
                            {headerActionBtnVisible &&
                                <AllocationsHeaderAction isInternalEngagement={isInternalEngagement} requestStatus={requestStatus} onClickUpdateRequestStatus={onClickUpdateRequestStatus} />
                            }
                        </Typography>
                    </Grid>
                </Grid>
            }

            {isEngagementDataPanelAvail &&
                <EngagementDataPanel data={data} isInternalEngagement={isInternalEngagement} isMarketingEvent={isMarketingEvent} />
            }
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormControl className={classes.formControl}>
                    <Autocomplete
                        id="email-outlined"
                        options={emails}
                        getOptionLabel={(option) => option.title}
                        value={selectedEmail}
                        onChange={onEmailChange}
                        getOptionDisabled={(option) => selectedEmails.filter(email => email.id === option.id).length > 0}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search by Email"
                            />
                        )}
                    />
                </FormControl>
                <FormControl className={classes.btnControl}>
                    <Button variant="contained" size="large" color="secondary" onClick={() => { onClickAddBtn() }} disabled={isPastDateForAlloc(engStartDate) && isPastDateForAlloc(engEndDate)}>
                        Allocate
                    </Button>
                </FormControl>
           </Grid> 

           <AllocationsTimelineExtended timelineStartDate={timelineStartDate} timelineEndDate={timelineEndDate} engStartDate={engStartDate} engEndDate={engEndDate} onClickAddBtn={onClickAddBtn} groups={selectedEmails} items={allocationsItemList} isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled} timezones={timezones} handleTimelineTimeZoneChange={handleTimelineTimeZoneChange} timelineTimeZoneObj={timelineTimeZoneObj}
                isSearchByDatesEnabled={true} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} onRefreshTimeline={onRefreshTimeline} isTimelineRefreshed={isTimelineRefreshed} setMessageBar={setMessageBar} />
        </React.Fragment>
    );
};

export default AllocationsByMail;