import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { AppConfig, RequestStatus, EngagementStatus, FormConstants, DATE_DISPLAY_FORMAT } from '../../Config';
import useHttp from '../utils/http';
import { isInternal } from "../utils/utils";
import { useAllocationTypes } from "../DataContext";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,        
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%',  
        fontSize:13,
    },
    page: {
        padding: 20,
        flexGrow: 1,
        boxShadow: '0px 3px 12px #00000017', 
    },
    resize:{
        fontSize:13,   
    }
}));

const EngagementFilterBySearch = ({ fetchDataOnSearch, isInternalEngagement, searchFilters, setSearchFilters, initialSearchFiltersState }) => {
    const classes = useStyles();
    const { handleRequest } = useHttp();
    let allAllocationTypes = useAllocationTypes();

    const [engagementTypes, setEngagementTypes] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [allocationTypes, setAllocationTypes] = useState([]);
    const [requestStatus, setRequestStatus] = useState([]);
    const [engagementStatus, setEngagementStatus] = useState([]);

    const getEngagementTypes = useCallback(async () => {
        let types = [];

        const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementTypes + `?type=${isInternalEngagement ? FormConstants.Types.Internal : FormConstants.Types.Customer}`;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            setEngagementTypes(data);
        });
    }, [isInternalEngagement]);

    const getAccounts = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementAccounts;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            setAccounts(data.filter(item => item.customerName && item.customerName.trim() !== FormConstants.Wso2InternalAccount && item.customerName.trim() !== FormConstants.Wso2InternalLegacyAccount && item.customerCode));
        });
    }, []);

    useEffect(() => {
        setRequestStatus([
            { name: RequestStatus.NEW },
            { name: RequestStatus.UPDATED },
            { name: RequestStatus.IN_PROGRESS },
            { name: RequestStatus.COMPLETED },
            { name: RequestStatus.CANCELLED }
        ]);

        setEngagementStatus([
            { name: EngagementStatus.CONFIRMED },
            { name: EngagementStatus.PENDING },
            { name: EngagementStatus.IN_PROGRESS },
            { name: EngagementStatus.COMPLETED },
            { name: EngagementStatus.CANCELLED }
        ]);
    }, []);

    const setAllocationTypesDropdown = (engagementType, account) => {
        let filteredAllocationTypes = [];

        if (engagementType && account) {
            let allocationTypesForEngagementType = allAllocationTypes.filter(type => (type.engagementTypeId === engagementType.id));

            if (account.customerName.trim() === FormConstants.Wso2InternalLegacyAccount || account.customerName.trim() == FormConstants.Wso2InternalAccount) {
                filteredAllocationTypes = allocationTypesForEngagementType.filter(type => isInternal(type.engagementTypeId));

            } else {
                filteredAllocationTypes = allocationTypesForEngagementType.filter(type => !isInternal(type.engagementTypeId));
            }
        } else if (engagementType) {
            filteredAllocationTypes = allAllocationTypes.filter(type => (type.engagementTypeId === engagementType.id));
        } else if (account) {
            if (account.customerName.trim() === FormConstants.Wso2InternalLegacyAccount || account.customerName.trim() == FormConstants.Wso2InternalAccount) {
                filteredAllocationTypes = allAllocationTypes.filter(type => isInternal(type.engagementTypeId));
            } else {
                filteredAllocationTypes = allAllocationTypes.filter(type => !isInternal(type.engagementTypeId));
            }
        } else {
            filteredAllocationTypes = isInternalEngagement ? allAllocationTypes.filter(type => isInternal(type.engagementTypeId)) : allAllocationTypes.filter(type => !isInternal(type.engagementTypeId));
        }

        setAllocationTypes(filteredAllocationTypes);

        if (searchFilters.allocationType && !filteredAllocationTypes.find(type => type.id === searchFilters.allocationType.id)) {
            setSearchFilters({
                ...searchFilters,
                allocationType: ""
            });
        }
    }

    useEffect(() => {
        getEngagementTypes();

        if (!isInternalEngagement) {
            getAccounts();
        }
    }, [isInternalEngagement]);

    useEffect(() => {
        setAllocationTypesDropdown(searchFilters.type, searchFilters.account);
    }, [allAllocationTypes, searchFilters.type, searchFilters.account]);

    const onSearchClick = () => {
        fetchDataOnSearch();
    }

    const resetFilters = () => {
        setSearchFilters(initialSearchFiltersState);
    };

    const onAccountChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                account: value
            })
        }
    };

    const onAllocationTypeChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                allocationType: value
            })
        }
    };

    const handleInputChange = (e) => {
        setSearchFilters({
            ...searchFilters,
            [e.target.name]: e.target.value
        })
    };

    const handleStartDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            startDate: date
        })
    };

    const handleEndDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            endDate: date
        })
    };

    return (
        <React.Fragment>
            <Paper square className={classes.page} >
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-helper-label" className={classes.resize}> By Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    // value='paid'
                                    name='type'
                                    label="By Type"
                                    value={searchFilters.type}
                                    onChange={handleInputChange}
                                >

                                    {engagementTypes && engagementTypes.map(type => (
                                        <MenuItem key={type.id} value={type}>{type.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!isInternalEngagement &&
                            <Grid item xs={2}>
                                <FormControl className={classes.formControl} size="small">
                                    <Autocomplete
                                        options={accounts.sort((a, b) => -b.customerName.localeCompare(a.customerName))}
                                        getOptionLabel={(option) => option.customerName}
                                        id="account"
                                        size="small" 
                                        value={searchFilters.account}
                                        onChange={onAccountChange}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={{style: {fontSize: 13}}} label="By Account" variant="outlined"/>}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={2} className={classes.label}>
                            <FormControl className={classes.formControl} size="small">
                                <Autocomplete
                                    options={allocationTypes.sort((a, b) => -b.allocationTypeName.localeCompare(a.allocationTypeName))}
                                    getOptionLabel={(option) => option.allocationTypeName}
                                    id="alloc-type"
                                    size="small"
                                    value={searchFilters.allocationType}
                                    onChange={onAllocationTypeChange}
                                    renderInput={(params) => <TextField {...params} InputLabelProps={{style: {fontSize: 13}}} label="By Allocation Type" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} variant="outlined" size="small" >
                                <InputLabel id="eng-status-select-helper-label" className={classes.resize}> By Engagement Status</InputLabel>
                                <Select
                                    labelId="eng-status-select-helper-label"
                                    id="eng-status-simple-select-helper"
                                    name='engagementStatus'
                                    label="By Engagement Status"
                                    value={searchFilters.engagementStatus}
                                    onChange={handleInputChange}
                                >
                                    {engagementStatus && engagementStatus.map(status => (
                                        <MenuItem key={status.name} value={status.name}>{status.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    {/* </Grid>
                    <Grid container item xs={12} spacing={1}> */}
                        <Grid item xs={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    size="small"
                                    format={DATE_DISPLAY_FORMAT}
                                    className={classes.formControl}
                                    InputLabelProps={{style: {fontSize: 13}}}
                                    label="Start Date"
                                    id="date-picker-inline"
                                    value={searchFilters.startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    okLabel=''
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    size="small"
                                    format={DATE_DISPLAY_FORMAT}
                                    className={classes.formControl}
                                    InputLabelProps={{style: {fontSize: 13}}}
                                    label="End Date"
                                    id="end-date-picker-inline"
                                    value={searchFilters.endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    okLabel=''
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} variant="outlined" size="small">
                                <InputLabel id="status-select-helper-label" className={classes.resize}>By Request Status</InputLabel>
                                <Select
                                    labelId="status-select-helper-label"
                                    id="status-simple-select-helper"
                                    name='requestStatus'
                                    label="By Request Status"
                                    value={searchFilters.requestStatus}
                                    onChange={handleInputChange}
                                >
                                    {requestStatus && requestStatus.map(status => (
                                        <MenuItem key={status.name} value={status.name}>{status.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid container item xs={12} spacing={1}> */}
                         <Grid item xs={!isInternalEngagement ? 6:8}></Grid> 

                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} >
                                <Button variant="contained" size="medium" color="secondary" onClick={() => { onSearchClick() }}>
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>
                
                        <Grid item xs={2} >
                            <FormControl className={classes.formControl} >
                                <Button variant="contained" size="medium" color="primary"  onClick={() => { resetFilters() }}>
                                    Reset
                                </Button>
                            </FormControl>
                        </Grid>
                       
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export default EngagementFilterBySearch;