import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';

import { useHistory } from "react-router-dom";

import RoleSettings from './RoleSettings';
import PartnerResourceMgt from './PartnerResourceMgt';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';
import AllocationTypesSettings from './AllocationTypesSettings';

const useStyles = makeStyles((theme) => ({
    page: {
        padding: 20,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
}));

const tabProps = (index) => {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tab-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const Settings = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [tab, setTab] = useState(history && history.location && history.location.state ? history.location.state.tab : 0);

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");

    const [tabIndexes, setTabIndexes] = useState({
        roleSettingsTab: 0,
        partnerSettingsTab: 1,
        allocationTypesSettingsTab: 2
    });

    useEffect(() => {
        let initIndex = 0;
        let roleSettingsTabIndex = -1;
        let partnerSettingsTabIndex = -1;
        let allocationTypesSettingsTabIndex = -1;

        if (isAccessGranted([Privileges.ROLE_SETTINGS_PAGE])) {
            roleSettingsTabIndex = initIndex;
            initIndex++;
        }

        if (isAccessGranted([Privileges.PARTNER_RESOURCES_MGT_PAGE])) {
            partnerSettingsTabIndex = initIndex;
            initIndex++;
        }

        if (isAccessGranted([Privileges.ALLOCATION_TYPES_SETTINGS_PAGE])) {
            allocationTypesSettingsTabIndex = initIndex;
            initIndex++;
        }


        setTabIndexes({
            roleSettingsTab: roleSettingsTabIndex,
            partnerSettingsTab: partnerSettingsTabIndex,
            allocationTypesSettingsTab: allocationTypesSettingsTabIndex
        });


    }, [])

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    return (
        <React.Fragment>
            <Paper square>
                <AppBar position="static">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="Settings tabs"
                    >
                        {isAccessGranted([Privileges.ROLE_SETTINGS_PAGE]) &&

                            <Tab label={
                                <Button className={classes.createNewButton} startIcon={<GroupAddIcon />} color="secondary">
                                    Role Settings
                                </Button>
                            } {...tabProps(tabIndexes.roleSettingsTab)} />
                        }
                        {isAccessGranted([Privileges.PARTNER_RESOURCES_MGT_PAGE]) &&
                            <Tab label={
                                <Button className={classes.createNewButton} startIcon={<SupervisedUserCircleIcon />} color="secondary">
                                    Partner Resources Management
                                </Button>
                            } {...tabProps(tabIndexes.partnerSettingsTab)} />
                        }
                        {isAccessGranted([Privileges.ALLOCATION_TYPES_SETTINGS_PAGE]) &&
                            <Tab label={
                                <Button className={classes.createNewButton} startIcon={<AccountTreeRoundedIcon />} color="secondary">
                                    Allocation Types
                                </Button>
                            } {...tabProps(tabIndexes.allocationTypesSettingsTab)} />
                        }
                    </Tabs>
                </AppBar>
                {isAccessGranted([Privileges.ROLE_SETTINGS_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.roleSettingsTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <RoleSettings setMessageBar={setMessageBar} />
                            </Grid>
                        </div>}>
                    </TabPanel>
                }
                {isAccessGranted([Privileges.PARTNER_RESOURCES_MGT_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.partnerSettingsTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <PartnerResourceMgt setMessageBar={setMessageBar} />
                            </Grid>
                        </div>}>
                    </TabPanel>
                }

                {isAccessGranted([Privileges.ALLOCATION_TYPES_SETTINGS_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.allocationTypesSettingsTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <AllocationTypesSettings setMessageBar={setMessageBar} />
                            </Grid>
                        </div>}>
                    </TabPanel>
                }

                <Portal>
                    <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                        <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </Portal>

            </Paper>
        </React.Fragment>
    );
};

export default Settings;