import { Fragment, useEffect, useState } from "react";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import AllocationsBy from "../allocations/AllocationsBy";
import { getAllowedPastDateForDefAlloc } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        boxShadow: '0px 3px 12px #00000017',
    },
}));

const TeamAllocations = () => {
    const classes = useStyles();

    const [data, setData] = useState({
        startDate: null,
        endDate: null,
        engagementCode: ""
    });

    useEffect(() => {
        let today = new Date();

        setData({
            ...data,
            startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            endDate: new Date(today.getFullYear(), today.getMonth() + 3, 0),
            minStartDate: getAllowedPastDateForDefAlloc(),
            minEndDate: getAllowedPastDateForDefAlloc()
        })
    }, []);

    return (
        <Fragment>
            <Paper square className={classes.paper}>
                <AllocationsBy header="Team Allocations" data={data} isHourlyBasis={false} isBulkUploadEnabled={true} isByMailEnabled={false} isSearchByAllocTypeEnabled={true} isTravelRequiredAvail={false} isAllocTypeSelect={true} isSearchByDatesEnabled={true} searchByTeamHeader="Team Allocations" bulkUploadHeader="Bulk Upload Allocations" isTeamAllocPage={true} />
            </Paper>
        </Fragment>
    );
};

export default TeamAllocations;