import React, { Fragment } from "react";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { isInternal, isMarketingEvent } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
    formControlHalf: {
        margin: theme.spacing(1),
        width: '90%'
    },

    panelItem: {
        border: 1,
        margin: 5,
        borderStyle: 'solid',
        borderColor: 'lightgray',
        borderRadius: 4
    },
}));

const FundingSourcePanelItem = ({ option, index, businessUnits, productUnits, regions, subRegions, addedFundingSources, engagementType, onFundingSourceChange, handleRemoveClick, handleAddClick, isSubmitBtnClicked }) => {
    const classes = useStyles();

    const isFieldValueValid = (index, field) => {
        // Check if isSubmitBtnClicked is true and needed value is available for the field
        return !(isSubmitBtnClicked && !addedFundingSources[index][field]);
    };

    const onParentItemSelect = (parentItemName, value, childItems, childItemName) => {
        onFundingSourceChange(parentItemName, value, index);

        if (childItemName && option[childItemName] && option[childItemName].parentId && option[childItemName].parentId !== value.id) {
            onFundingSourceChange(childItemName, null, index);
        }
        
        if (childItemName && childItems && childItems.filter(item => item.parentId ? item.parentId === value.id : true)?.length === 1) {
            onFundingSourceChange(childItemName, childItems.filter(item => item.parentId === value.id)[0], index);
        }
    }

    const onChildItemSelect = (childItemName, value, parentItems, parentItemName) => {
        onFundingSourceChange(childItemName, value, index);

        if (parentItems && parentItemName && value.parentId) {
            onFundingSourceChange(parentItemName, parentItems.find(item => item.id === value.parentId), index);
        }
    }

    return (
        <Fragment>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={11}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.panelItem}
                        xs={12}
                    >

                        <Grid item xs={2}>
                            <FormControl className={classes.formControlHalf} variant="outlined">
                                <Autocomplete
                                    options={regions}
                                    getOptionLabel={(option) => option.name}
                                    id="fs-region"
                                    // size="small"
                                    value={option.region}
                                    getOptionDisabled={(option) => !option.isActive}
                                    onChange={(e, value) => onParentItemSelect("region", value, subRegions, "subRegion")}
                                    renderInput={(params) => <TextField {...params} required error={!isFieldValueValid(index, "region")} label="Region" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControlHalf} variant="outlined">
                                <Autocomplete
                                    options={subRegions.filter(item => option.region?.id ? item.parentId === option.region?.id || !item.parentId : true)}
                                    getOptionLabel={(option) => option.name}
                                    id="fs-sub-region"
                                    // size="small"
                                    value={option.subRegion}
                                    getOptionDisabled={(option) => !option.isActive}
                                    onChange={(e, value) => onChildItemSelect("subRegion", value, regions, "region")}
                                    renderInput={(params) => <TextField {...params} required error={!isFieldValueValid(index, "subRegion")} label="Sub Region" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControlHalf} variant="outlined">
                                <Autocomplete
                                    options={businessUnits}
                                    getOptionLabel={(option) => option.name}
                                    id="fs-business-unit"
                                    // size="small"
                                    value={option.businessUnit}
                                    getOptionDisabled={(option) => !option.isActive}
                                    onChange={(e, value) => onParentItemSelect("businessUnit", value, productUnits, "productUnit")}
                                    renderInput={(params) => <TextField {...params} required error={!isFieldValueValid(index, "businessUnit")} label="Business Unit" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControlHalf} variant="outlined">
                                <Autocomplete
                                    options={productUnits.filter(item => isInternal(engagementType) ? (isMarketingEvent(engagementType) ? item.isMarketing : item.isInternal) : item.isCustomer).filter(item => option.businessUnit?.id ? item.parentId === option.businessUnit?.id || !item.parentId : true)}
                                    getOptionLabel={(option) => option.name}
                                    id="fs-product-unit"
                                    // size="small"
                                    value={option.productUnit}
                                    getOptionDisabled={(option) => !option.isActive}
                                    onChange={(e, value) => onChildItemSelect("productUnit", value, businessUnits, "businessUnit")}
                                    renderInput={(params) => <TextField {...params} required error={!isFieldValueValid(index, "productUnit")} label="Product Unit" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl className={classes.formControlHalf}>
                                <TextField
                                    id="perc"
                                    value={option.percentage}
                                    label="Percentage"
                                    variant="outlined"
                                    onChange={e => onFundingSourceChange("percentage", e.target.value, index)}
                                    required
                                    type="number"
                                    error={!isFieldValueValid(index, "percentage")}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={1}>
                    {addedFundingSources.length !== 1 && (
                        <Tooltip title="Remove funding source">
                            <IconButton aria-label="remove" onClick={() => handleRemoveClick(index)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {addedFundingSources.length - 1 === index && (
                        <Tooltip title="Add new funding source">
                            <IconButton aria-label="add" onClick={() => { handleAddClick() }}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Fragment>

    )
}

export default FundingSourcePanelItem;