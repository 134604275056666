import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AllocationStatus, EngagementStatus } from '../../Config';
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import TableComponent from '../TableComponent';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';
import { STATUS_COLORS } from '../utils/AppConstants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        fontSize: 13,
        padding: 0,
        margin: 0
        // width: 100
    },
    icon: {
        margin: 3,
        alignContent: 'center'
    },
}));

const ACTIONS = {
    UPDATE_REQUEST_STATUS: 'UPDATE_REQUEST_STATUS'
};

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <textarea maxLength="255" style={{ width: "100%", outline: 'none', padding: '0 3px', height: "90%" }} value={value} onChange={onChange} onBlur={onBlur} />
}

const AllocationsTable = ({ data, isLoading, isInternalEngagement, openUpdateAllocationStatusPopup, updateComment, 
    setTablePageSize, fetchData, isResetPageIndex, setFilterText, setSortBy }) => {
    const classes = useStyles();
    const history = useHistory();

    const columns = React.useMemo(
        () => {
            let columns = [];

            if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT])) {
                let privilegedActionCount = 1;

                if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATION_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATION_STATUS_UPDATE])) {
                    privilegedActionCount++;
                }

                columns.push(
                    {
                        Header: 'Action',
                        accessor: 'action',
                        Cell: ({ row }) => {
                            return (
                                <div>
                                    {isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATION_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATION_STATUS_UPDATE]) && (
                                        <Tooltip title="Edit">
                                            <IconButton className={classes.icon} aria-label="edit" onClick={() => openUpdateAllocationStatusPopup(row.original)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>)}

                                    <Tooltip title="Allocate Consultant">
                                        <IconButton className={classes.icon} aria-label="allocate" component={Link} to={`/allocations/${row.original.engagementId}`}>
                                            <PersonIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )
                        },
                        collapse: true,
                        minWidth: 50 * privilegedActionCount,
                        disableSortBy: true,
                        style: { display: 'flex', alignItems: 'center' },
                    }
                );
            }

            columns.push(
                {
                    Header: 'Account',
                    accessor: 'customerName',
                    collapse: true,
                },
                {
                    Header: 'Opportunity Name',
                    accessor: 'opportunityName',
                    collapse: true,
                },
                {
                    Header: 'Engagement Type',
                    accessor: 'engagementType',
                    collapse: true,
                    // minWidth: 95,
                    maxWidth: 100,

                },
                {
                    Header: 'Allocation Type',
                    accessor: 'allocationTypeName',
                    collapse: true,
                },
                {
                    Header: 'Engagement Code',
                    accessor: 'engagementCode',
                    collapse: true,
                    // minWidth: 90,
                    maxWidth: 110,
                },
                {
                    Header: 'Consultant',
                    accessor: 'email',
                    collapse: true,
                },
                // {
                //     Header: 'Role',
                //     accessor: 'role',
                //     collapse: true,
                // },
                {
                    Header: 'Start Date',
                    accessor: 'startDateDisp',
                    collapse: true,
                    // minWidth: 90,
                    maxWidth: 90,
                },
                {
                    Header: 'End Date',
                    accessor: 'endDateDisp',
                    collapse: true,
                    // minWidth: 90,
                    maxWidth: 90,
                },
                {
                    Header: 'Opportunity Owner',
                    accessor: 'opportunityOwner',
                    collapse: true,
                },
                {
                    Header: 'Comment',
                    accessor: 'comment',
                    Cell: EditableCell,
                    collapse: true,
                    minWidth: 150,
                },
                {
                    Header: 'Engagement Location',
                    accessor: 'engagementLocation',
                    collapse: true,
                    // minWidth: 90,
                    maxWidth: 100,
                },
                {
                    Header: 'Country',
                    accessor: 'country',
                    collapse: true,
                    // minWidth: 90,
                    maxWidth: 90,
                },
                {
                    Header: 'Engagement Status',
                    accessor: 'engagementStatus',
                    collapse: true,
                    minWidth: 95,
                    style: { display: 'flex', alignItems: 'center' },
                    // maxWidth: 100,
                    Cell: ({ row }) => {
                        if (row) {
                            return (
                                <Chip size="small" label={row.original.engagementStatus} style={{ backgroundColor: getEngagementStatusColor(row.original.engagementStatus), color: "white", borderRadius: 5, height: 25, width: 90, alignItems: "center", }} />
                            )

                        }
                    },
                },

                {
                    Header: 'Allocation Status',
                    accessor: 'clearanceStatus',
                    style: { display: 'flex', alignItems: 'center' },
                    // style: { overflow: 'hidden' },
                    Cell: ({ row }) => {
                        if (row) {
                            if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATION_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATION_STATUS_UPDATE])) {
                                return <div>
                                    <Chip size="small" label={row.original.clearanceStatus} style={{ backgroundColor: getAllocationStatusColor(row.original.clearanceStatus), color: "white", borderRadius: 5, height: 25, width: 150 }} />
                                </div>
                            } else {
                                return (
                                    <Chip size="small" label={row.original.clearanceStatus} style={{ backgroundColor: getAllocationStatusColor(row.original.clearanceStatus), color: "white", borderRadius: 5, height: 25, width: 150 }} />
                                    //row.original.clearanceStatus
                                )
                            }
                        }
                    },
                    collapse: true,
                    // minWidth: 100,
                    minWidth: 170,
                });

            return columns;
        },
        []
    );

    const getAllocationStatusColor = (status) => {
        switch (status) {
            case AllocationStatus.CLEARANCE_IN_PROGRESS:
                return STATUS_COLORS.IN_PROGRESS; // yellow
            case AllocationStatus.ACCEPTED_BY_CONSULTANT:
                return STATUS_COLORS.CONFIRMED; // blue
            case AllocationStatus.REJECTED_BY_CONSULTANT:
            case AllocationStatus.REJECTED_VISA_ISSUES:
            case AllocationStatus.REJECTED_OTHER:
            case AllocationStatus.ALLOCATION_CANCELLED:
                return STATUS_COLORS.CANCELLED; // gray
            case AllocationStatus.CONFIRMED_VISA_PENDING:
                return STATUS_COLORS.PENDING; // orange
            case AllocationStatus.CONFIRMED:
                return STATUS_COLORS.CONFIRMED; // green
            default:
                return STATUS_COLORS.DEFAULT; //pink
        }
    };
    const getEngagementStatusColor = (status) => {
        switch (status) {
            case EngagementStatus.PENDING:
                return STATUS_COLORS.PENDING; // orange
            case EngagementStatus.IN_PROGRESS:
                return STATUS_COLORS.IN_PROGRESS; // yellow
            case EngagementStatus.CANCELLED:
                return STATUS_COLORS.CANCELLED; // gray
            case EngagementStatus.COMPLETED:
                return STATUS_COLORS.COMPLETED; // green
            case EngagementStatus.CONFIRMED:
                return STATUS_COLORS.CONFIRMED; // blue
            default:
                return STATUS_COLORS.DEFAULT;//pink
        }
    };

    const [originalData] = React.useState(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const updateData = (rowIndex, columnId, value) => {
        let rowData = data[rowIndex];

        updateComment(rowData.id, value);
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
    }

    // After data changes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
    React.useEffect(() => {
        setSkipPageReset(false)
    }, [data])

    return (
        <TableComponent columns={columns}
            data={data ? data : []}
            updateData={updateData}
            skipPageReset={skipPageReset}
            isLoading={isLoading}
            setTablePageSize={setTablePageSize}
            fetchData={fetchData}
            isResetPageIndex={isResetPageIndex}
            isManualProcessing={true}
            setFilterText={setFilterText}
            setSortBy={setSortBy}
            sortByCol={{id: 'startDateDisp', desc: true}}
        />
    )

};

export default AllocationsTable;
