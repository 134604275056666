import React, { Fragment, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import timezones from 'timezones-list';

import { AppConfig, DefaultAllocationStatus, AllocationStatus, DATE_DISPLAY_FORMAT, FormConstants } from '../../Config';
import { useAllocationTypes } from "../DataContext";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { isPastDateForDefAlloc, validateUserInput } from '../utils/utils';
import { ALLOCATION_TYPE_ACTIVE_STATUS } from '../utils/AppConstants';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '80%',      
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: '0px 3px 12px #00000017',
        padding: theme.spacing(2, 4, 3),
        maxHeight: '95%',
        'overflow-y': 'scroll',
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    page: {
        padding: 20,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },

    formControlHalf: {
        margin: theme.spacing(1),
        marginLeft: 'auto',
        minWidth: 180,
    },

    checkboxText: {
        marginLeft: theme.spacing(1),
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AddNewAllocationPopup = ({ title, data, open, setAllocationData, handleFormAction, isAllocationStatusEdit, statusList, isHourlyBasis, setIsHourlyBasis, isTravelRequiredAvail = false, isAllocTypeSelect, isHourlyBasisDisabled = false, isTeamAllocPage = false, isAddOverheadEnabled, isSubmitBtnClicked, isInternalEngagement }) => {
    const classes = useStyles();

    let allocationTypes = useAllocationTypes();

    const [isEngagementCodeDisabled, setIsEngagementCodeDisabled] = useState(true);

    // Used to validate each required field and add an error in each if not valid
    const isFieldValueValid = (field) => {
        // Check if isSubmitBtnClicked is true and needed value is available for the field
        return !(isSubmitBtnClicked && (data[field] === "" || data[field] === null || data[field] === undefined));
    };

    const isUserInputInvalid = (value) => {
        return isSubmitBtnClicked && !validateUserInput(value);
    }

    const isPastDate = (date) => {
        if (isTeamAllocPage && !isAllocationStatusEdit && isPastDateForDefAlloc(date)) {
            return true;
        }

        return false;
    }

    const setDefaultEngagementCode = (allocationTypeObj, engagementCode) => {

        let allocationCode = allocationTypeObj.defaultEngagementCode;

        if (allocationCode) {
            setIsEngagementCodeDisabled(true);
            setAllocationData("engagementCode", allocationCode);
        } else {
            setIsEngagementCodeDisabled(false);
            setAllocationData("engagementCode", "");
        }
    }

    const handleSubmitDialog = () => {
        handleFormAction(true, isAllocationStatusEdit, data.email, data.isPreparationOverhead, data.isPostOverhead);
    };

    const handleClose = () => {
        handleFormAction(false);
    };

    const handleInputChange = (e) => {
        setAllocationData(e.target.name, e.target.value);
    };

    const handleStartDateChange = (date) => {
        setAllocationData("startDate", date);
    };

    const handleEndDateChange = (date) => {
        setAllocationData("endDate", date);
    };

    const handlePreparationStartChange = (date) => {
        setAllocationData("preparationStartDate", date);
    };

    const handlePostEndChange = (date) => {
        setAllocationData("postEndDate", date);
    };

    const handleStartTimeChange = (date) => {
        setAllocationData("startTime", date);
    };

    const handleEndTimeChange = (date) => {
        setAllocationData("endTime", date);
    };

    const handleTimeZoneChange = (event, value) => {
        setAllocationData("timeZoneObj", value);
    };

    const onAllocationTypeChange = (event, value) => {
        if (value) {
            setAllocationData("allocationType", value.id);
            setAllocationData("allocationTypeObj", value);

            setDefaultEngagementCode(value, data.engagementCode);

        }

    };

    const handleChange = (event) => {
        setIsHourlyBasis(event.target.checked);
    }

    const handleChangePrepOverhead = (event) => {
        setAllocationData("isPreparationOverhead", event.target.checked);
    }

    const handleChangePostOverhead = (event) => {
        setAllocationData("isPostOverhead", event.target.checked);
    }

    const handleTentativeChange = (event) => {
        setAllocationData("isTentativeAllocation", event.target.checked);
    }

    return (
        <Modal
            aria-labelledby="allocation-popup-title"
            aria-describedby="allocation-popup-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper square className={classes.paper}>
                    <Typography variant="h5" display="block" gutterBottom className={classes.checkboxText}>
                        {title}
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        margin="10"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                disabled
                                id="email-disabled"
                                label="Email"
                                variant="outlined"
                                value={data.email}
                                size='small'
                                required
                                error={!isFieldValueValid("email")}
                            />
                        </FormControl>
                    </Grid>

                    {isAllocTypeSelect ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    options={isTeamAllocPage ? allocationTypes.filter(typeObj => (typeObj.engagementTypeId === FormConstants.EngagementTypes.InternalEngagement.id) && typeObj.status === ALLOCATION_TYPE_ACTIVE_STATUS) : allocationTypes.filter(typeObj => typeObj.status === ALLOCATION_TYPE_ACTIVE_STATUS)}
                                    groupBy={(option) => option.engagementTypeName}
                                    getOptionLabel={(option) => option.allocationTypeName}
                                    id="alloc-type"
                                    value={data.allocationTypeObj}
                                    onChange={onAllocationTypeChange}
                                    renderInput={(params) => <TextField {...params} label="Select Allocation Type" required autoFocus={!isAllocationStatusEdit} error={!isFieldValueValid("allocationTypeObj")} variant="outlined" />}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <FormControl className={classes.formControl}>
                                <TextField
                                    disabled
                                    id="type-disabled"
                                    label="Allocation Type"
                                    variant="outlined"
                                    size='small'
                                    value={data.allocationTypeObj && data.allocationTypeObj.allocationTypeName ? data.allocationTypeObj.allocationTypeName : data.allocationType}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    )}

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <FormControl className={classes.formControl}>
                            <TextField
                                disabled={isEngagementCodeDisabled}
                                id="code-eng"
                                label="Engagement Code"
                                variant="outlined"
                                name="engagementCode"
                                value={data.engagementCode}
                                onChange={handleInputChange}
                                size='small'
                                required
                                error={!isFieldValueValid("engagementCode")}
                            />
                        </FormControl>
                    </Grid>

                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={isAllocationStatusEdit && (!isTeamAllocPage || (isTeamAllocPage && isPastDateForDefAlloc(data.initStartDate)))}
                                    inputVariant="outlined"
                                    className={classes.formControl}
                                    disableToolbar
                                    format={DATE_DISPLAY_FORMAT}
                                    label="Start Date"
                                    id="date-picker-inline"
                                    minDate={data.minStartDate}
                                    maxDate={data.maxStartDate}
                                    value={data.startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    required
                                    size='small'
                                    autoOk={true}
                                    okLabel=''
                                    error={!isFieldValueValid("startDate") || isNaN(data.startDate) || isPastDate(data.startDate) || data.startDate < data.minStartDate || data.startDate > data.maxStartDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={isAllocationStatusEdit && (!isTeamAllocPage || isTeamAllocPage && isPastDateForDefAlloc(data.initEndDate))}
                                    disableToolbar
                                    inputVariant="outlined"
                                    className={classes.formControl}
                                    format={DATE_DISPLAY_FORMAT}
                                    label="End Date"
                                    id="date-picker-inline"
                                    minDate={data.minEndDate}
                                    maxDate={data.maxEndDate}
                                    value={data.endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    required
                                    size='small'
                                    autoOk={true}
                                    okLabel=''
                                    error={!isFieldValueValid("endDate") || isNaN(data.endDate) || isPastDate(data.endDate) || data.endDate < data.minEndDate || data.endDate > data.maxEndDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Fragment>
                    {/* )} */}

                    {(!isTeamAllocPage && (!isAllocationStatusEdit || isHourlyBasis)) &&
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="subtitle1" align="left" className={classes.checkboxText}>
                                Hourly
                            </Typography>

                            <FormControl component="fieldset">
                                <FormControlLabel
                                    value={isHourlyBasis}
                                    disabled={isHourlyBasisDisabled || isAllocationStatusEdit}
                                    control={<Checkbox checked={isHourlyBasis} color="primary" onChange={handleChange} />}
                                />
                            </FormControl>
                        </Grid>
                    }


                    {isHourlyBasis && (
                        <Fragment>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <FormControl className={classes.formControl}>
                                    <Autocomplete
                                        options={timezones}
                                        getOptionLabel={(option) => option.label}
                                        id="time-zone"
                                        value={data.timeZoneObj}
                                        onChange={handleTimeZoneChange}
                                        disabled={isAllocationStatusEdit}
                                        renderInput={(params) => <TextField {...params} required label="Select Time Zone" error={!isFieldValueValid("timeZoneObj")} variant="outlined" />}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        disabled={isAllocationStatusEdit}
                                        inputVariant="outlined"
                                        className={classes.formControl}
                                        // disableToolbar
                                        format="HH:mm"
                                        minutesStep={15}
                                        label="Recurring Start Time"
                                        id="date-picker-inline"
                                        value={data.startTime}
                                        onChange={handleStartTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        required
                                        size='small'
                                        error={!isFieldValueValid("startTime") || isNaN(data.startTime)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        disabled={isAllocationStatusEdit}
                                        inputVariant="outlined"
                                        className={classes.formControl}
                                        format="HH:mm"
                                        minutesStep={15}
                                        label="Recurring End Time"
                                        id="date-picker-inline"
                                        value={data.endTime}
                                        onChange={handleEndTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        // initialFocusedDate={data.endTime}
                                        // defaultValue={data.endTime}
                                        required
                                        size='small'
                                        error={!isFieldValueValid("endTime") || isNaN(data.endTime)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Fragment>
                    )}

                    {(isAllocationStatusEdit || isInternalEngagement) && !isTeamAllocPage && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormControl className={classes.formControl} variant="outlined" size='small'>
                                <InputLabel id="demo-simple-select-helper-label">Clearance Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={data.clearanceStatus}
                                    name="clearanceStatus"
                                    onChange={handleInputChange}
                                    label="Clearance Status"
                                    size='small'
                                    error={!isFieldValueValid("clearanceStatus")}
                                >
                                    {statusList && statusList.map(status => (
                                        <MenuItem key={status} value={status}>{status}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="outlined-textarea"
                                label="Comment"
                                multiline
                                minRows={2}
                                variant="outlined"
                                name="comment"
                                value={data.comment}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                size='small'
                                error={isUserInputInvalid(data.comment)}
                            />
                        </FormControl>
                    </Grid>

                    {isTravelRequiredAvail && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            margin='20px'
                        >
                            <FormControl required className={classes.formControl} variant="outlined" size='small' error={!isFieldValueValid("isTravelRequired")}>
                                <InputLabel id="demo-simple-select-helper-label">Travel Required ?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={data.isTravelRequired}
                                    name="isTravelRequired"
                                    onChange={handleInputChange}
                                    label="Travel Required ?"
                                    disabled={isAllocationStatusEdit}
                                >
                                    <MenuItem value="Yes" margin="20px">Yes</MenuItem>
                                    <MenuItem value="No" margin="20px">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {!isTeamAllocPage && !isAllocationStatusEdit && !isInternalEngagement &&
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="subtitle1" align="left" className={classes.checkboxText}>
                                Tentative allocation
                            </Typography>

                            <FormControl component="fieldset">
                                <FormControlLabel
                                    value={data.isTentativeAllocation}
                                    control={<Checkbox checked={data.isTentativeAllocation} color="primary" onChange={handleTentativeChange} />}
                                />
                            </FormControl>
                        </Grid>
                    }

                    {isAddOverheadEnabled && !isAllocationStatusEdit && (
                        <Fragment>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Typography variant="subtitle2" align="left" className={classes.checkboxText}>
                                    Preparation Overhead
                                </Typography>

                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        value={data.isPreparationOverhead}
                                        control={<Checkbox checked={data.isPreparationOverhead} color="primary" onChange={handleChangePrepOverhead} />}
                                    />
                                </FormControl>

                                {data.isPreparationOverhead &&
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            className={classes.formControlHalf}
                                            format={DATE_DISPLAY_FORMAT}
                                            label="Preparation Start"
                                            id="date-picker-inline"
                                            minDate={data.minPrePrepStartDate}
                                            maxDate={data.maxStartDate}
                                            value={data.preparationStartDate}
                                            onChange={handlePreparationStartChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            required
                                            size='small'
                                            autoOk={true}
                                            okLabel=''
                                            error={!isFieldValueValid("preparationStartDate") || isNaN(data.preparationStartDate) || isPastDate(data.preparationStartDate) || data.preparationStartDate < data.minPrePrepStartDate || data.preparationStartDate > data.maxStartDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                }

                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Typography variant="subtitle2" align="left" className={classes.checkboxText}>
                                    Post Engagement Overhead
                                </Typography>

                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        value={data.isPostOverhead}
                                        control={<Checkbox checked={data.isPostOverhead} color="primary" onChange={handleChangePostOverhead} />}
                                    />
                                </FormControl>

                                {data.isPostOverhead &&
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            className={classes.formControlHalf}
                                            format={DATE_DISPLAY_FORMAT}
                                            label="Post Engagement End"
                                            id="date-picker-inline"
                                            minDate={data.minEndDate}
                                            maxDate={data.maxPostPrepEndDate}
                                            value={data.postEndDate}
                                            onChange={handlePostEndChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            required
                                            size='small'
                                            autoOk={true}
                                            okLabel=''
                                            error={!isFieldValueValid("postEndDate") || isNaN(data.postEndDate) || isPastDate(data.postEndDate) || data.postEndDate < data.minEndDate || data.postEndDate > data.maxPostPrepEndDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                }

                            </Grid>
                        </Fragment>


                    )}

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    // alignItems="center"
                    >
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" color="secondary" onClick={() => { handleSubmitDialog() }}>
                                Confirm
                            </Button>
                        </FormControl>
                        <FormControl className={classes.btnControl}>
                            <Button variant="contained" size="large" color="primary" onClick={() => { handleClose() }}>
                                Cancel
                            </Button>
                        </FormControl>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
};

export default AddNewAllocationPopup;