import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import SyncIcon from '@material-ui/icons/Sync';

import AllocationsTimeline from './AllocationsTimeline';
import { DATE_DISPLAY_FORMAT } from '../../Config';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        marginBottom: 0,
        width: '90%'
    },

    btnControl: {
        margin: theme.spacing(1),
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    formControlTimezone: {
        margin: theme.spacing(1),
        marginBottom: 0,
        minWidth: 300,
        float: 'right',
        textAlign: 'right'
    },

    indicatorDot: {
        height: 10,
        width: 10,
        borderRadius: '50%',
        display: 'inline-block',
        // marginRight: 10,
    }
}));

const AllocationsTimelineExtended = ({ engStartDate, engEndDate, onClickAddBtn, onItemDoubleClick, groups, items, isTimelineTimeZoneEnabled = false, timelineTimeZoneObj, handleTimelineTimeZoneChange, timezones, sidebarWidth, isSearchByDatesEnabled, timelineStartDate, timelineEndDate, handleStartDateChange, handleEndDateChange, onRefreshTimeline, isTimelineRefreshed, setMessageBar }) => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState(timelineStartDate);
    const [endDate, setEndDate] = useState(timelineEndDate);

    useEffect(() => {
        if (!startDate) {
            setStartDate(timelineStartDate);
        }

        if (!endDate) {
            setEndDate(timelineEndDate);
        }
    }, [timelineStartDate, timelineEndDate])

    const onDoubleClickTimeline = (groupId, time, e) => {
        if (groupId) {
            onClickAddBtn(groupId);
        }
    };

    const onClickTimelineRefresh = () => {
        let msg = "";

        if (isNaN(startDate) || isNaN(endDate)) {
            msg = "Please select valid dates for Start/End dates"
        } else if (!startDate) {
            msg = "Please select the start date"
        } else if (!endDate) {
            msg = "Please select the end date"
        } else if (endDate < startDate) {
            msg = "End date is less than the start date"
        } else if (startDate.getTime() === endDate.getTime()) {
            msg = "Please do not select the same date for Start/End dates"
        }

        if (msg) {
            setMessageBar(msg, true, 'error');
        } else {
            handleStartDateChange(startDate);
            handleEndDateChange(endDate);
            onRefreshTimeline(startDate, endDate);
        }

    }

    const onStartDateChange = (date) => {
        setStartDate(date);
    }

    const onEndDateChange = (date) => {
        setEndDate(date);
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginTop: '30px' }}
            >
                <Grid container item xs={12} spacing={1}>

                    {isSearchByDatesEnabled && (
                        <>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled={false}
                                        disableToolbar
                                        inputVariant="outlined"
                                        format={DATE_DISPLAY_FORMAT}
                                        // margin="normal"
                                        id="date-picker-start"
                                        className={classes.formControl}
                                        label="Start Date"
                                        value={startDate}
                                        onChange={onStartDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        okLabel=''
                                        size='small'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled={false}
                                        disableToolbar
                                        inputVariant="outlined"
                                        format={DATE_DISPLAY_FORMAT}
                                        // margin="normal"
                                        id="date-picker-end"
                                        className={classes.formControl}
                                        label="End Date"
                                        value={endDate}
                                        onChange={onEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        okLabel=''
                                        size='small'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl className={classes.btnControl}>
                                    <Button variant='contained' size="medium" color="primary" disabled={false} onClick={() => { onClickTimelineRefresh() }}>
                                        <SyncIcon /> Refresh
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {isTimelineTimeZoneEnabled && (
                        <>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={3}>

                                <FormControl className={classes.formControlTimezone}>
                                    <Autocomplete
                                        options={timezones}
                                        getOptionLabel={(option) => option.label}
                                        id="time-zone"
                                        value={timelineTimeZoneObj}
                                        onChange={handleTimelineTimeZoneChange}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} required label="Select Time Zone" variant="outlined" size='small' />}

                                    />
                                </FormControl>
                            </Grid>
                        </>

                    )}
                </Grid>


                {timelineStartDate && timelineEndDate && !isTimelineRefreshed && (
                    <AllocationsTimeline startDate={timelineStartDate} endDate={timelineEndDate} engStartDate={engStartDate} engEndDate={engEndDate} groups={groups} items={items} onDoubleClick={onDoubleClickTimeline} onItemDoubleClick={onItemDoubleClick} isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled} sidebarWidth={sidebarWidth} />

                )}


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    {groups && groups.length > 0 && groups[0].id &&
                        <Typography variant='caption' style={{ paddingLeft: "10px", fontWeight: 'bold' }}>* Double click on the timeline to add allocations</Typography>
                    }

                    {engStartDate && engEndDate &&
                        <Typography variant='caption' style={{ paddingLeft: "10px", fontWeight: 'bold' }}><span className={classes.indicatorDot} style={{ backgroundColor: '#ff7300' }} /> - Engagement Start/End Date Indicators</Typography>
                    }

                    <Typography variant='caption' style={{ paddingLeft: "10px", fontWeight: 'bold' }}><span className={classes.indicatorDot} style={{ backgroundColor: '#0AA1DD' }} /> - Today Indicator</Typography>
                </Grid>
            </Grid>
            {/* )} */}
            {/* </Paper> */}
        </React.Fragment>
    );
};

export default AllocationsTimelineExtended;