export const AppConfig = {
  baseUrl: window.config?.BACKEND_API_BASE_URL,
  appUUID: window.config?.APP_UUID,

  salesForceUrl: window.config?.SALESFORCE_URL,
  userGuideUrl: window.config?.USER_GUIDE_URL,

  getUserPrivileges: "user-privileges",

  getEngagementAccounts: "engagements/accounts",

  getEngagementById: "engagements/$id",
  getEngagements: "engagement-search",
  getEngagementTypes: "engagement-types",
  getAccounts: "customers",
  getOpportunities: "customers/$id/opportunities",
  getProducts: "opportunities/$id/line-items",
  getAllocationTypes: "allocation-types",
  updateRequestStatus: "engagements/$id",
  updateEngagementStatus: "engagements/$id",
  getEmployees: "employees",
  getAllocationsByEmail: "get-allocations",
  getNewDates: "opportunities/line-items/$id",
  getAllProducts: "engagements/products",
  getAllocationsByEngagementId: "engagement-search/$id/allocations",

  getAllocationsWithEngagementType: "allocation-count-by-engagement-type",
  getPaidAllocationsWithAllocationType:
    "paid-allocation-count-by-allocation-type",
  getNonPaidAllocationsWithAllocationType:
    "non-paid-allocation-count-by-allocation-type",

  addNewEngagement: "engagements",
  updateEngagement: "engagements/$id",
  updateAllocation: "allocations/$id",
  deleteAllocation: "allocations/$id",
  validateAllocation: "get-allocations",
  getEmployeeJobBandData: "employee-job-band-Data",

  getAllocations: "allocation-search",
  getPartners: "partners",
  getActivePartners: "active-partners",
  addNewPartner: "partners",
  updatePartner: "partners/$id",
  getAllocationCountForPartnerConsultant:
    "allocation-count-for-partner-consultant",
  deletePartner: "partners/$id",

  getOrgData: "org-data",

  bulkUploadAllocations: "allocations/bulk",

  getRoles: "roles",
  getPrivileges: "privileges",
  getPrivilegesByRole: "roles/$id/privileges",
  addNewRole: "roles",
  updatePrivilegesForRole: "roles/$id",

  getAllocationsForReport: "consultant-allocations",
  getEmployeeDataByEmail: "employees/$email",
  getNonAllocatedEngineerData: "non-allocated-engineers",
  getDefaultAllocations: "default-allocation-data-for-teams",
  getEngagementCodeData: "engagement-code-data",

  getAllFundingSources: "funding-sources",
  getAddedEventNamesList: "engagements/event-names",

  addNewAllocationType: "allocation-types",
  updateAllocationType: "allocation-types/$id",
  deleteAllocationType: "allocation-types/$id",

  getBusinessUnitsList: "business-units",
  getProductUnitsList: "product-units",
  getRegionsList: "regions",
  getSubRegionsList: "sub-regions",

  headers: {
    Accept: "application/json",
    "API-Key": "asgardeo-id-token",
  },

  reqHeaders: {
    Accept: "application/json",
  },
};

export const APP_NAME = "Allocations App";
export const APP_DESCRIPTION =
  "Manage customer engagements, consultant allocations, team allocations, reports";

export const EMAILS = {
  GET_HELP_EMAIL_TO: "internal-apps-group@wso2.com",
  GET_HELP_EMAIL_SUBJECT: `[HELP] ${APP_NAME}`,
};

export const AUTH_CONFIG = {
  signInRedirectURL: window.config?.AUTH_SIGN_IN_REDIRECT_URL,
  signOutRedirectURL: window.config?.AUTH_SIGN_OUT_REDIRECT_URL,
  clientID: window.config?.AUTH_CLIENT_ID,
  serverOrigin: window.config?.AUTH_SERVER_ORIGIN,
  scope: ["openid", "groups", "email"],
};

export const PAGES = {
  BASE_URL: "/",
  DASHBOARD: "/dashboard",
  NEW_ENGAGEMENT: "/engagement",
  EDIT_ENGAGEMENT: "/engagement/edit",
  ENGAGEMENTS: "/engagement/view/all",
  ENGAGEMENT_ALLOCATIONS: "/engagement/allocations",
  TEAM_ALLOCATIONS: "/team-allocations",
  REPORTS: "/report/consultant-allocations",
  SETTINGS: "/settings",
  EVENTS: "/events",
  INTERNAL: "/internal",
  EDIT_INTERNAL: "/internal/edit",
};

export const ResponseStatus = {
  success: 200,
};

export const FormConstants = {
  Wso2InternalAccount: "WSO2",
  Wso2InternalLegacyAccount: "WSO2 Internal",
  Wso2WorkshopLegacyAccount: "WSO2 Workshop",
  EngagementTypes: {
    InternalEngagement: { id: 1, name: "Internal Engagment" },
    MarketingEvent: { id: 5, name: "Marketing Event" },
    Paid: { id: 2, name: "Paid" },
    NonPaidPreSale: { id: 3, name: "Non-Paid Pre Sale" },
    NonPaidPostSale: { id: 4, name: "Non-Paid Post Sale" },
  },
  Types: {
    Internal: "Internal",
    Customer: "Customer",
  },
};

export const engineeringAllocEngagementId = "-1.0";

export const RequestStatus = {
  NEW: "New",
  UPDATED: "Updated",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const EngagementStatus = {
  CONFIRMED: "Confirmed",
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const AllocationStatus = {
  TENTATIVE: "Tentative",
  READY_FOR_CLEARANCE: "Ready for Clearance",
  CLEARANCE_IN_PROGRESS: "Clearance In Progress",
  ACCEPTED_BY_CONSULTANT: "Accepted by Consultant",
  REJECTED_BY_CONSULTANT: "Rejected by Consultant",
  REJECTED_VISA_ISSUES: "Rejected - Visa Issues",
  REJECTED_OTHER: "Rejected - Other",
  CONFIRMED: "Confirmed",
  CONFIRMED_VISA_PENDING: "Confirmed - Visa Pending",
  ALLOCATION_CANCELLED: "Allocation Cancelled",
};

export const DefaultAllocationStatus = "Active";

export const Privileges = {
  DASHBOARD_PAGE: 1,
  CUSTOMER_ENGAGEMENT_CREATE_PAGE: 2,
  CUSTOMER_ENGAGEMENTS_PAGE: 3,
  CUSTOMER_ENGAGEMENT_STATUS_UPDATE: 4,
  CUSTOMER_ENGAGEMENT_EDIT: 5,
  CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT: 6,
  CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE: 7,
  CUSTOMER_ENGAGEMENTS_PAGE_VIEW_CONSULTANTS_COLUMN: 8,
  CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE: 9,
  CUSTOMER_ENGAGEMENT_ALLOCATION_STATUS_UPDATE: 10,

  INTERNAL_ENGAGEMENT_CREATE_PAGE: 11,
  MARKETING_EVENT_CREATE_PAGE: 12,
  INTERNAL_ENGAGEMENTS_PAGE: 13,
  INTERNAL_ENGAGEMENT_STATUS_UPDATE: 14,
  INTERNAL_ENGAGEMENT_EDIT: 15,
  INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT: 16,
  INTERNAL_ENGAGEMENT_REQUEST_STATUS_UPDATE: 17,
  INTERNAL_ENGAGEMENTS_PAGE_VIEW_CONSULTANTS_COLUMN: 18,
  INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE: 19,
  INTERNAL_ENGAGEMENT_ALLOCATION_STATUS_UPDATE: 20,

  TEAM_ALLOCATIONS_PAGE: 21,
  CONSULTANT_ALLOCATIONS_REPORT: 22,
  ROLE_SETTINGS_PAGE: 23,
  PARTNER_RESOURCES_MGT_PAGE: 24,
  ALLOCATION_TYPES_SETTINGS_PAGE: 25,
};

export const DATE_DISPLAY_FORMAT = "yyyy-MM-dd";
