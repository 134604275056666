import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  TodayMarker,
  CustomMarker
} from 'react-calendar-timeline/lib'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { getEndDateTime } from '../utils/utils';
import Chip from '@mui/material/Chip';
import { DefaultAllocationStatus } from '../../Config';
import { defaults } from 'chart.js';
/**
Format for a group in groups array:
  {
  id: workEmail,
  title: workEmail
  }
 */

/**
 Format for an item in items array:
  {
  id: uniqueId,
  group: email,
  title: allocationType,
  start_time: startTime,
  end_time: endTime
 // itemProps: {
 // 'data-tip': tip
 // }
  }
 */
const useStyles = makeStyles((theme) => ({
  timelineControl: {
    'overflow-y': 'auto',
    margin: theme.spacing(1),
    minWidth: 500,
    width: "100%",
    maxWidth: '100%',
    maxHeight: 300,
    overflow:'auto',
  },
  sticky: {
    position: "sticky",
    top: 0,
    "z-index": 1000,
  }
}));

const groupRenderer = ({ group }) => {
  return (
    <div >
    {/* style={{backgroundColor: group.color, padding: '0 5px', margin:'0 5px', }} title={group.tooltip}> */}
      {group.title && <Chip className="custom-group" size="small" label={group.title} title={group.tooltip} style={{ backgroundColor:group.color, color: "black", borderRadius:3, width:'98%', paddingLeft: 3, marginLeft:3, marginRight: -5}} />}
            
      {/* <span>{group.title}</span> */}
    </div>
  )
}

const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const backgroundColor = itemContext.selected ? (itemContext.dragging ? "#F37878" : item.selectedBgColor) : item.bgColor;
  const borderColor = itemContext.resizing ? "#F37878" : item.color;

  return (
    <>
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          },
        })}
        title={`${item.tooltip}` + `${item.tooltipExtra ? "\n" + item.tooltipExtra : ""}`}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        {itemContext.selected && item.isRemovable && (
          <button
            // className="far fa-times-circle"
            style={{
              float: "right",
              height: itemContext.dimensions.height - 2,
              borderRadius: 4,
              borderWidth: 1
            }}
            onClick={() => {
              item.onRemoveItem();
            }}
          >
            X
          </button>
        )}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    </>
  );
};

const AllocationsTimeline = ({ startDate, endDate, groups, items, onDoubleClick, onItemDoubleClick, sidebarWidth, style, engStartDate, engEndDate }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControl className={classes.timelineControl} style={style}>
          <Timeline
            groups={groups}
            items={items}
            defaultTimeStart={startDate ? startDate : moment().add(-12, 'hour')}
            defaultTimeEnd={endDate ? endDate : moment().add(12, 'hour')}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
            onCanvasDoubleClick={onDoubleClick}
            onItemDoubleClick={onItemDoubleClick}
            itemTouchSendsClick={false}
            stackItems
            showCursorLine
            canMove={false}
            canResize={false}
            sidebarWidth={sidebarWidth ? sidebarWidth : 200}
          >
            <TimelineMarkers>
              <TodayMarker>
                {({ styles, date }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: '#0AA1DD',// light blue
                    width: '4px',
                    
                  }
                  return <div style={customStyles} />
                }}
              </TodayMarker>
              {engStartDate && (
                <CustomMarker date={engStartDate}>
                  {({ styles, date }) => {
                    const customStyles = {
                      ...styles,
                      backgroundColor: '#ff7300',// primary color
                      width: '4px'
                    }
                    return <div style={customStyles} />
                  }}
                </CustomMarker>
              )}

              {engEndDate && (
                <CustomMarker date={getEndDateTime(new Date(engEndDate.getTime()))}>
                  {({ styles, date }) => {
                    const customStyles = {
                      ...styles,
                      backgroundColor: '#ff7300',
                      width: '4px'
                    }
                    return <div style={customStyles} />
                  }}
                </CustomMarker>
              )}

            </TimelineMarkers>
            <TimelineHeaders className={classes.sticky} style={{ backgroundColor: '#92A9BD' }}>
              <SidebarHeader >
                {({ getRootProps }) => {
                  return <div {...getRootProps()}><span style={{paddingInline: 82, paddingTop: 10, color: '#fff'}}>Email</span></div>;
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
        </FormControl>
      </Grid>
    </Fragment>

  );
};

export default AllocationsTimeline;