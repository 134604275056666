import React, { useReducer, useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';

import EngagementFilterBySearch from './EngagementFilterBySearch';
import FilterByCheckBoxes from '../utils/FilterByCheckBoxes';
import EngagementTable from "./EngagementsTable";
import ConfirmationDialog from '../ConfirmationDialog';
import { DIALOG_TYPES, DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_OFFSET, ENGAGEMENT_TABLE_COLUMN_FIELD_MAP, SORT_ORDER } from "../utils/AppConstants";
import { AppConfig, EngagementStatus, RequestStatus, FormConstants } from '../../Config';
import { useAllocationTypes } from "../DataContext";
import { getDateFromString, isInternal, getCurrentYear, formatDateWithDashes } from '../utils/utils';
import BackdropProgress from '../BackdropProgress';

import useHttp from '../utils/http';
import { getUserName } from "../utils/oauth";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        boxShadow: '0px 3px 12px #00000017',
        color: theme.palette.text.secondary,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%'
    },
    page: {
        padding: 20,
        flexGrow: 1,
        boxShadow: '0px 3px 12px #00000017',
    },
}));

const ACTIONS = {
    UPDATE_REQUEST_STATUS: 'UPDATE_REQUEST_STATUS',
    UPDATE_ENGAGEMENT_STATUS: 'UPDATE_ENGAGEMENT_STATUS'
};

const Engagements = ({ isInternalEngagement }) => {
    const { handleRequest } = useHttp();

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");
    const [isLoading, setIsLoading] = useState(false);

    let allocationTypes = useAllocationTypes();

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [checkboxStateBU, setCheckboxStateBU] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const today = new Date();
    const initialSearchFiltersState = {
        type: null,
        account: "",
        allocationType: '',
        engagementStatus: "",
        requestStatus: "",
        startDate: new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
        endDate: null
    };

    const [searchFilters, setSearchFilters] = useState(initialSearchFiltersState);
    const [tablePageSize, setTablePageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);
    const [isResetPageIndex, setIsResetPageIndex] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [sortBy, setSortBy] = useState(null);

    const [checkboxStateEngagementType, setCheckboxStateEngagementType] = useState([
        { label: "Customer Delivery", name: "Customer Delivery", type: FormConstants.Types.Customer, excludeStrings: ["-PR"], isChecked: false },
        { label: "Pre-Sale", name: "Pre-Sale", type: FormConstants.Types.Customer, searchStrings: ["-PR"], isChecked: false },
        { label: "R&D", name: "R&D", type: FormConstants.Types.Internal, searchStrings: ["RND-"], isChecked: false },
        { label: "Marketing", name: "Marketing", type: FormConstants.Types.Internal, searchStrings: ["MKT-"], isChecked: false },
        { label: "Sales", name: "Sales", type: FormConstants.Types.Internal, searchStrings: ["SAL-", "CHA-"], isChecked: false },
        { label: "Support & Delivery", type: FormConstants.Types.Internal, searchStrings: ["DEL-"], name: "Support & Delivery", isChecked: false },
        { label: "Solutions Architecture", type: FormConstants.Types.Internal, searchStrings: ["PRE-"], name: "Solutions Architecture", isChecked: false },
        { label: "Other Operations", type: FormConstants.Types.Internal, searchStrings: ["FIN-", "LEG-", "ADM-", "HRM-", "DIG-", "LED-"], name: "Other Operations", isChecked: false },
    ]);

    const engagementsReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.UPDATE_REQUEST_STATUS:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails
                }
            case ACTIONS.UPDATE_ENGAGEMENT_STATUS:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails
                }
            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails }, dispatchForm] = useReducer(engagementsReducer, { confirmDialogDetails: { open: false } });

    const getEngagementData = useCallback(async (limit, offset, searchString, sortByObj) => {
        if (validateFormFields(searchFilters)) {
            let engagements = [];
            const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagements;

            let filterBUList = checkboxStateBU.filter(checkbox => (
                checkbox.isChecked
              )).map(trueCheckbox => (
                trueCheckbox.name
              ));

            let filterTypeList = checkboxStateEngagementType.filter(checkbox => (
                checkbox.isChecked &&
                checkbox.type === (isInternalEngagement ? FormConstants.Types.Internal : FormConstants.Types.Customer)
            ));

            let engagementCodeSearchStringList = [];
            let engagementCodeExcludeStringList = [];

            filterTypeList.forEach((checkbox) => {
                if (checkbox.searchStrings) {
                    engagementCodeSearchStringList = engagementCodeSearchStringList.concat(checkbox.searchStrings);
                }
                if (checkbox.excludeStrings) {
                    engagementCodeExcludeStringList = engagementCodeExcludeStringList.concat(checkbox.excludeStrings);
                }
            });

            let body = {
                type: isInternalEngagement ? FormConstants.Types.Internal : FormConstants.Types.Customer,
                engagementType: searchFilters.type && searchFilters.type.id,
                account: searchFilters.account && searchFilters.account.customerName,
                allocationType: searchFilters.allocationType && searchFilters.allocationType.allocationTypeName,
                engagementStatus: searchFilters.engagementStatus,
                requestStatus: searchFilters.requestStatus,
                startDate: searchFilters.startDate ? formatDateWithDashes(searchFilters.startDate) : "",
                endDate: searchFilters.endDate ? formatDateWithDashes(searchFilters.endDate) : "",
                businessUnits: filterBUList,
                engagementCodeSearchStrings: engagementCodeSearchStringList,
                engagementCodeExcludeStrings: engagementCodeExcludeStringList,

                limit: limit ?? tablePageSize,
                offset: offset ?? DEFAULT_TABLE_OFFSET,

                searchString: searchString ?? filterText,
                searchColumns: ["id", "customerName", "opportunityName", "engagementCode", "allocationType", "engagementNature", 
                    "country", "startDate", "endDate", "requestStatus", "engagementStatus", "allocationConsultant", "allocationStartDate", "allocationEndDate"],
                
                sortColumn: sortByObj ? ENGAGEMENT_TABLE_COLUMN_FIELD_MAP[sortByObj.id] : 
                    (sortBy ? ENGAGEMENT_TABLE_COLUMN_FIELD_MAP[sortBy.id] : null),
                sortOrder: sortByObj ? (sortByObj.desc ? SORT_ORDER.DESC : SORT_ORDER.ASC) : 
                    (sortBy ? (sortBy.desc ? SORT_ORDER.DESC : SORT_ORDER.ASC) : null)
            }

            handleRequest(endPointUrl, 'POST', body, (data) => {
                data.forEach((item, index) => {
                    let consultantsList = [];
                    item.consultants.forEach(consultant => {
                        consultantsList.push(`${consultant.email} (${getDateFromString(consultant.allocationStartDate)} - ${getDateFromString(consultant.allocationEndDate)})`);
                    })
                    engagements.push({
                        engagementId: item.id,
                        type: item.type,
                        customerName: item.customerName,
                        opportunityName: item.opportunityName,
                        engagementCode: item.engagementCode,
                        allocationTypeName: item.allocationTypeName,
                        engagementNature: item.engagementNature,
                        country: item.country,
                        startDate: item.startDate && getDateFromString(item.startDate),
                        endDate: item.endDate && getDateFromString(item.endDate),
                        action: "",
                        consultants: consultantsList,
                        requestStatus: item.requestStatus,
                        engagementStatus: item.engagementStatus,
                        productName: item.productName,
                        address: item.address,
                        customerContact: item.customerContact,
                        businessDomain: item.businessDomain,
                        opportunityOwner: item.opportunityOwner,
                        technicalOwner: item.technicalOwner,
                        isContractsSigned: item.isContractsSigned,
                        engagementScope: item.engagementScope,
                        comment: item.comment,
                        username: item.username
                    });
                });

                setData(engagements);
            }, () => {
                setMessageBar("An error occurred in searching", true, "error");
                setData([]);
            }, setIsDataLoading);
        } else {
            setMessageBar("There are errors in the search fields.", true, "error");
        }

    }, [isInternalEngagement, tablePageSize, filterText, checkboxStateBU, sortBy, searchFilters, checkboxStateEngagementType]);

    const validateFormFields = (searchFilters) => {
        let isValid = true;

        if (isNaN(searchFilters.startDate) || isNaN(searchFilters.endDate)) {
            isValid = false;
        }

        return isValid;
    }

    const getAllocationTypeNameById = (id) => {
        return (allocationTypes && allocationTypes.find(type => (type.id === id))) ? allocationTypes.find(type => (type.id === id)).allocationTypeName : id;
    };

    const handleDialogBoxAction = async (isConfirm) => {
        switch (confirmDialogDetails.id) {
            case 'updateRequestStatus':
                return handleRequestStatusUpdate(isConfirm);
            case 'updateEngagementStatus':
                return handleEngagementStatusUpdate(isConfirm);
            default:
                return;
        }

    };

    const handleRequestStatusUpdate = (isConfirm) => {
        if (isConfirm) {
            updateRequestStatus(confirmDialogDetails.engagementId, confirmDialogDetails.status);
        }

        dispatchForm({
            type: ACTIONS.UPDATE_REQUEST_STATUS,
            confirmDialogDetails: {
            },
        });
    }

    const updateRequestStatus = (engagementId, status) => {
        let reqData = {
            status: status,
        }

        const endPointUrl = AppConfig.baseUrl + AppConfig.updateRequestStatus.replace("$id", engagementId);

        handleRequest(endPointUrl, 'PATCH', reqData, (resData) => {
            setMessageBar('Request Status updated successfully', true, 'success');

            let updatedData = [...data];

            if (updatedData.find(engagement => engagement.engagementId === engagementId)) {
                updatedData.find(engagement => engagement.engagementId === engagementId).requestStatus = status;
                setData(updatedData);
            }
        }, () => {
            setMessageBar('An Error occurred in updating Request Status', true, 'error');
        }, setIsLoading);
    }

    const handleEngagementStatusUpdate = (isConfirm) => {
        if (isConfirm) {
            // From old code:
            // if we update the last_updated_date there will be an issue in SF record timestamp.
            // we might need to introduce a new field
            //var currDate = new Date();

            let reqData;

            if (confirmDialogDetails.engagementStatus === EngagementStatus.CANCELLED) {
                reqData = {
                    engagementStatus: confirmDialogDetails.engagementStatus,
                    status: RequestStatus.CANCELLED
                }
            } else {
                reqData = {
                    engagementStatus: confirmDialogDetails.engagementStatus
                }
            }


            const endPointUrl = AppConfig.baseUrl + AppConfig.updateEngagementStatus.replace("$id", confirmDialogDetails.engagementId);

            handleRequest(endPointUrl, 'PATCH', reqData, (resData) => {
                let engagementId = confirmDialogDetails.engagementId;
                let updatedData = [...data];

                if (updatedData.find(engagement => engagement.engagementId === engagementId)) {
                    updatedData.find(engagement => engagement.engagementId === engagementId).engagementStatus = confirmDialogDetails.engagementStatus;

                    if (confirmDialogDetails.engagementStatus === EngagementStatus.CANCELLED) {
                        updatedData.find(engagement => engagement.engagementId === engagementId).requestStatus = RequestStatus.CANCELLED;
                    }

                    setData(updatedData);
                }
                setMessageBar('Engagement Status updated successfully', true, 'success');
            }, () => {
                setMessageBar('An error occurred in Engagement Status update', true, 'error');
            }, setIsLoading);
        }

        dispatchForm({
            type: ACTIONS.UPDATE_ENGAGEMENT_STATUS,
            confirmDialogDetails: {
            },
        });
    }

    const getBusinessUnits = useCallback(async () => {
        let buCheckBoxList = [];
        const endPointUrl = AppConfig.baseUrl + AppConfig.getBusinessUnitsList;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                buCheckBoxList.push({ 
                    id: item.id, 
                    label: item.isActive ? item.name : `${item.name} (Deprecated)`, 
                    name: item.name, 
                    isChecked: false })
            });

            setCheckboxStateBU(buCheckBoxList);
        })
    });

    useEffect(() => {
        getBusinessUnits();
    }, []);

    const fetchDataOnSearch = () => {
        setIsResetPageIndex(prev => !prev);
        getEngagementData();
    }

    const fetchData = (pageSize, pageIndex, filterText, sortBy) => {
        let sortByObj = sortBy instanceof Array ? (sortBy.length > 0 ? sortBy[0] : null) : sortBy;
        getEngagementData(pageSize, pageSize * pageIndex, filterText, sortByObj);
    };

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    return (
        <React.Fragment>
            <Portal>
                <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                    <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Portal>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        {isInternalEngagement ? "Internal Engagements" : "Customer Engagements"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <EngagementFilterBySearch isInternalEngagement={isInternalEngagement} fetchDataOnSearch={fetchDataOnSearch}
                        searchFilters={searchFilters} setSearchFilters={setSearchFilters} initialSearchFiltersState={initialSearchFiltersState} />
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FilterByCheckBoxes formGroupLabel=" Filter by Business Unit" checkBoxes={checkboxStateBU} setCheckboxesState={setCheckboxStateBU} fetchData={fetchDataOnSearch} />
                    </Grid>
                    <Grid item xs={6}>
                        <FilterByCheckBoxes formGroupLabel="Filter by Engagement Type" setCheckboxesState={setCheckboxStateEngagementType} fetchData={fetchDataOnSearch}
                            checkBoxes={checkboxStateEngagementType.filter(item => item.type === (isInternalEngagement ? FormConstants.Types.Internal : FormConstants.Types.Customer))} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <EngagementTable data={data} isLoading={isDataLoading} isInternalEngagement={isInternalEngagement}
                    dispatchForm={dispatchForm} setTablePageSize={setTablePageSize} fetchData={fetchData} 
                    isResetPageIndex={isResetPageIndex} setFilterText={setFilterText} setSortBy={setSortBy} />
            </Grid>

            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={DIALOG_TYPES.YES_NO_DIALOG} />

            <BackdropProgress open={isLoading} />

        </React.Fragment >
    );
};

export default Engagements;