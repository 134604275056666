import React, { Fragment, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { AllocationStatus } from '../../Config';
import Chip from '@mui/material/Chip';
import { render } from "react-dom";

import TableComponent from '../TableComponent';
import { STATUS_COLORS } from '../utils/AppConstants';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
  btnControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <textarea maxLength="255" style={{ width: "100%", outline: 'none', padding: '0 3px',height: "90%" }} value={value} onChange={onChange} onBlur={onBlur} />
};

const AllocationRecordsTable = ({  isInternalEngagement, addedAllocationsList, openUpdateAllocationStatusPopup, updateComment,
  setTablePageSize, fetchData, isResetPageIndex, setFilterText, setSortBy }) => {
  const classes = useStyles();

  // const [data, setData] = useState(addedAllocationsList ? addedAllocationsList : []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Consultant',
        accessor: 'email',
      },
      //   {
      //     Header: 'Role',
      //     accessor: 'role',
      //   },
      {
        Header: 'Start Date',
        accessor: 'startDateDisp',
      },
      {
        Header: 'End Date',
        accessor: 'endDateDisp',
      },
      {
        Header: 'Hourly',
        accessor: 'startEndTime',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: EditableCell
      },
      {
        Header: 'Allocation Status',
        accessor: 'clearanceStatus',
        minWidth: 130,
       
        Cell: ({ row }) => {
          if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATION_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATION_STATUS_UPDATE])) {
            return <div>
              <Tooltip title="Update Allocation Status" >
                <IconButton aria-label="edit" onClick={() => openUpdateAllocationStatusPopup(row.original)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>      
              <Chip size="small" label={ row.original.clearanceStatus} style={{ backgroundColor: getAllocationStatusColor(row.original.clearanceStatus), color: "white", borderRadius:5, height: 25, width:150 }} />
            </div>
            
          } else {
            return (
              <Chip size="small" label={ row.original.clearanceStatus} style={{ backgroundColor: getAllocationStatusColor(row.original.clearanceStatus), color: "white", borderRadius:5, height: 25, width:150 }} />
            )
          }       
        }
      }
    ],
    [addedAllocationsList]
  );

  const getAllocationStatusColor = (status) => {
    switch (status) {
      case AllocationStatus.CLEARANCE_IN_PROGRESS:
        return STATUS_COLORS.IN_PROGRESS; // yellow
      case AllocationStatus.ACCEPTED_BY_CONSULTANT:
        return STATUS_COLORS.CONFIRMED; // orange
      case AllocationStatus.REJECTED_BY_CONSULTANT:
      case AllocationStatus.REJECTED_VISA_ISSUES:
      case AllocationStatus.REJECTED_OTHER:
      case AllocationStatus.ALLOCATION_CANCELLED:
        return STATUS_COLORS.CANCELLED; // gray
      case AllocationStatus.CONFIRMED_VISA_PENDING:
        return STATUS_COLORS.PENDING; // orange
      case AllocationStatus.CONFIRMED:
        return STATUS_COLORS.CONFIRMED; // green
      default:
        return STATUS_COLORS.DEFAULT;//pink
    }
  };

  const [originalData] = React.useState(addedAllocationsList);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const updateData = (rowIndex, columnId, value) => {
    let rowData = addedAllocationsList[rowIndex];

    updateComment(rowData.id, value); 
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
  }

  // After data changes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [addedAllocationsList]);

  return (
    <Fragment>
      <TableComponent columns={columns}
        data={addedAllocationsList}
        updateData={updateData}
        skipPageReset={skipPageReset}
        setTablePageSize={setTablePageSize}
        fetchData={fetchData}
        isResetPageIndex={isResetPageIndex}
        isManualProcessing={true}
        setFilterText={setFilterText}
        setSortBy={setSortBy}
      />

    </Fragment>
  );
};

export default AllocationRecordsTable;