import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import randomColor from "randomcolor";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';

import { formatDateWithDashes, getRoundOffNumber } from '../utils/utils';
import useHttp from '../utils/http';
import { AppConfig, FormConstants, DATE_DISPLAY_FORMAT } from '../../Config';

import { makeStyles } from '@material-ui/core/styles';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TableComponent from '../TableComponent';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BackdropProgress from '../BackdropProgress';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';
import { style, textAlign } from '@mui/system';
import theme from '../../theme';
import { Table } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '10%',
    minWidth: 100,
  },
  page: {
    padding: 20,
  },
  createNewButton: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    //boxShadow: theme.shadows[24],
    boxShadow: '0px 3px 12px #00000017',
    padding: theme.spacing(2, 4, 3),
    // minWidth: 1000,
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200,
  },
  formControlDate: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlRadio: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  btnControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
}));

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title);

ChartJS.register(ChartDataLabels);

ChartJS.register({
  id: 'pieCharts',
  afterDraw: function (chart) {
    // if (!chart.data.datasets.data || chart.data.datasets.data.length === 0) {
    // No data is present
    if (chart.data.datasets[0].data.length < 1) {
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height;
      // chart.clear();

      // ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "25px bold 'Arial' '#7C99AC'" ;
      // ctx.fillText(chart.options.plugins.title.text, width / 2, 18);
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  }
});

const Dashboard = () => {
  const classes = useStyles();
  const { handleRequest } = useHttp();

  const [businessUnits, setBusinessUnits] = useState([]);
  const [businessUnit, setBusinessUnit] = useState("");

  const [allAllocationsChartData, setAllAllocationsChartData] = useState(null);
  const [paidAllocationsChartData, setPaidAllocationsChartData] = useState(null);
  const [nonPaidAllocationsChartData, setNonPaidAllocationsChartData] = useState(null);

  const [paidAllocationsData, setPaidAllocationsData] = useState([]);
  const [nonPaidAllocationsData, setNonPaidAllocationsData] = useState([]);

  const [isAllocationsByTypeLoading, setIsAllocationsByTypeLoading] = useState(false);
  const [isPaidAllocationsDataLoading, setIsPaidAllocationsDataLoading] = useState(false);
  const [isNonPaidAllocationsDataLoading, setIsNonPaidAllocationsDataLoading] = useState(false);

  const [isTodayDashboard, setIsTodayDashboard] = useState("true");
  const [pastDate, setPastDate] = useState(null);
  const [maxPastDate, setMaxPastDate] = useState(null);

  const [isBackdropProgressOpen, setIsBackdropProgressOpen] = useState(false);

  const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("error");

  const columns = useMemo(
    () => [
      {
        Header: 'Allocation Type',
        accessor: 'allocationTypeName',
        collapse: true,
      },
      {
        Header: 'Count',
        accessor: 'count',
        collapse: true,
        maxWidth: 15,
        style: {textAlign: 'right'}
      },
      {
        Header: '%',
        accessor: 'countPercentage',
        collapse: true,
        maxWidth: 20,
        style: {textAlign: 'right'}
      },
    ],
    []
  );

  // to add a predefined color to each engagement type
  const typeColors = {
    [FormConstants.EngagementTypes.Paid.id]: '#665C9D',
    [FormConstants.EngagementTypes.InternalEngagement.id]: '#F78769',
    [FormConstants.EngagementTypes.NonPaidPreSale.id]: '#82E4F0',
    [FormConstants.EngagementTypes.NonPaidPostSale.id]: '#FEAA5B',
    [FormConstants.EngagementTypes.MarketingEvent.id]: '#398AB9'
  };

  const getBusinessUnits = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getBusinessUnitsList;

    handleRequest(endPointUrl, 'GET', null, (data) => {
      let buList = [{ id: null, name: "All"}];

      data.forEach(item => {
          buList.push({ id: item.id, name: item.name });
      });

      setBusinessUnits(buList);
      setBusinessUnit(buList[0]);
    })
  })

  const getAllocationsWithEngagementType = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationsWithEngagementType;
    const params = `businessUnitId=${businessUnit?.id ?? ''}&pastDate=${isTodayDashboard === "false" && pastDate ? formatDateWithDashes(pastDate) : ''}`;
    const requestUrl = `${endPointUrl}?${params}`;

    handleRequest(requestUrl, 'GET', null, (data) => {

      let engagementTypes = [];
      let chartData = [];
      let colors = [];

      const getColor = (type) => {
        return typeColors[type];
      }

      if (data.length > 0) {
        data.forEach(item => {
          engagementTypes.push(item.engagementType);
          chartData.push(item.allocationCount);
          colors.push(getColor(item.engagementTypeId));
        })
      }

      setAllAllocationsChartData({
        labels: engagementTypes,
        datasets: [
          {
            data: chartData,
            backgroundColor: colors,
            borderWidth: 1,
          },
        ],
      });

      showSuccessMsg();
    }, () => {
      showErrorMsg();
    },
      setIsAllocationsByTypeLoading
    );
  })

  const getPaidAllocationsWithAllocationType = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getPaidAllocationsWithAllocationType;
    const params = `businessUnitId=${businessUnit?.id ?? ''}&pastDate=${isTodayDashboard === "false" && pastDate ? formatDateWithDashes(pastDate) : ''}`;
    const requestUrl = `${endPointUrl}?${params}`;

    handleRequest(requestUrl, 'GET', null, (data) => {

      let randomSeed = Math.floor(Math.random() * 1000);
      let i = 0;

      let allocationTypes = [];
      let chartData = [];
      let backgroundColors = [];
      let borderColors = [];

      let total = 0;
      let paidAllocationsData = [];

      data.forEach(item => {
        total = total + item.allocationCount;
      });

      data.forEach(item => {
        paidAllocationsData.push({
          allocationTypeName: item.allocationTypeName,
          count: item.allocationCount,
          countPercentage: getRoundOffNumber((item.allocationCount / total) * 100)
        });

        allocationTypes.push(item.allocationTypeName);
        chartData.push(item.allocationCount);

        backgroundColors.push(
          backgroundColors.includes(item.allocationTypeColor) ?
            randomColor({
              luminosity: "dark",
              seed: randomSeed + i,
            })

            : item.allocationTypeColor
        );

        i++;
      });

      setPaidAllocationsChartData({
        labels: allocationTypes,
        datasets: [
          {
            data: chartData,
            backgroundColor:
              backgroundColors,
            borderWidth: 1,
          },
        ],
      });

      setPaidAllocationsData(paidAllocationsData);

      showSuccessMsg();
    }, () => {
      showErrorMsg();
    }, setIsPaidAllocationsDataLoading);
  })

  const getNonPaidAllocationsWithAllocationType = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getNonPaidAllocationsWithAllocationType;
    const params = `businessUnitId=${businessUnit?.id ?? ''}&pastDate=${isTodayDashboard === "false" && pastDate ? formatDateWithDashes(pastDate) : ''}`;
    const requestUrl = `${endPointUrl}?${params}`;

    handleRequest(requestUrl, 'GET', null, (data) => {

      let randomSeed = Math.floor(Math.random() * 1000);
      let i = 0;

      let allocationTypes = [];
      let chartData = [];
      let backgroundColors = [];
      let borderColors = [];

      let total = 0;
      let nonPaidAllocationsData = [];

      data.forEach(item => {
        total = total + item.allocationCount;
      });

      data.forEach(item => {
        nonPaidAllocationsData.push({
          allocationTypeName: item.allocationTypeName,
          count: item.allocationCount,
          countPercentage: getRoundOffNumber((item.allocationCount / total) * 100)
        });

        allocationTypes.push(item.allocationTypeName);
        chartData.push(item.allocationCount);

        backgroundColors.push(
          backgroundColors.includes(item.allocationTypeColor) ?
            randomColor({
              luminosity: "dark",
              seed: randomSeed + i,
            })

            : item.allocationTypeColor
        );

        i++;
      });

      setNonPaidAllocationsChartData({
        labels: allocationTypes,
        datasets: [
          {
            data: chartData,
            backgroundColor: backgroundColors,
            // borderColor: borderColors,
          },
        ],
      });

      setNonPaidAllocationsData(nonPaidAllocationsData);

      showSuccessMsg();
    }, () => {
      showErrorMsg();
    }, setIsNonPaidAllocationsDataLoading);
  });

  const showErrorMsg = () => {
    if (!(isAllocationsByTypeLoading || isPaidAllocationsDataLoading || isNonPaidAllocationsDataLoading)) {
      setMessageBar("An error occurred in retrieving data", true, "error");
    }
  }

  const showSuccessMsg = () => {
    if (!(isAllocationsByTypeLoading || isPaidAllocationsDataLoading || isNonPaidAllocationsDataLoading)) {
      setMessageBar("Successfully retrieved chart data", true, "success");
    }
  }

  useEffect(() => {
    getBusinessUnits();

    let initDate = new Date();
    initDate.setDate(initDate.getDate() - 1);
    initDate.setHours(0, 0, 0, 0);

    setMaxPastDate(initDate);
    setPastDate(initDate);
  }, []);

  useEffect(() => {
    if (getValidation(isTodayDashboard, pastDate)) {
      getAllocationsWithEngagementType();
      getPaidAllocationsWithAllocationType();
      getNonPaidAllocationsWithAllocationType();
    }
  }, [businessUnit, isTodayDashboard, pastDate]);

  useEffect(() => {
    setIsBackdropProgressOpen(isAllocationsByTypeLoading || isPaidAllocationsDataLoading || isNonPaidAllocationsDataLoading);
  }, [isAllocationsByTypeLoading, isPaidAllocationsDataLoading, isNonPaidAllocationsDataLoading]);

  const getValidation = (isTodayDashboard, pastDate) => {
    let isValid = true;

    if (isTodayDashboard === "false") {
      if (isNaN(pastDate) || pastDate.getTime() > maxPastDate.getTime()) {
        isValid = false;
      }
    }

    return isValid;
  }

  const handleInputChange = (e) => {
    setBusinessUnit(e.target.value);
  }

  const setDashboardType = (e) => {
    setIsTodayDashboard(e.target.value);
  }

  const handlePastDateChange = (date) => {
    if (date) {
      date.setHours(0, 0, 0, 0);
      setPastDate(date);
    }
  };

  const setMessageBar = (message, isOpen, type) => {
    setAlertMessage(message);
    setIsMessageBarOpen(isOpen);
    setAlertMessageType(type);
  };

  const handleMessageBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsMessageBarOpen(false);
    setAlertMessage("");
  };

  const getOptionsForChart = (title) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          
        },
        title: {
          align: 'center',
          fullSize: true,
          font: {
            size: 20,
            
          },
          display: true,
          text: title       
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (data) {
              let total = data.dataset.data.reduce((prevVal, currVal) => prevVal + currVal);
              let percentage = getRoundOffNumber((data.parsed / total) * 100);
              let tooltipLabel = data.label;

              return tooltipLabel + ": " + data.formattedValue + " (" + percentage + "%)";
            }
          }
        },
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'start',
          color: "white",
          font: {
            size: '13px',
            weight: 'bold'
          },
          padding: 6,

          formatter: (value, ctx) => {
            return value;
          }
        },
      },
      maintainAspectRatio: false,
    }
  }
  return (
    <React.Fragment>
      <Paper square className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" display="block" gutterBottom>
              Dashboard
            </Typography>
          </Grid>
         {/* <Grid item xs={5}>
          </Grid>   */}
          <Grid item xs={12}>
            {/* <Grid item xs={6}>  */}
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="demo-simple-select-helper-label">Select Business Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={businessUnit}
                  name="businessUnit"
                  onChange={handleInputChange}
                  label="Select Business Unit"
                >
                  {businessUnits && businessUnits.sort((a, b) => -b.name.localeCompare(a.name)).map(businessUnit => (
                    <MenuItem key={businessUnit.id} value={businessUnit}>{businessUnit.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            {/* </Grid>  */}
            {/* <Grid item xs={6}> */}
              <FormControl row variant="outlined" style={{ width: '84%', alignItems: 'flex-end'}}>
                <RadioGroup row aria-label="position" name="position" value={isTodayDashboard} onChange={setDashboardType}>


                  {/* <Grid item xs={6}> */}
                    <FormControlLabel
                      row
                      value="true"
                      control={<Radio color="primary" />}
                      label="Today"
                    />
                  {/* </Grid> */}

                  {/* <Grid item xs={12}> */}
                    <FormControlLabel
                      row
                      value="false"
                      control={<Radio color="primary" />}
                      label="Past Date"
                    />
                    {(isTodayDashboard === "false") && (
                      <FormControl className={classes.formControlDate} variant="outlined">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            disableToolbar
                            format={DATE_DISPLAY_FORMAT}
                            label="Select Date"
                            id="date-picker-inline"
                            maxDate={maxPastDate}
                            value={pastDate}
                            onChange={handlePastDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            required
                            autoOk={true}
                            okLabel=''
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    )}
                  {/* </Grid> */}

                </RadioGroup>
              </FormControl>
            {/* </Grid>  */}
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {allAllocationsChartData &&
            <Grid item xs={12}>
               <Paper square className={classes.paper}>
               <Grid container spacing={1}>
               <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <Pie data={allAllocationsChartData}
                    width={350}
                    height={350}
                    style={{ margin: '5px'}}
                    options={getOptionsForChart("Allocations by Engagement Type")}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                </Grid>
               </Paper>  
             
            </Grid>
          }

          {paidAllocationsChartData &&
            <Grid item xs={6}>
               <Paper style={{ boxShadow: '0px 3px 12px #00000017' }}> 
                <Paper square className={classes.paper}>
                  <Pie data={paidAllocationsChartData}
                    width={350}
                    height={350}
                    style={{ margin: '5px'}}
                    options={getOptionsForChart("Paid Allocations by Allocation Type")}
                  />
                </Paper>

                {paidAllocationsData && paidAllocationsData.length > 0 && 
                  <Paper square className={classes.paper}>
                    <TableComponent
                      columns={columns}
                      data={paidAllocationsData}
                      minRows={3}
                      showPagination={true}
                      pageSize={4}
                      style={{ height: '100%' }}
                      isLoading={isPaidAllocationsDataLoading}
                      sortByCol={{id: 'countPercentage', desc: true}}
                    />
                  </Paper>
                 } 
               </Paper> 
             </Grid> 
          }

          {nonPaidAllocationsChartData &&
            <Grid item xs={6}>
              <Paper style={{ boxShadow: '0px 3px 12px #00000017' }}>
                <Paper square className={classes.paper}>
                  <Pie data={nonPaidAllocationsChartData}
                    width={350}
                    height={350}
                    style={{ margin: '5px'}}
                    options={getOptionsForChart("Non Paid Allocations by Allocation Type")}
                  />
                </Paper>
                {nonPaidAllocationsData && nonPaidAllocationsData.length > 0 &&
                  <Paper square className={classes.paper}>
                    <TableComponent
                      columns={columns}
                      data={nonPaidAllocationsData}
                      minRows={3}
                      showPagination={true}
                      pageSize={4}
                      style={{ height: '100%' }}
                      isLoading={isNonPaidAllocationsDataLoading}
                      sortByCol={{id: 'countPercentage', desc: true}}
                    />
                  </Paper>
                }
              </Paper>
            </Grid>
          }
        </Grid>
      </Paper>

      <BackdropProgress open={isBackdropProgressOpen} />

      <Portal>
        <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
          <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Portal>

    </React.Fragment>
  );
};

export default Dashboard;