import React, { useEffect, useState, useCallback, useContext } from 'react';
import { AppConfig, Privileges } from '../Config';
import useHttp from './utils/http';
import { isAccessGranted } from './utils/utils';

const FlatListContext = React.createContext();
const OrgStructureContext = React.createContext();

export function useFlatList() {
  return useContext(FlatListContext);
}

export function useOrgStructure() {
  return useContext(OrgStructureContext);
}

export function OrgStructureProvider({ children }) {
  const { handleRequest } = useHttp();

  const [flatList, setFlatList] = useState([]);
  const [orgStructureData, setOrgStructureData] = useState([]);
  const [orgLevelCount, setOrgLevelCount] = useState(null);
  const [levelData, setLevelData] = useState([]);
  const [isOrgStructureLoading, setIsOrgStructureLoading] = useState(false);
  const [loadingErrMsg, setLoadingErrMsg] = useState("");

  const getStructureData = useCallback(async () => {
    let data;
    const endPointUrl = AppConfig.baseUrl + AppConfig.getOrgData + "?employeeStatuses=Active";

    handleRequest(endPointUrl, 'GET', null, (data) => {
      setLoadingErrMsg("");

      let orgStructureData = [];

      setFlatList(data.flatList);
      setOrgLevelCount(data.orgLevelCount);

      data.orgStructure.forEach((item) => {
        setStructureData(orgStructureData, 0, item.typeName, item.type, item.name, item, []);
      });

      setOrgStructureData(orgStructureData);

      return null;
    }, () => { setLoadingErrMsg("Error in loading org structure dropdowns") }, setIsOrgStructureLoading)
  }, []);

  const setStructureData = (orgStructureData, level, label, labelId, name, item, parents) => {
    if (!orgStructureData[level]) {
      orgStructureData[level] = {};

      setLevelData(prevData => {
        return [
          ...prevData,
          { level: level, levelName: labelId }
        ]
      });
    }

    if (!orgStructureData[level]["options"]) {
      orgStructureData[level]["options"] = [];
    }

    orgStructureData[level]["label"] = label;
    orgStructureData[level]["labelId"] = labelId;



    orgStructureData[level]["options"].push({ name: name, level: level, parents: parents });

    let children = item.children;

    if (children && children.length > 0) {
      let allParents = [
        ...parents,
        {
          level: level,
          type: labelId,
          name: name
        }
      ]

      children.forEach((child) => {
        setStructureData(orgStructureData, level + 1, child.typeName, child.type, child.name, child, allParents);
      });
    }
  };

  useEffect(() => {
    if (isAccessGranted([
      Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE, Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE, Privileges.CUSTOMER_ENGAGEMENT_EDIT, Privileges.CUSTOMER_ENGAGEMENTS_PAGE, Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT,
      Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE, Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE, Privileges.INTERNAL_ENGAGEMENT_EDIT, Privileges.INTERNAL_ENGAGEMENTS_PAGE, Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT,
      Privileges.TEAM_ALLOCATIONS_PAGE, Privileges.CONSULTANT_ALLOCATIONS_REPORT])) {
      getStructureData();
    }
  }, []);

  return (
    <FlatListContext.Provider value={flatList}>
      <OrgStructureContext.Provider value={{ orgStructureData: orgStructureData, orgLevelCount: orgLevelCount, levelData: levelData, isOrgStructureLoading: isOrgStructureLoading, loadingErrMsg: loadingErrMsg }}>
        {children}
      </OrgStructureContext.Provider>
    </FlatListContext.Provider>
  )

}