import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy, useFlexLayout } from 'react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import LoadingSpinner from './LoadingSpinner';
import EmptyData from '../images/emptyData.svg';
import { DEFAULT_TABLE_PAGE_SIZE, TABLE_AUTO_FILTER_DEBOUNCE_TIMEOUT, TABLE_MANUAL_FILTER_DEBOUNCE_TIMEOUT } from './utils/AppConstants';

const Styles = styled.div`
/* This is required to make the table full-width */
display: block;
max-width: 100%;
// height: 450px;
margin-top:20px;
overflow-y: auto;



/* This will make the table scrollable when it gets too small */
.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  height: 450px;
  // border-bottom: 1px solid black;
}

table {
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 0;
  border: 1px solid #d3d3d3;
 

  tr {
    max-height: 100px;
    border: #d3d3d3;
   

    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    font-size: '13px';


  

    /* The secret sauce */
    /* Each cell should grow equally */
    width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapse {
      width: 0.0000000001%  !important;
    }

    :last-child {
      border-right: 0;
    }
  }

  td {
    overflow-x: hidden;
    // padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.pagination {
  padding: 0.5rem;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}
`

const defaultPropGetter = () => ({})

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isManualFiltering,
  setFilterText
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setFilterText?.(value || "");
    setGlobalFilter(value || "");
  }, isManualFiltering ? TABLE_MANUAL_FILTER_DEBOUNCE_TIMEOUT : TABLE_AUTO_FILTER_DEBOUNCE_TIMEOUT)

  return (
    <span>
      Search: {' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        // placeholder={`${count} records`}
        style={{
          fontSize: '15px',
          borderWidth: '0.1px',
          width: '200px',
          height: '30px',
          outline: 'none',
          borderColor: '#DFE0DF',
          borderStyle: 'solid',
          borderRadius: '4px',
          padding: '3px',
        }}
      />
    </span>
  )
};

const TableComponent = ({ columns, data, minRows, updateData, skipPageReset, getCellProps = defaultPropGetter, isLoading, 
  sortByCol, setTablePageSize, isManualProcessing = false, fetchData, isResetPageIndex, setFilterText, setSortBy }) => {

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 100, // minWidth is only used as a limit for resizing
      width: 120, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,

    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, globalFilter },
  } = useTable({
    columns,
    data,
    defaultColumn,
    initialState: {
      pageIndex: 0,
      pageSize: DEFAULT_TABLE_PAGE_SIZE,
      sortBy: sortByCol ? [sortByCol] : [],
      globalFilter: ""
    },
    minRows,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset, // to stop resetting page on action: false
    autoResetSortBy: false,
    autoResetFilters: false,
    autoResetGlobalFilter: false,
    // updateData isn't part of the API, but anything we put into these options will automatically be available on the instance.
    // That way we can call this function from our cell renderer!
    updateData,
    manualPagination: isManualProcessing,
    manualSortBy: isManualProcessing,
    manualGlobalFilter: isManualProcessing,
    disableSortRemove: sortByCol ? true : false, // If there is a default sort column for the table, then the ability to remove the sort is disabled in table level
    disableMultiSort: true,
    pageCount: -1
  },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout
  );

  useEffect(() => {
    fetchData?.(pageSize, pageIndex, globalFilter, sortBy)
  }, [pageIndex, pageSize, globalFilter, sortBy]);

  useEffect(() => {
    gotoPage(0);
  }, [isResetPageIndex, pageSize]);

  useEffect(() => {
    setSortBy?.(sortBy instanceof Array ? (sortBy.length > 0 ? sortBy[0] : null) : sortBy);
  }, [sortBy]);

  TableComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
  }

  // Render the UI for your table
  return (
    <React.Fragment>
      <Styles>
        <div className="tableWrap">
          <table {...getTableProps()}>
            <thead>
              <tr>
                <th
                  // className="sticky"
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                    backgroundColor: '#F6F6F6',

                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isManualFiltering={isManualProcessing}
                    setFilterText={setFilterText}
                  />
                </th>
              </tr>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps([
                      {
                        // className: `${column.collapse ? 'collapse' : ''} sticky` ,
                        className: column.collapse ? 'collapse' : '',
                      },
                      column.getSortByToggleProps()])}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {

                      }
                      <span style={{ float: 'right' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowUpwardRoundedIcon fontSize='small' style={{ color: '#435560' }} />
                            : <ArrowDownwardRoundedIcon fontSize='small' style={{ color: '#435560' }} />
                          : column.disableSortBy ? '' : <SwapVertRoundedIcon fontSize='small' style={{ color: '#435560' }} />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}

            </thead>
            <tbody {...getTableBodyProps()}>

              {/* <div>No Data avaliable</div> */}
              {isLoading ? (
                <tr>
                  <td colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'center',
                    }}>

                    <LoadingSpinner />
                  </td>
                </tr>

                // <tbody>
                //   <i
                //     className="fa fa-spinner fa-pulse fa-3x fa-fw"
                //     style={{ fontSize: 36, color: "#ef6c00" }}
                //   />
                // </tbody>

              ) : data.length > 0 ? (
                page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps([
                          {
                            className: cell.column.collapse ? 'collapse' : cell.column.className,
                            style: {
                              ...cell.column.style,
                              wordBreak: 'break-word'
                            },
                          },
                          getCellProps(cell),
                        ])}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'center',
                      fontSize: '25px',
                      fontWeight: 600,
                      color: '#7C99AC',

                    }}>
                    <img src={EmptyData} alt="emptydata" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


        <div className="pagination">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'Previous'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'Next'}
          </button>{' '}

          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
              setTablePageSize?.(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>{' '}
        </div>
      </Styles>
    </React.Fragment>
  )
}

export default TableComponent;