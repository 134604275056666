import { Fragment } from "react";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { RequestStatus, Privileges } from '../../Config';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { isAccessGranted } from '../utils/utils';

const AllocationsHeaderAction = ({ isInternalEngagement, requestStatus, onClickUpdateRequestStatus }) => {

    return (
        <Fragment>
            {requestStatus === RequestStatus.COMPLETED ?
                <IconButton aria-label="update" disabled >
                    <CheckCircleOutlineIcon style={{ color: '#76BA99' }} />
                </IconButton>
                : (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_REQUEST_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE]) ?
                    <Tooltip title="Update Request Status to Completed">
                        <IconButton aria-label="update" onClick={() => { onClickUpdateRequestStatus(RequestStatus.COMPLETED) }}>
                            <CheckCircleOutlineIcon style={{ color: '#C0C0C0' }} />
                        </IconButton>
                    </Tooltip> :
                    <Tooltip title="Update Request Status to Completed">
                        <IconButton aria-label="update" disabled >
                            <CheckCircleOutlineIcon style={{ color: '#C0C0C0' }} />
                        </IconButton>
                    </Tooltip>)
            }
        </Fragment>
    );

}

export default AllocationsHeaderAction;