import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

import TableComponent from '../TableComponent';

import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    float: 'right'
  },
}));

const ConsultantAllocationsTable = ({ data, isLoading }) => {
  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Engagement Type',
        accessor: 'engagementTypeName',
        collapse: true,
      },
      {
        Header: 'Engagement Location',
        accessor: 'engagementNature',
        collapse: true,
      },
      {
        Header: 'Country',
        accessor: 'country',
        collapse: true,
      },
      {
        Header: 'Allocation Type',
        accessor: 'allocationTypeName',
        collapse: true,
      },
      {
        Header: 'Consultant E-mail',
        accessor: 'email',
        collapse: true,
        minWidth: 100,
        // maxWidth: 75,
      },
      {
        Header: 'Department',
        accessor: 'department',
        collapse: true,
        minWidth: 120,
      },
      {
        Header: 'Employee Business Unit',
        accessor: 'businessUnit',
        collapse: true,
        minWidth: 140,
      },
      {
        Header: 'Company',
        accessor: 'company',
        collapse: true,
        minWidth: 100,
        maxWidth: 110,
      },
      {
        Header: 'Engagement Code',
        accessor: 'engagementCode',
        collapse: true,
        minWidth: 100,
      },
      {
        Header: 'Daily / Hourly',
        accessor: 'dailyHourly',
        collapse: true,
        minWidth: 100,
        maxWidth: 105,
      },
      {
        Header: 'Calendar Days',
        accessor: 'calendarDays',
        collapse: true,
        minWidth: 100,
        maxWidth: 105,
      },
      {
        Header: 'Working Days',
        accessor: 'workingDays',
        collapse: true,
        minWidth: 100,
        maxWidth: 105,
      },
      {
        Header: 'Working Hours',
        accessor: 'workingHours',
        collapse: true,
        minWidth: 100,
        maxWidth: 105,
      },
      {
        Header: 'Engagement Business Unit',
        accessor: 'engagementBusinessUnit',
        collapse: true,
        minWidth: 100,
      },
      {
        Header: 'Engagement Sales POD',
        accessor: 'engagementSalesPOD',
        collapse: true,
        minWidth: 110,
      },
      {
        Header: 'Engagement Group',
        accessor: 'engagementGroup',
        collapse: true,
        minWidth: 100,
      },
      {
        Header: 'Account Name',
        accessor: 'accountName',
        collapse: true,
        minWidth: 100,

      },
      {
        Header: 'GL Code',
        accessor: 'glCode',
        collapse: true,
        minWidth: 100,
        maxWidth: 110,
      }
    ],
    []
  );

  const headers = [
    {
      label: 'Engagement Type',
      key: 'engagementTypeName',
    },
    {
      label: 'Engagement Location',
      key: 'engagementNature',
    },
    {
      label: 'Country',
      key: 'country',
    },
    {
      label: 'Allocation Type',
      key: 'allocationTypeName',
    },
    {
      label: 'Consultant E-mail',
      key: 'email',
    },
    {
      label: 'Department',
      key: 'department',
    },
    {
      label: 'Employee Business Unit',
      key: 'businessUnit',
    },
    {
      label: 'Company',
      key: 'company',
    },
    {
      label: 'Engagement Code',
      key: 'engagementCode',
    },
    {
      label: 'Daily / Hourly',
      key: 'dailyHourly',
    },
    {
      label: 'Calendar Days',
      key: 'calendarDays',
    },
    {
      label: 'Working Days',
      key: 'workingDays',
    },
    {
      label: 'Working Hours',
      key: 'workingHours',
    },
    {
      label: 'Engagement Business Unit',
      key: 'engagementBusinessUnit',
    },
    {
      label: 'Engagement Sales POD',
      key: 'engagementSalesPOD',
    },
    {
      label: 'Engagement Group',
      key: 'engagementGroup',
    },
    {
      label: 'Account Name',
      key: 'accountName',
    },
    {
      label: 'GL Code',
      key: 'glCode',
    }
  ]

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CSVLink data={data} headers={headers} filename={"consultant-allocations.csv"}>
            <FormControl className={classes.formControl}>
              <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                Download CSV
              </Button>
            </FormControl>
          </CSVLink>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            data={data ? data : []}
            minRows={3}
            showPagination={true}
            pageSize={4}
            style={{ height: '100%' }}
            isLoading={isLoading}
            fontSize='11px'
            sortByCol={{id: 'engagementTypeName', desc: true}}
          />
        </Grid>
      </Grid>
    </React.Fragment>

  )
};

export default ConsultantAllocationsTable;