import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { CssBaseline, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Collapse, Link, Button } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './MainStyle';
import { PAGES, Privileges, EMAILS, AppConfig } from '../Config';
import DashboardIcon from '../images/dashboard.svg';
import EngagementIcon from '../images/engagement.svg';
import InternalEngagementIcon from '../images/internal-engagement.svg';
import TeamAllocationsIcon from '../images/team-allocations.svg';
import ReportsIcon from '../images/reports.svg';
import SettingsIcon from '../images/settings.svg';
import { isAccessGranted, getGmailMailTo } from './utils/utils';

const DrawerMenuVariant = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}>
                <div className={classes.toolbar}></div>
                <Divider />
                <List>
                    {isAccessGranted([Privileges.DASHBOARD_PAGE]) &&
                        <NavLink to={PAGES.DASHBOARD} className={classes.navLink}>
                            <ListItem button key={"dashboard"} selected={props.page === PAGES.DASHBOARD} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={DashboardIcon} alt="dashboard" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }

                    {isAccessGranted([Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE, Privileges.CUSTOMER_ENGAGEMENTS_PAGE, Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE]) &&
                        <NavLink to={PAGES.NEW_ENGAGEMENT} className={classes.navLink}>
                            <ListItem button key={"new_engagement"} selected={props.page === PAGES.NEW_ENGAGEMENT} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={EngagementIcon} alt="new-engagement" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Customer Engagements" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }
                    {isAccessGranted([Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE, Privileges.INTERNAL_ENGAGEMENTS_PAGE, Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE]) &&
                        <NavLink to={PAGES.INTERNAL} className={classes.navLink}>
                            <ListItem button key={"events"} selected={props.page === PAGES.INTERNAL} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={InternalEngagementIcon} alt="internal" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Internal Engagements" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }
                    {isAccessGranted([Privileges.TEAM_ALLOCATIONS_PAGE]) &&
                        <NavLink to={PAGES.TEAM_ALLOCATIONS} className={classes.navLink}>
                            <ListItem button key={"team_allocations"} selected={props.page === PAGES.TEAM_ALLOCATIONS} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={TeamAllocationsIcon} alt="team_allocations" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Team Allocations" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }
                    {isAccessGranted([Privileges.CONSULTANT_ALLOCATIONS_REPORT]) &&
                        <NavLink to={PAGES.REPORTS} className={classes.navLink}>
                            <ListItem button key={"reports"} selected={props.page === PAGES.REPORTS} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={ReportsIcon} alt="reports" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Reports" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }
                    {isAccessGranted([Privileges.ROLE_SETTINGS_PAGE, Privileges.PARTNER_RESOURCES_MGT_PAGE]) &&
                        <NavLink to={PAGES.SETTINGS} className={classes.navLink}>
                            <ListItem button key={"settings"} selected={props.page === PAGES.SETTINGS} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={SettingsIcon} alt="settings" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Settings" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    }
                    {/* {isAccessGranted([Privileges.TEAM_ALLOCATIONS_PAGE]) &&
                        <NavLink to={PAGES.EVENTS} className={classes.navLink}>
                            <ListItem button key={"events"} selected={props.page === PAGES.EVENTS} className={classes.listItem}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img src={TeamAllocationsIcon} alt="events" className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Events" classes={{ primary: classes.listItemText }} />
                            </ListItem>
                        </NavLink>
                    } */}
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <Collapse in={open} orientation="horizontal" timeout={2} unmountOnExit={true} className={classes.navLink} >
                    <Box
                        sx={{
                            backgroundColor: 'background.default',
                            m: 2,
                            p: 2
                        }}
                    >

                        <Typography
                            align="center"
                            gutterBottom
                            variant="h4"
                            noWrap
                        >
                            Need help?
                        </Typography>
                        {/* <Typography
                            align="center"
                            variant="body2"
                            noWrap
                        >
                            Contact Internal Apps
                        </Typography> */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pt: 2
                            }}
                        >
                            {/* <Link href={getGmailMailTo(EMAILS.GET_HELP_EMAIL_TO, EMAILS.GET_HELP_EMAIL_SUBJECT)} target="_blank" rel="noreferrer"> */}
                            <Button
                                color="secondary"
                                variant="contained"
                                href={AppConfig.userGuideUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View User Guide
                            </Button>
                            {/* </Link> */}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pt: 2
                            }}
                        >
                            {/* <Link href={getGmailMailTo(EMAILS.GET_HELP_EMAIL_TO, EMAILS.GET_HELP_EMAIL_SUBJECT)} target="_blank" rel="noreferrer"> */}
                            <Button
                                color="secondary"
                                variant="contained"
                                href={getGmailMailTo(EMAILS.GET_HELP_EMAIL_TO, EMAILS.GET_HELP_EMAIL_SUBJECT)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Contact Internal Apps
                            </Button>
                            {/* </Link> */}
                        </Box>
                    </Box>
                </Collapse>
            </Drawer>
        </div >
    );

};

export default DrawerMenuVariant;