import React, { useCallback, useReducer, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

import AllocationFilterBySearch from './AllocationFilterBySearch';
import FilterByCheckBoxes from '../utils/FilterByCheckBoxes';
import AllocationsTable from "./AllocationsTable";
import ConfirmationDialog from '../ConfirmationDialog';
import { DIALOG_TYPES, DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_OFFSET, ALLOCATION_TABLE_COLUMN_FIELD_MAP, SORT_ORDER } from "../utils/AppConstants";
import { AppConfig, FormConstants } from '../../Config';
import { useAllocationTypes } from "../DataContext";
import { getDateFromString } from '../utils/utils';
import { countries } from '../utils/AppData';

import AddNewAllocationPopup from '../allocations/AddNewAllocationPopup';
import useHttp from '../utils/http';
import { AllocationStatus } from '../../Config';
import { isPaidEngagement, formatDateWithDashes, getDateObjectFromTimeString, getTimeZoneObj, getDateObjectFromString } from '../utils/utils';

import moment from 'moment';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        boxShadow: '0px 3px 12px #00000017',
        color: theme.palette.text.secondary,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%'
    },
    page: {
        padding: 20,
        flexGrow: 1,
    },
}));

const ACTIONS = {
    UPDATE_ALLOCATION_STATUS: 'UPDATE_ALLOCATION_STATUS'
};

const EngagementAllocations = ({ isInternalEngagement }) => {
    const classes = useStyles();
    const { handleRequest } = useHttp();

    let allocationTypes = useAllocationTypes();

    const [data, setData] = useState([]);
    const [prevClearanceStatus, setPrevClearanceStatus] = useState("");
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [statusList, setStatusList] = useState([]);
    const [selectedAllocationData, setSelectedAllocationData] = useState({
        email: "",
        allocationTypeObj: "",
        startDate: "",
        endDate: "",
        allocationStatus: "",
        comment: "",
        engagementCode: "",
        isTravelRequired: "",
        status: "",
        addedBy: "",
        engagementId: "",
        clearanceStatus: "",
        sfAllocationId: "",
        roleConsultant: "",
        startTime: null,
        endTime: null,
        isRecurring: "",
        engagementTypeId: ""
    });

    const today = new Date();

    const initialSearchFiltersState = {
        account: "",
        allocationType: '',
        consultant: "",
        clearanceStatus: "",
        startDate: new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()),
        endDate: new Date(today.getFullYear(), today.getMonth() + 2, today.getDate())
    };

    const [searchFilters, setSearchFilters] = useState(initialSearchFiltersState);
    const [isResetPageIndex, setIsResetPageIndex] = useState(false);
    const [tablePageSize, setTablePageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);
    const [filterText, setFilterText] = useState("");
    const [sortBy, setSortBy] = useState(null);

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessageType, setAlertMessageType] = useState("error");
    const [alertMessage, setAlertMessage] = useState("");

    const [checkboxStateBU, setCheckboxStateBU] = useState([]);

    const allocationReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.UPDATE_ALLOCATION_STATUS:
                return {
                    ...state, allocationsPopupDetails: action.allocationsPopupDetails, title: "Update Allocation Status", isAllocationStatusEdit: true
                }
            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails, dialogType, allocationsPopupDetails, title, isAllocationStatusEdit }, dispatchForm] = useReducer(allocationReducer, { confirmDialogDetails: { open: false }, dialogType: '', allocationsPopupDetails: {}, title: '', isAllocationStatusEdit: false });

    const handleDialogBoxAction = async (isConfirm) => {
    };

    const getAllocationData = useCallback(async (limit, offset, searchString, sortByObj) => {
        if (validateFormFields(searchFilters)) {
            const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocations;

            let filterBUList = checkboxStateBU.filter(checkbox => (
                checkbox.isChecked
              )).map(trueCheckbox => (
                trueCheckbox.name
              ));

            let body = {
                type: isInternalEngagement ? FormConstants.Types.Internal : FormConstants.Types.Customer,
                account: searchFilters.account && searchFilters.account.customerName,
                allocationType: searchFilters.allocationType && searchFilters.allocationType.allocationTypeName,
                consultant: searchFilters.consultant,
                clearanceStatus: searchFilters.clearanceStatus,
                startDate: searchFilters.startDate ? formatDateWithDashes(searchFilters.startDate) : "",
                endDate: searchFilters.endDate ? formatDateWithDashes(searchFilters.endDate) : "",
                businessUnits: filterBUList,

                limit: limit ?? tablePageSize,
                offset: offset ?? DEFAULT_TABLE_OFFSET,

                searchString: searchString ?? filterText,
                searchColumns: ["customerName", "opportunityName", "engagementType", "engagementCode", "allocationType", "engagementNature", 
                    "country", "startDate", "endDate", "email", "engagementStatus", "clearanceStatus", "opportunityOwner", "comment"],

                sortColumn: sortByObj ? ALLOCATION_TABLE_COLUMN_FIELD_MAP[sortByObj.id] : 
                    (sortBy ? ALLOCATION_TABLE_COLUMN_FIELD_MAP[sortBy.id] : null),
                sortOrder: sortByObj ? (sortByObj.desc ? SORT_ORDER.DESC : SORT_ORDER.ASC) : 
                    (sortBy ? (sortBy.desc ? SORT_ORDER.DESC : SORT_ORDER.ASC) : null)
            }

            handleRequest(endPointUrl, 'POST', body, (data) => {
                let allocations = [];
                let allocation;

                data.forEach(item => {
                    allocation = {
                        customerName: item.engagement.customerName,
                        opportunityName: item.engagement.opportunityName,
                        engagementType: item.engagement.engagementTypeName,
                        allocationTypeName: getAllocationTypeNameById(item.allocationType),
                        allocationType: item.allocationType,
                        engagementCode: item.engagement.engagementCode,
                        email: item.email,
                        role: item.consultantRole,
                        opportunityOwner: item.engagement.opportunityOwner,
                        comment: item.comment,
                        engagementLocation: item.engagement.engagementNature,
                        country: item.engagement.country,
                        engagementStatus: item.engagement.engagementStatus,
                        action: "",
                        engagementId: item.engagement.engagementId,
                        id: item.id,
                        allocationTypeObj: allocationTypes.find((type) => type.id === item.allocationType),
                        clearanceStatus: item.clearanceStatus,
                        isTravelRequired: item.isTravelRequired === "Yes" ? "Yes" : "No",
                        startDate: item.startDate && getDateObjectFromString(getDateFromString(item.startDate)),
                        endDate: item.endDate && getDateObjectFromString(getDateFromString(item.endDate)),
                        startTime: item.startTime && getDateObjectFromTimeString(item.startTime),
                        endTime: item.endTime && getDateObjectFromTimeString(item.endTime),
                        timeZoneObj: item.timeZone && getTimeZoneObj(item.timeZone),
                        isRecurring: item.isRecurring === "Yes" ? "Yes" : "No",

                        startDateDisp: getDateFromString(item.startDate),
                        endDateDisp: getDateFromString(item.endDate)
                    };

                    allocations.push(allocation);
                })

                setData(allocations);
            }, () => {
                setMessageBar("An error occurred in searching", true, "error");
                setData([]);
            }, setIsDataLoading);
        } else {
            setMessageBar("There are errors in the search fields.", true, "error");
        }
    }, [isInternalEngagement, tablePageSize, filterText, checkboxStateBU, sortBy, searchFilters]);

    const validateFormFields = (searchFilters) => {
        let isValid = true;

        if (isNaN(searchFilters.startDate) || isNaN(searchFilters.endDate)) {
            isValid = false;
        }

        return isValid;
    }

    const getAllocationTypeNameById = (id) => {
        return (allocationTypes && allocationTypes.find(type => (type.id === id))) ? allocationTypes.find(type => (type.id === id)).allocationTypeName : id;
    };

    const getBusinessUnits = useCallback(async () => {
        let buCheckBoxList = [];
        const endPointUrl = AppConfig.baseUrl + AppConfig.getBusinessUnitsList;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                buCheckBoxList.push({ 
                    id: item.id, 
                    label: item.isActive ? item.name : `${item.name} (Deprecated)`, 
                    name: item.name, 
                    isChecked: false })
            });

            setCheckboxStateBU(buCheckBoxList);
        })
    });

    useEffect(() => {
        getBusinessUnits();
    }, []);

    const getStatusList = (clearanceStatus) => {
        switch (clearanceStatus) {
            case AllocationStatus.TENTATIVE:
                return [
                    AllocationStatus.READY_FOR_CLEARANCE,
                    AllocationStatus.CLEARANCE_IN_PROGRESS,
                    AllocationStatus.CONFIRMED,
                    AllocationStatus.CONFIRMED_VISA_PENDING,
                    AllocationStatus.ALLOCATION_CANCELLED
                ]
            case AllocationStatus.READY_FOR_CLEARANCE:
                return [
                    AllocationStatus.CLEARANCE_IN_PROGRESS,
                    AllocationStatus.CONFIRMED,
                    AllocationStatus.CONFIRMED_VISA_PENDING,
                    AllocationStatus.ALLOCATION_CANCELLED
                ]
            case AllocationStatus.CLEARANCE_IN_PROGRESS:
                return [
                    AllocationStatus.ACCEPTED_BY_CONSULTANT,
                    AllocationStatus.REJECTED_BY_CONSULTANT,
                    AllocationStatus.ALLOCATION_CANCELLED,
                ]
            case AllocationStatus.ACCEPTED_BY_CONSULTANT:
                return [
                    AllocationStatus.CONFIRMED,
                    AllocationStatus.CONFIRMED_VISA_PENDING,
                    AllocationStatus.REJECTED_BY_CONSULTANT,
                    AllocationStatus.ALLOCATION_CANCELLED,
                ]
            case AllocationStatus.CONFIRMED:
                return [
                    AllocationStatus.REJECTED_BY_CONSULTANT,
                    AllocationStatus.REJECTED_VISA_ISSUES,
                    AllocationStatus.REJECTED_OTHER,
                    AllocationStatus.ALLOCATION_CANCELLED,
                ]
            case AllocationStatus.CONFIRMED_VISA_PENDING:
                return [
                    AllocationStatus.CONFIRMED,
                    AllocationStatus.REJECTED_VISA_ISSUES,
                    AllocationStatus.REJECTED_BY_CONSULTANT,
                    AllocationStatus.ALLOCATION_CANCELLED,
                ]
            default:
                return [
                    AllocationStatus.CLEARANCE_IN_PROGRESS,
                    AllocationStatus.CONFIRMED,
                    AllocationStatus.CONFIRMED_VISA_PENDING,
                    AllocationStatus.ALLOCATION_CANCELLED
                ]
        }
    };

    const handleAllocationData = (property, value) => {
        setSelectedAllocationData({
            ...selectedAllocationData,
            [property]: value
        })
    };

    const openUpdateAllocationStatusPopup = (data) => {
        dispatchForm({
            type: ACTIONS.UPDATE_ALLOCATION_STATUS,
            allocationsPopupDetails: {
                open: true,
            },
        })

        let currentClearanceStatus = data.clearanceStatus;

        setPrevClearanceStatus(currentClearanceStatus);
        setSelectedAllocationData({
            ...data,
            clearanceStatus: getStatusList(currentClearanceStatus)[0]
        });
        setStatusList(getStatusList(currentClearanceStatus));
    };


    const handleUpdateAllocationsSubmission = (isConfirm, isAllocationStatusEdit, email) => {
        if (isConfirm) {
            let msg = "";

            if (!isAllocationFormValid().isValid) {
                msg = isAllocationFormValid().msg;
            } else {
                dispatchForm({
                    type: ACTIONS.UPDATE_ALLOCATION_STATUS,
                    allocationsPopupDetails: {
                    }
                });

                updateAllocation();
            }

            if (msg) {
                setMessageBar(msg, true, 'error');
            }
        } else {
            dispatchForm({
                type: ACTIONS.UPDATE_ALLOCATION_STATUS,
                allocationsPopupDetails: {
                }
            });
        }
    };

    const isAllocationFormValid = () => {
        let isValid = true;
        let msg = "";

        const requiredFields = [
            "email",
            "allocationType",
            "startDate",
            "endDate",
            "isTravelRequired"
        ];

        if (selectedAllocationData.isRecurring === "Yes") {
            requiredFields.push("startTime", "endTime");
        }

        for (let field of requiredFields) {
            if (selectedAllocationData[field] === "" || selectedAllocationData[field] === null) {
                isValid = false;
                msg = "You need to fill the required fields";
                break;
            }
        };

        if (isValid) {
            // New allocation related data
            let engStartDate = selectedAllocationData.startDate;
            let engEndDate = selectedAllocationData.endDate;

            if ((engStartDate < data.minStartDate || engStartDate > data.startDate || engEndDate < data.endDate || engEndDate > data.maxEndDate)) {

                // if (!isTeamAllocPage && (selectedAllocationData.startDate < data.minStartDate || selectedAllocationData.startDate > data.startDate || selectedAllocationData.endDate < data.endDate || selectedAllocationData.endDate > data.maxEndDate)) {
                isValid = false;
                msg = "Start Date / End Date is not within the range";
            } else if (selectedAllocationData.isRecurring === "Yes" && (selectedAllocationData.startTime >= selectedAllocationData.endTime)) {
                isValid = false;
                msg = "End Time should be higher than Start Time"
            }
        }

        return { isValid: isValid, msg: msg };
    };

    const updateAllocation = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.updateAllocation.replace("$id", selectedAllocationData.id);
        let status = "Active";

        if ([AllocationStatus.ALLOCATION_CANCELLED, AllocationStatus.REJECTED_BY_CONSULTANT, AllocationStatus.REJECTED_VISA_ISSUES, AllocationStatus.REJECTED_OTHER].includes(selectedAllocationData.clearanceStatus)) {
            status = "Inactive";
        }

        let countryItem = countries.find(countryItem => countryItem[1] === selectedAllocationData.country);

        let allocationData = {
            id: selectedAllocationData.id,
            clearanceStatus: selectedAllocationData.clearanceStatus,
            comment: selectedAllocationData.comment,
            status: status,

            countryCode: countryItem[0],
            prevClearanceStatus: prevClearanceStatus
        };

        handleRequest(endPointUrl, 'PATCH', allocationData, (resData) => {
            setMessageBar("Allocation updated successfully", true, 'success');
            let updatedData = [...data];
            let selectedItem = updatedData.find(allocation => allocation.id === selectedAllocationData.id);

            if (selectedItem) {
                selectedItem.clearanceStatus = selectedAllocationData.clearanceStatus;
                selectedItem.comment = selectedAllocationData.comment;
                setData(updatedData);
            }
        }, (customErrMsg) => {
            setMessageBar(customErrMsg ? customErrMsg : "Error occurred in allocation update", true, 'error');
        });
    }, [selectedAllocationData, allocationTypes]);

    const updateAllocationComment = useCallback(async (id, comment) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.updateAllocation.replace("$id", id);

        let originalData = [...data];
        let originalComment;

        if (originalData.find(allocation => allocation.id == id)) {
            originalComment = originalData.find(allocation => allocation.id == id).comment;

            if (originalComment !== comment) {
                handleRequest(endPointUrl, 'PATCH', {
                    comment: comment
                }, (resData) => {
                    originalData.find(allocation => allocation.id == id).comment = comment;
                    setData(originalData);

                    setMessageBar('Comment updated successfully', true, 'success');
                }, (customErrMsg) => {
                    setMessageBar(customErrMsg ? customErrMsg : 'Error occurred in Comment update', true, 'error');
                });
            }
        }
    }, [data]);

    const fetchDataOnSearch = () => {
        setIsResetPageIndex(prev => !prev);
        getAllocationData();
    }

    const fetchData = (pageSize, pageIndex, filterText, sortBy) => {
        let sortByObj = sortBy instanceof Array ? (sortBy.length > 0 ? sortBy[0] : null) : sortBy;
        getAllocationData(pageSize, pageSize * pageIndex, filterText, sortByObj);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");

        // setMessageBar("", false);
    };

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    return (
        <React.Fragment>
            <Portal>
                <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                    <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Portal>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        {isInternalEngagement ? "Internal Engagement Allocations" : "Customer Engagement Allocations"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AllocationFilterBySearch isInternalEngagement={isInternalEngagement} fetchDataOnSearch={fetchDataOnSearch}
                        searchFilters={searchFilters} setSearchFilters={setSearchFilters} initialSearchFiltersState={initialSearchFiltersState} />
                </Grid>
                <Grid item xs={12}>
                    <FilterByCheckBoxes formGroupLabel="Filter by Business Unit" checkBoxes={checkboxStateBU} setCheckboxesState={setCheckboxStateBU} fetchData={fetchDataOnSearch} />
                </Grid>
                <Grid item xs={12}>
                    <AllocationsTable data={data} isLoading={isDataLoading} isInternalEngagement={isInternalEngagement} dispatchForm={dispatchForm}
                        openUpdateAllocationStatusPopup={openUpdateAllocationStatusPopup} updateComment={updateAllocationComment}
                        setTablePageSize={setTablePageSize} fetchData={fetchData} isResetPageIndex={isResetPageIndex} setFilterText={setFilterText} setSortBy={setSortBy} />

                </Grid>
            </Grid>

            <AddNewAllocationPopup title={title} data={selectedAllocationData} open={Boolean(allocationsPopupDetails && Object.values(allocationsPopupDetails).length) && allocationsPopupDetails.open} setAllocationData={handleAllocationData} handleFormAction={handleUpdateAllocationsSubmission} isAllocationStatusEdit={isAllocationStatusEdit} statusList={statusList} isHourlyBasis={selectedAllocationData.isRecurring === "Yes"} isTravelRequiredAvail={true} isAllocTypeSelect={false} isAddOverheadEnabled={isPaidEngagement(selectedAllocationData.engagementTypeId)} isInternalEngagement={isInternalEngagement} />
            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={DIALOG_TYPES.YES_NO_DIALOG} />
        </React.Fragment>
    );
};

export default EngagementAllocations;