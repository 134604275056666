import { useEffect, useState, useCallback, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Avatar from "@material-ui/core/Avatar";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { PAGES, APP_NAME, APP_DESCRIPTION } from "../Config";
import {
  setIdToken,
  setUserName,
  getUserName,
  setUserRoles,
  setUserPrivileges,
  setRefreshTokenFunction,
  setSessionClearFunction,
  setAccessToken,
} from "./utils/oauth";

import DrawerMenuVariant from "./DrawerMenuVariant";
import PersonIcon from "@material-ui/icons/Person";
import CircularProgressIndeterminate from "./CircularProgressIndeterminate";

import { useAuthContext } from "@asgardeo/auth-react";

import logo from "../images/logo.png";
import useStyles from "./MainStyle";
import { experimentalStyled } from "@mui/material";

import NotFound from "./NotFound";
import EngagementNew from "./engagements/EngagementNew";
import Dashboard from "./dashboard/Dashboard";
import TeamAllocations from "./teamAllocations/TeamAllocations";
import EngagementEdit from "./engagements/EngagementEdit";
import Allocations from "./allocations/Allocations";
import Settings from "./settings/Settings";
import Reports from "./reports/Reports";
import { OrgStructureProvider } from "./OrgStructureContext";
import { DataProvider } from "./DataContext";
import UserContext from "./UserContext";
import { AppConfig, Privileges } from "../Config";
import useHttp from "./utils/http";
import { isAccessGranted } from "./utils/utils";
import BackdropProgress from "./BackdropProgress";
import { QUERY_PARAMS_REGEX } from "./utils/AppConstants";

import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./Alert";
import IdleTimer from "./IdleTimer";

import { useHistory } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";

import PRODUCT_LOGOS from "../images/allocation-app-login-logos.png";
import BACKGROUND_IMAGE from "../images/allocation-app-login-background.png";

let theme = createTheme({
  palette: {
    primary: {
      light: "#465867",
      main: "#212a32",
      dark: "#465867",
    },
    secondary: {
      main: "#ff7300",
    },
    error: {
      main: "#e84118",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
      root: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
      containedSecondary: {
        color: "#fff",
      },
      outlinedSizeSmall: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 7,
        paddingLeft: 7,
      },
      containedSizeSmall: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 7,
        paddingLeft: 7,
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        maxWidth: 300,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
        overflow: "visible",
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: "#465867",
        color: "#fff",
      },
    },
    MuiGrid: {
      item: {
        paddingRight: 1,
      },
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          borderBottom: "0.5px solid #000",
          transition:
            "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        paddingLeft: 8,
      },
      content: {
        width: "100%",
        margin: "8px 0px",
        "&$expanded": {
          margin: "14px 0px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "0px 0px 0px",
      },
    },
    MuiAccordion: {
      root: {
        border: "2px solid transparent",
        "&$expanded": {
          border: "2px solid gray",
          transition: "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "16px 24px 0px 24px",
      },
    },
    MuiCollapse: {
      container: {
        width: "100%",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        },
      },
    },
    MuiFab: {
      secondary: {
        color: "#fff",
        fontWeight: 500,
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: "0px !important",
      },
    },
  },
};

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "auto",
  width: "100%",
}));

const Main = (props) => {
  const { handleRequest } = useHttp();
  const history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hoverActive, setHoverActive] = useState(true);
  const [page, setPage] = useState(null);

  const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("error");

  const handleDrawerToggle = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setHoverActive(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHoverActive(false);
    setTimeout(() => {
      setHoverActive(true);
    }, 500);
  };

  const handleDrawerHoverOver = () => {
    if (hoverActive) {
      setOpen(true);
    }
  };

  const handleDrawerHoverOut = () => {
    if (hoverActive) {
      setOpen(false);
    }
  };

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  const {
    state,
    signIn,
    signOut,
    getBasicUserInfo,
    getIDToken,
    getDecodedIDToken,
    refreshAccessToken,
    getAccessToken,
    revokeAccessToken,
  } = useAuthContext();

  const [loadApp, setLoadApp] = useState(false);
  const [authenticateState, setAuthenticateState] = useState(null);
  const [isUserPrivileged, setIsUserPrivileged] = useState(false);
  const [isAppInitializing, setIsAppInitializing] = useState(true);

  const setIsInitLogin = (value) => {
    sessionStorage.setItem("isInitLogin", value);
  };

  const getIsInitLogin = () => {
    if (sessionStorage.getItem("isInitLogin") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const setIsLoggedOut = (value) => {
    sessionStorage.setItem("isLoggedOut", value);

    setLoggedOutState(value === "true");
  };

  const getIsLoggedOut = () => {
    if (sessionStorage.getItem("isLoggedOut") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const setIsSessionTimeOut = (value) => {
    sessionStorage.setItem("isSessionTimeOut", value);
  };

  const getIsSessionTimeOut = () => {
    if (sessionStorage.getItem("isSessionTimeOut") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const [loggedOutState, setLoggedOutState] = useState(getIsLoggedOut());

  const setPathNameToRedirect = () => {
    if (
      !sessionStorage.getItem("pathNameToRedirect") &&
      history &&
      history.location &&
      history.location.pathname
    ) {
      if (validatePathName(history.location.pathname)) {
        sessionStorage.setItem("pathNameToRedirect", history.location.pathname);
      }
    }
  };

  const validatePathName = (pathname) => {
    let isValid = false;

    if (pathname !== "/") {
      if (pathname.split("/").length > 1) {
        let queryParams = pathname.split("/").pop();

        if (queryParams.length < 20 && QUERY_PARAMS_REGEX.test(queryParams)) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
    }

    return isValid;
  };

  const getPathNameToRedirect = () => {
    return sessionStorage.getItem("pathNameToRedirect");
  };

  const redirectToPathName = () => {
    const pathNameToRedirect = getPathNameToRedirect();

    if (pathNameToRedirect != "null" && pathNameToRedirect) {
      sessionStorage.removeItem("pathNameToRedirect");
      history.push(pathNameToRedirect);
    }
  };

  const initUserPrivileges = useCallback(async (callbackFn) => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getUserPrivileges;

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (privileges) => {
        let userPrivileges = [];

        privileges.forEach((privilege) => {
          userPrivileges.push(privilege.id);
        });

        setUserPrivileges(userPrivileges);

        setIsUserPrivileged(userPrivileges.length > 0);

        if (userPrivileges.length === 0) {
          setMessageBar(
            "You are not privileged to view this app",
            true,
            "error"
          );
        } else if (callbackFn) {
          callbackFn();
        }
      },
      () => {
        setMessageBar(
          "An error occurred in initializing the app! Try reloading the page. Please contact the Internal Apps Team if this issue continues.",
          true,
          "error"
        );
      },
      setIsAppInitializing
    );
  }, []);

  const handleLogin = () => {
    setIsInitLogin("true");
    setIsSessionTimeOut("false");
    setIsLoggedOut("false");

    signIn().catch((e) => {
      console.error(e);
    });
  };

  const handleLogout = async () => {
    setIsInitLogin("false");
    setIsLoggedOut("true");
    setIsSessionTimeOut("false");
    revokeAccessToken().then(signOut());
  };

  useEffect(() => {
    if (!getIsLoggedOut() && !(state?.isLoading || state?.isAuthenticated)) {
      handleLogin();
    }
  }, [state.isLoading, state.isAuthenticated]);

  const handleTokenRefresh = async () => {
    return refreshAccessToken()
      .then(async (e) => {
        setAccessToken(await getAccessToken());
      })
      .catch((err) => {
        if (err) {
          throw err;
        }
      });
  };

  useEffect(() => {
    setPathNameToRedirect();
  }, []);

  useEffect(() => {
    if (state && state.isAuthenticated) {
      setRefreshTokenFunction(handleTokenRefresh);
      setSessionClearFunction(sessionClearFn);

      const getUserData = async (callback) => {
        const basicUserInfo = await getBasicUserInfo();
        const accessToken = await getAccessToken();
        const idToken = await getIDToken();
        const decodedIDToken = await getDecodedIDToken();

        const authState = {
          authenticateResponse: basicUserInfo,
          idToken: idToken.split("."),
          decodedIdTokenHeader: JSON.parse(atob(idToken.split(".")[0])),
          decodedIDTokenPayload: decodedIDToken,
        };

        setIdToken(idToken);
        setAccessToken(accessToken);

        if (accessToken) {
          setLoadApp(true);
          callback?.();
        }

        if (basicUserInfo && basicUserInfo.email) {
          setUserName(basicUserInfo.email);
        }
        if (basicUserInfo && basicUserInfo.groups) {
          setUserRoles(basicUserInfo.groups);
        }

        setAuthenticateState(authState);
      };

      getUserData(() => {
        initUserPrivileges(redirectToPathName());
      });
    }
  }, [state.isAuthenticated, state.isLoading]);

  const setMessageBar = (message, isOpen, type) => {
    setAlertMessage(message);
    setIsMessageBarOpen(isOpen);
    setAlertMessageType(type);
  };

  const handleMessageBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsMessageBarOpen(false);
    setAlertMessage("");
  };

  const sessionClearFn = () => {
    setLoadApp(false);
    setIsInitLogin("false");
    setIsSessionTimeOut("true");
    setIsLoggedOut("true");

    revokeAccessToken().catch((e) => {
      console.error(e);
    });
    history.push("");
  };

  return (
    <>
      <Portal>
        <Snackbar
          open={isMessageBarOpen}
          autoHideDuration={6000}
          onClose={handleMessageBarClose}
        >
          <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Portal>

      {state.isAuthenticated && loadApp ? (
        <UserContext.Provider
          value={{
            userName: getUserName(),
          }}
        >
          <ThemeProvider theme={theme}>
            {/* <div className={classes.root}> */}
            <MainLayoutRoot>
              <CssBaseline />
              <AppBar position="fixed" onMouseOver={handleDrawerHoverOut}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    className={classes.menuButton}
                  >
                    {open && !hoverActive ? <ChevronLeftIcon /> : <MenuIcon />}
                  </IconButton>
                  <span className={classes.logo}>
                    <img src={logo} alt="Logo" style={{ width: "100%" }} />
                  </span>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" className={classes.title} noWrap>
                        Allocations App
                      </Typography>
                    </Grid>
                    <Grid item></Grid>
                    <Grid item xs={5}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <ProfileMenu
                          userName={getUserName()}
                          handleLogout={handleLogout}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div onMouseOver={handleDrawerHoverOver}>
                <DrawerMenuVariant open={open} page={page} />
              </div>

              <BackdropProgress open={isAppInitializing} />

              {isUserPrivileged && (
                <OrgStructureProvider>
                  <DataProvider>
                    <main
                      className={
                        open
                          ? classes.contentDrawerOpen
                          : classes.contentDrawerClose
                      }
                      onMouseOver={handleDrawerHoverOut}
                    >
                      <div className={classes.toolbar} />

                      <Switch>
                        {isAccessGranted([Privileges.DASHBOARD_PAGE]) && (
                          <Route
                            exact
                            path={PAGES.DASHBOARD}
                            render={() => {
                              return <Dashboard />;
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE,
                          Privileges.CUSTOMER_ENGAGEMENTS_PAGE,
                          Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE,
                        ]) && (
                          <Route
                            exact
                            path={PAGES.NEW_ENGAGEMENT}
                            render={() => {
                              return (
                                <EngagementNew key={"customer-engagement"} />
                              );
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.CUSTOMER_ENGAGEMENT_EDIT,
                        ]) && (
                          <Route
                            exact
                            path={`${PAGES.EDIT_ENGAGEMENT}/:id`}
                            render={(props) => {
                              return <EngagementEdit {...props} />;
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT,
                          Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT,
                        ]) && (
                          <Route
                            exact
                            path="/allocations/:id"
                            component={Allocations}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.TEAM_ALLOCATIONS_PAGE,
                        ]) && (
                          <Route
                            exact
                            path={PAGES.TEAM_ALLOCATIONS}
                            render={() => {
                              return <TeamAllocations />;
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.CONSULTANT_ALLOCATIONS_REPORT,
                        ]) && (
                          <Route
                            exact
                            path={PAGES.REPORTS}
                            render={() => {
                              return <Reports />;
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.ROLE_SETTINGS_PAGE,
                          Privileges.PARTNER_RESOURCES_MGT_PAGE,
                        ]) && (
                          <Route
                            exact
                            path={PAGES.SETTINGS}
                            render={() => {
                              return <Settings />;
                            }}
                          />
                        )}
                        {isAccessGranted([
                          Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE,
                          Privileges.MARKETING_EVENT_CREATE_PAGE,
                          Privileges.INTERNAL_ENGAGEMENTS_PAGE,
                          Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE,
                        ]) && (
                          <Route
                            exact
                            path={PAGES.INTERNAL}
                            render={() => {
                              return (
                                <EngagementNew
                                  key={"internal-engagement"}
                                  isInternal
                                />
                              );
                            }}
                          />
                        )}

                        {isAccessGranted([
                          Privileges.INTERNAL_ENGAGEMENT_EDIT,
                        ]) && (
                          <Route
                            exact
                            path={`${PAGES.EDIT_INTERNAL}/:id`}
                            render={(props) => {
                              return <EngagementEdit {...props} isInternal />;
                            }}
                          />
                        )}
                        <Redirect exact from="/" to={PAGES.DASHBOARD} />

                        <Route
                          render={() => {
                            return <NotFound />;
                          }}
                        />
                      </Switch>
                      <IdleTimer sessionClearFn={sessionClearFn} />
                    </main>
                  </DataProvider>
                </OrgStructureProvider>
              )}
              {/* </div> */}
            </MainLayoutRoot>
          </ThemeProvider>
        </UserContext.Provider>
      ) : getIsLoggedOut() ? (
        // <Box sx={{
        //   backgroundColor: 'background.default',
        //   display: 'flex',
        //   flexDirection: 'column',
        //   height: '100%',
        //   justifyContent: 'center'
        // }}
        // >
        //   <Container maxWidth="md">
        //     <Card>
        //       <CardContent>
        //         <Box
        //           sx={{
        //             p: 2
        //           }}
        //         >
        //           <Grid container
        //             direction="column"
        //             justifyContent="center"
        //             alignItems="center"
        //             spacing={2}>
        //             <Grid item xs={12}>
        //               <img alt="logo" width="150" height="auto" src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"></img>
        //             </Grid>
        //             <Grid
        //               item
        //               xs={12}
        //               sx={{ pb: 2, }}
        //             >
        //               <Typography variant="h2">
        //                 {APP_NAME}
        //               </Typography>
        //             </Grid>
        //             {(!(getIsInitLogin() || state.isLoading || state.isAuthenticated) || getIsSessionTimeOut()) ? (
        //               <Grid item xs={12}>
        //                 <Button
        //                   id="login"
        //                   onClick={() => {
        //                     handleLogin();
        //                   }}
        //                   variant="contained"
        //                   color="secondary"
        //                   disabled={(getIsInitLogin() || state.isLoading || state.isAuthenticated) && !getIsSessionTimeOut()}
        //                 >
        //                   Log In

        //                 </Button>
        //               </Grid>
        //             ) :
        //               (<Grid item xs={12}>
        //                 <Typography variant="caption">
        //                   <CircularProgressIndeterminate isLoading={true} />
        //                 </Typography>
        //               </Grid>)}
        //           </Grid>
        //         </Box>
        //       </CardContent>
        //       <Divider />
        //     </Card>
        //   </Container>
        // </Box>
        <>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                backgroundImage: `url(${BACKGROUND_IMAGE})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Container fixed maxWidth="xs">
                <Card
                  elevation={24}
                  sx={{
                    borderRadius: 3,
                    pt: 3,
                    mx: 2,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        px: 3,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        p={1}
                      >
                        <Grid item xs={12}>
                          <img
                            alt="logo"
                            width="130"
                            height="auto"
                            src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                          ></img>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "2em" }}
                            align="center"
                            // variant="h5"
                          >
                            {APP_NAME}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 2 }}>
                          <Typography align="center" sx={{ fontSize: "1em" }}>
                            {APP_DESCRIPTION}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            id="login"
                            onClick={() => {
                              handleLogin();
                            }}
                            variant="contained"
                            color="secondary"
                            loading={
                              (getIsInitLogin() ||
                                state.isLoading ||
                                state.isAuthenticated) &&
                              !getIsSessionTimeOut()
                            }
                            loadingPosition="center"
                            sx={{ color: "#ffffff" }}
                          >
                            Log In
                          </LoadingButton>
                        </Grid>
                        {getIsInitLogin() && (
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              Redirecting to Asgardeo...
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} mt={10}>
                          <Stack direction="column" spacing={2}>
                            <Typography align="center">Powered By</Typography>

                            <Stack direction="row" spacing={2}>
                              <img height={22} src={PRODUCT_LOGOS} />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                          <Typography
                            align="center"
                            color="text.secondary"
                            sx={{ fontSize: "0.8em" }}
                          >
                            {"© 2023 WSO2 LLC"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Container>
            </Box>
          </ThemeProvider>
        </>
      ) : (
        // <Box
        //   sx={{
        //     backgroundColor: "background.default",
        //     display: "flex",
        //     flexDirection: "column",
        //     height: "100%",
        //     justifyContent: "center",
        //   }}
        // >
        //   <Container maxWidth="md">
        //     <Card>
        //       <CardContent>
        //         <Box
        //           sx={{
        //             p: 2,
        //           }}
        //         >
        //           <Grid
        //             container
        //             direction="column"
        //             justifyContent="center"
        //             alignItems="center"
        //             spacing={2}
        //           >
        //             <Grid item xs={12}>
        //               <img
        //                 alt="logo"
        //                 width="150"
        //                 height="auto"
        //                 src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
        //               ></img>
        //             </Grid>
        //             <Grid item xs={12} sx={{ pb: 2 }}>
        //               <Typography variant="h2">{APP_NAME}</Typography>
        //             </Grid>

        //             <Grid item xs={12}>
        //               <Typography variant="caption">
        //                 <CircularProgressIndeterminate isLoading={true} />
        //               </Typography>
        //             </Grid>
        //           </Grid>
        //         </Box>
        //       </CardContent>
        //       <Divider />
        //     </Card>
        //   </Container>
        // </Box>
        <>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                backgroundImage: `url(${BACKGROUND_IMAGE})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Container fixed maxWidth="xs">
                <Card
                  elevation={24}
                  sx={{
                    borderRadius: 3,
                    pt: 3,
                    mx: 2,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        px: 3,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        p={1}
                      >
                        <Grid item xs={12}>
                          <img
                            alt="logo"
                            width="130"
                            height="auto"
                            src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                          ></img>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "2em" }}
                            align="center"
                            // variant="h5"
                          >
                            {APP_NAME}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 2 }}>
                          <Typography align="center" sx={{ fontSize: "1em" }}>
                            {APP_DESCRIPTION}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            id="login"
                            onClick={() => {
                              handleLogin();
                            }}
                            variant="contained"
                            color="secondary"
                            loading={true}
                            loadingPosition="center"
                            sx={{ color: "#ffffff" }}
                          >
                            Log In
                          </LoadingButton>
                        </Grid>
                        {getIsInitLogin() && (
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              Redirecting to Asgardeo...
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} mt={10}>
                          <Stack direction="column" spacing={2}>
                            <Typography align="center">Powered By</Typography>

                            <Stack direction="row" spacing={2}>
                              <img height={22} src={PRODUCT_LOGOS} />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                          <Typography
                            align="center"
                            color="text.secondary"
                            sx={{ fontSize: "0.8em" }}
                          >
                            {"© 2023 WSO2 LLC"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Container>
            </Box>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default Main;
