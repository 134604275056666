import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

import EngagementDataPanelItem from "./EngagementDataPanelItem";
import { formatDateWithDashes } from "../utils/utils";
import { fontSize } from "@mui/system";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,  
        boxShadow: '0px 3px 12px #00000017',  
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%'
    },
    page: {
        padding: 20,
        flexGrow: 1,
    },
}));

const EngagementDataPanel = ({ data, isInternalEngagement, isMarketingEvent }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Paper square className={classes.page}>
                <Grid container item xs={12} spacing={1} >
                    <Grid item xs={2}>
                        <EngagementDataPanelItem name={isMarketingEvent ? "Event Id" : "Engagement Id"} value={data.engagementId} />
                    </Grid>
                    <Grid item xs={2}>
                        <EngagementDataPanelItem name="Engagement Code" value={data.engagementCode} />
                    </Grid>
                    <Grid item xs={2}>
                        <EngagementDataPanelItem name="Account" value={data.customerName} />
                    </Grid>

                    <Grid item xs={3}>
                        <EngagementDataPanelItem name={isInternalEngagement ? (isMarketingEvent ? "Event Name" : "Name / Purpose") : "Opportunity Name"} value={data.opportunity} />
                    </Grid>

                    <Grid item xs={3}>
                        <EngagementDataPanelItem name="Product" value={data.productName} />
                    </Grid>

                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                        <EngagementDataPanelItem name="Start Date" value={data.startDate && formatDateWithDashes(data.startDate)} />
                    </Grid>

                    <Grid item xs={2}>
                        <EngagementDataPanelItem name="End Date" value={data.endDate && formatDateWithDashes(data.endDate)} />
                    </Grid>

                    <Grid item xs={2}>
                        <EngagementDataPanelItem name="Request Status" value={data.requestStatus} />
                    </Grid>

                    <Grid item xs={3}>
                        <EngagementDataPanelItem name="Engagement Status" value={data.engagementStatus} />
                    </Grid>

                    <Grid item xs={3}>
                        <EngagementDataPanelItem name="Location" value={data.engagementNature} />
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    )
}

export default EngagementDataPanel;