import { useIdleTimer } from 'react-idle-timer';
import { DIALOG_TYPES } from './utils/AppConstants';
import ConfirmationDialog from './ConfirmationDialog';
import { useState } from 'react';

const IdleTimer = ({ sessionClearFn }) => {
    const [showModal, setShowModal] = useState(false);
    const [confirmDialogDetails, setConfirmDialogDetails] = useState({
        open: true,
        id: 1,
        title: "You have been Idle",
        message: "Your session will get timed out. Do you need to stay ?"
    });

    const onPrompt = () => {
        setShowModal(true);
    }

    const onIdle = () => {
        setShowModal(false);
        sessionClearFn();
    }

    const {
        reset
    } = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: 1000 * 60 * 30, // 30 minutes
        promptTimeout: 1000 * 60 * 0.5, // 30 seconds
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    });

    const handleDialogBoxAction = (isConfirm) => {
        setShowModal(false);

        if (isConfirm) { // User chooses to stay
            reset();
        } else {
            sessionClearFn();
        }
    }

    return (
        <>
            <ConfirmationDialog data={confirmDialogDetails} open={showModal} handleSubmit={handleDialogBoxAction} dialogType={DIALOG_TYPES.CUSTOM} disableCloseOnClickOutside={true} confirmFalseBtnTxt="End Session" confirmTrueBtnTxt="Stay" />
        </>
    );
}

export default IdleTimer;