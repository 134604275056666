import { Fragment, useMemo, useState, useCallback, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Chip from '@mui/material/Chip';

import useHttp from '../utils/http';
import { AppConfig } from '../../Config';
import TableComponent from '../TableComponent';
import ConfirmationDialog from '../ConfirmationDialog';
import { DIALOG_TYPES, PARTNER_RESOURCES } from "../utils/AppConstants";
import AddPartnerPopup from "./AddPartnerPopup";
import { countries } from '../utils/AppData';

const ACTIONS = {
    ADD_NEW_PARTNER: 'ADD_NEW_PARTNER',
    UPDATE_PARTNER: 'UPDATE_PARTNER',
    DELETE_PARTNER: 'DELETE_PARTNER',
    SHOW_PAGE_INFO: 'SHOW_PAGE_INFO'
}

const PartnerResourcesMgt = ({ setMessageBar }) => {
    const { handleRequest, handleComponentEncodedRequest } = useHttp();

    const partnerReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.ADD_NEW_PARTNER:
                return {
                    ...state, partnerPopupDetails: action.partnerPopupDetails, title: "Add New Partner Resource", isEditMode: false
                }

            case ACTIONS.UPDATE_PARTNER:
                return {
                    ...state, partnerPopupDetails: action.partnerPopupDetails, title: "Update Partner Resource", isEditMode: true
                }

            case ACTIONS.DELETE_PARTNER:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.CONFIRM_DIALOG
                }

            case ACTIONS.SHOW_PAGE_INFO:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.INFO_DIALOG
                }

            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails, dialogType, partnerPopupDetails, title, isEditMode }, dispatchForm] = useReducer(partnerReducer, { confirmDialogDetails: { open: false }, dialogType: '', partnerPopupDetails: {}, title: '', isEditMode: false });


    const columns = useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action',
                collapse: true,
                disableSortBy: true,
                maxWidth: 70,
                style: { display: 'flex', alignItems: 'center' },
                Cell: ({ row }) => {
                    return (
                        <div>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" onClick={() => { onClickEditPartner(row.original) }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => { confirmDeletePartner(row.original) }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                Header: 'Country',
                accessor: 'countryName',
                collapse: true,
            },
            {
                Header: 'Partner',
                accessor: 'partner',
                collapse: true,
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
                collapse: true,
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                collapse: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                collapse: true,
                minWidth: 200,
            },
            {
                Header: 'Phone Number',
                accessor: 'mobileNumber',
                collapse: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { display: 'flex', alignItems: 'center' },
                collapse: true,
                Cell: ({ row }) => {
                    if (row) {
                        return (
                            <Chip size="small" label={row.original.status} style={{ backgroundColor: getStatusColor(row.original.status), marginLeft: 20, color: "white", alignItems: 'center', borderRadius: 5, height: 25, width: 90, alignItems: "center" }} />
                        )
                    }
                },
            }
        ], []
    );

    const [partners, setPartners] = useState([]);
    const [selectedPartnerData, setSelectedPartnerData] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);

    const getPartners = useCallback(async () => {
        let partnersList = [];
        let partner, country;

        const endPointUrl = AppConfig.baseUrl + AppConfig.getPartners;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                country = countries.find(countryData => countryData[1] === item.country);

                partner = {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: item.email,
                    mobileNumber: item.mobileNumber,
                    country: country,
                    countryName: item.country,
                    partner: item.partner,
                    status: item.status,
                }



                partnersList.push(partner);
            });

            setPartners(partnersList);
        }, () => {

        }, setIsDataLoading);
    }, []);

    const getStatusColor = (status) => {
        switch (status) {
            case "Active":
                return '#76BA99'; // green #a0db8e
            case "Left":
                return '#F8CB2E'; // orange #fd8585
            default:
                return;
        }
    };

    useEffect(() => {
        getPartners();
    }, []);

    const handlePartnerData = (property, value) => {
        setSelectedPartnerData({
            ...selectedPartnerData,
            [property]: value
        })
    };

    const confirmDeletePartner = (selectedPartnerData) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationCountForPartnerConsultant;
        const params = [
            `partner=${encodeURIComponent(selectedPartnerData.partner)}`, 
            `firstName=${encodeURIComponent(selectedPartnerData.firstName)}`, 
            `lastName=${encodeURIComponent(selectedPartnerData.lastName)}`
        ].join('&');
        const requestUrl = `${endPointUrl}?${params}`;

        handleComponentEncodedRequest(requestUrl, 'GET', null, (noOfPartnerConsultantAllocations) => {
            if (noOfPartnerConsultantAllocations > 0) {
                setMessageBar("Delete not allowed. " + "There are " + noOfPartnerConsultantAllocations +
                    " existing (Confirmed/Rejected) partner allocations for this consultant. " +
                    "You can change the status of this consultant to \"Left\".", true, 'error');
            } else {
                let confirmDialogDetails = PARTNER_RESOURCES.MESSAGES.DELETE_PARTNER;

                dispatchForm({
                    type: ACTIONS.DELETE_PARTNER,
                    confirmDialogDetails: {
                        open: true,
                        id: confirmDialogDetails.id,
                        title: confirmDialogDetails.title,
                        message: confirmDialogDetails.message,
                        data: {
                            id: selectedPartnerData.id
                        }
                    },
                });
            }
        }, () => {
            setMessageBar("Error while getting allocation count for partner consultant", true, 'error');
        });
    }

    const onClickAddBtn = () => {
        setIsSubmitBtnClicked(false);

        dispatchForm({
            type: ACTIONS.ADD_NEW_PARTNER,
            partnerPopupDetails: {
                open: true,
            },
        });

        setSelectedPartnerData({
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            country: "",
            partner: "",
            status: "",
        });
    }

    const onClickEditPartner = (data) => {
        setIsSubmitBtnClicked(false);

        dispatchForm({
            type: ACTIONS.UPDATE_PARTNER,
            partnerPopupDetails: {
                open: true,
            },
        });

        setSelectedPartnerData({
            ...selectedPartnerData,
            ...data
        });
    }

    const handleParnerSubmission = (isConfirm, isEditMode) => {
        if (isConfirm) {
            setIsSubmitBtnClicked(true);

            if (isValidForm(selectedPartnerData)) {
                dispatchForm({
                    type: ACTIONS.ADD_NEW_PARTNER,
                    partnerPopupDetails: {
                    },
                });

                if (isEditMode) {
                    updatePartner();
                } else {
                    addNewPartner();
                }
            }
        } else {
            dispatchForm({
                type: ACTIONS.ADD_NEW_PARTNER,
                partnerPopupDetails: {
                },
            });
        }
    }

    const handleDeletePartner = (isConfirm) => {
        if (isConfirm) {
            const endPointUrl = AppConfig.baseUrl + AppConfig.deletePartner.replace("$id", confirmDialogDetails.data.id);

            handleRequest(endPointUrl, 'DELETE', null, (data) => {
                getPartners();
                setMessageBar("Successfully deleted the partner consultant", true, 'success');
            }, () => {
                setMessageBar("Error while deleting the partner consultant", true, 'error');
            })
        }

        dispatchForm({
            type: ACTIONS.DELETE_PARTNER,
            confirmDialogDetails: {
            },
        });
    }

    const addNewPartner = () => {
        let endPointUrl = AppConfig.baseUrl + AppConfig.addNewPartner;
        let partnerData = {
            firstName: selectedPartnerData.firstName,
            lastName: selectedPartnerData.lastName,
            email: selectedPartnerData.email,
            mobileNumber: selectedPartnerData.mobileNumber,
            country: selectedPartnerData.country[1],
            partner: selectedPartnerData.partner,
            status: "Active",
        }

        handleRequest(endPointUrl, 'POST', partnerData, (data) => {
            getPartners();
            setMessageBar("Partner added successfully", true, 'success');
        }, (customErrMsg) => {
            setMessageBar(customErrMsg ? customErrMsg : `Error occurred adding partner consultant ${partnerData.partner} / ${partnerData.firstName} ${partnerData.lastName} to DB`, true, 'error');
        });
    }

    const updatePartner = () => {
        let endPointUrl = AppConfig.baseUrl + AppConfig.updatePartner.replace("$id", selectedPartnerData.id);
        let partnerData = {
            firstName: selectedPartnerData.firstName,
            lastName: selectedPartnerData.lastName,
            email: selectedPartnerData.email,
            mobileNumber: selectedPartnerData.mobileNumber,
            country: selectedPartnerData.country[1],
            partner: selectedPartnerData.partner,
            status: selectedPartnerData.status,
        }

        handleRequest(endPointUrl, 'PUT', partnerData, (data) => {
            getPartners();
            setMessageBar("Partner updated successfully", true, 'success');
        }, (customErrMsg) => {
            setMessageBar(customErrMsg ? customErrMsg : `Error occurred in updating partner consultant ${partnerData.partner} / ${partnerData.firstName} ${partnerData.lastName} in DB`, true, 'error');
        });
    };

    const isValidForm = (data) => {
        let isValid = true;

        const requiredFields = [
            "country",
            "partner",
            "firstName",
            "lastName",
        ];

        for (let field of requiredFields) {
            if (data[field] === "" || data[field] === null || data[field] === undefined) {
                isValid = false;
                setMessageBar("You need to fill the required fields", true, 'error');
                break;
            }
        };

        if (isValid) {
            return isValidEmail(data.email);
        }

        return isValid;
    }

    const isValidEmail = (email) => {
        if (!email || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            setMessageBar("Email is not valid", true, 'error');
            return false;
        }
    };

    const handleDialogBoxAction = (isConfirm) => {
        switch (confirmDialogDetails.id) {
            case 'deletePartner':
                return handleDeletePartner(isConfirm);
            case 'showInfo':
                return handleShowInfo();

            default:
                return;
        }
    };

    const showPageInfo = () => {
        let confirmDialogDetails = PARTNER_RESOURCES.MESSAGES.SHOW_PAGE_INFO;

        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const handleShowInfo = () => {
        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
            },
        });
    }

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        Partner Resources Management
                        {/* <Tooltip> */}
                        <IconButton aria-label="info" onClick={() => { showPageInfo() }}>
                            <InfoIcon color='secondary' />
                        </IconButton>
                        {/* </Tooltip> */}
                        <Button variant="contained" size="large" color="secondary" onClick={() => { onClickAddBtn() }} style={{ float: 'right', marginRight: 10 }}>
                            Add New
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableComponent
                    columns={columns}
                    data={partners}
                    getCellProps={cellInfo => ({
                        // style: {
                        //     backgroundColor: (cellInfo.column.id === 'status' ? getStatusColor(cellInfo.value) : '#fff'),
                        // },
                    })}
                    minRows={3}
                    showPagination={true}
                    pageSize={4}
                    style={{ height: '100%' }}
                    isLoading={isDataLoading}
                />

            </Grid>
            <AddPartnerPopup title={title} data={selectedPartnerData} open={Boolean(partnerPopupDetails && Object.values(partnerPopupDetails).length) && partnerPopupDetails.open} handlePartnerData={handlePartnerData} handleFormAction={handleParnerSubmission} isEditMode={isEditMode} isSubmitBtnClicked={isSubmitBtnClicked} />
            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={dialogType} />
        </Fragment>
    )
}

export default PartnerResourcesMgt;