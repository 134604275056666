import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { RequestStatus, EngagementStatus, FormConstants, PAGES } from '../../Config';
import { makeStyles } from "@material-ui/core/styles";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormControl from "@material-ui/core/FormControl";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import Chip from '@mui/material/Chip';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from 'react-router-dom';

import { ENGAGEMENTS, STATUS_COLORS } from '../utils/AppConstants';
import TableComponent from '../TableComponent';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';
import { getUserName } from "../utils/oauth";
import { render } from '@testing-library/react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    fontSize: 13,
    padding: 0,
    margin: 0,
    // width: 100
  },
  StatusChip: {
    padding: 2,
    borderRadius: 25,
    alignItems: 'center',
    display: 'block',
  },
  statusDot: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  }
}));

const ACTIONS = {
  UPDATE_REQUEST_STATUS: 'UPDATE_REQUEST_STATUS',
  UPDATE_ENGAGEMENT_STATUS: 'UPDATE_ENGAGEMENT_STATUS'
};

const EngagementTable = ({ data, isLoading, isInternalEngagement, dispatchForm, 
  setTablePageSize, fetchData, isResetPageIndex, setFilterText, setSortBy }) => {
  const classes = useStyles();

  const [engagementStatus, setEngagementStatus] = useState([]);

  const [skipPageReset, setSkipPageReset] = React.useState(false); // Not used for the moment

  const columns = React.useMemo(
    () => {
      let columns = [];

      if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_EDIT, Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.INTERNAL_ENGAGEMENT_EDIT] : [Privileges.CUSTOMER_ENGAGEMENT_EDIT, Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE])) {
        let privilegedActionCount = 0;

        if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_EDIT] : [Privileges.CUSTOMER_ENGAGEMENT_EDIT])) {
          privilegedActionCount++;
        }

        if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT])) {
          privilegedActionCount++;
        }

        if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_REQUEST_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE])) {
          privilegedActionCount = privilegedActionCount + 2;
        }
        columns.push(
          {
            Header: 'Action',
            accessor: 'action',
            collapse: true,
            minWidth: 45 * privilegedActionCount,
            style: { display: 'flex', alignItems: 'center' },
            // maxWidth: 100,
            disableSortBy: true,

            Cell: ({ row }) => {
              return (
                <div>
                  {isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_EDIT] : [Privileges.CUSTOMER_ENGAGEMENT_EDIT]) && (<Tooltip title="Edit Engagement">
                    <IconButton aria-label="edit" component={Link} to={isInternalEngagement ? `${PAGES.EDIT_INTERNAL}/${row.original.engagementId}` : `${PAGES.EDIT_ENGAGEMENT}/${row.original.engagementId}`}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>)}
                  {isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT]) && (<Tooltip title="Allocate Consultant">
                    <IconButton aria-label="allocate" component={Link} to={`/allocations/${row.original.engagementId}`}>
                      <PersonIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>)}
                  {isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_REQUEST_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE]) && (<Tooltip title="Complete">
                    <IconButton aria-label="complete" onClick={() => { updateRequestStatus(row.original.engagementId, "Completed") }}>
                      <CheckBoxOutlinedIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>)}
                  {isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_REQUEST_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_REQUEST_STATUS_UPDATE]) && (<Tooltip title="Reopen / In Progress">
                    <IconButton aria-label="reopen" onClick={() => { updateRequestStatus(row.original.engagementId, "In Progress") }}>
                      <UndoIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>)}
                </div>
              )
            }
          }
        )
      }

      columns.push(
        {
          Header: 'Engagement ID',
          accessor: 'engagementId',
          collapse: true,
        },
        {
          Header: 'Account',
          accessor: 'customerName',
          collapse: true,
          minWidth: 120,
        },
        {
          Header: 'Opportunity',
          accessor: 'opportunityName',
          collapse: true,
          minWidth: 130,
        },
        {
          Header: 'Engagement Code',
          accessor: 'engagementCode',
          collapse: true,
          minWidth: 120,
        },
        {
          Header: 'Allocation Type',
          accessor: 'allocationTypeName',
          collapse: true,
        },
        {
          Header: 'Engagement Location',
          accessor: 'engagementNature',
          collapse: true,
          maxWidth: 140,
        },
        {
          Header: 'Country',
          accessor: 'country',
          collapse: true,
          maxWidth: 140,
        },
        {
          Header: 'Start Date',
          accessor: 'startDate',
          collapse: true,
          // minWidth: 90,
          maxWidth: 90,
        },
        {
          Header: 'End Date',
          accessor: 'endDate',
          collapse: true,
          // minWidth: 90,
          maxWidth: 90,
        },
        // {
        //   Header: 'Business Unit',
        //   accessor: 'businessUnit',
        // },
      );

      if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENTS_PAGE_VIEW_CONSULTANTS_COLUMN] : [Privileges.CUSTOMER_ENGAGEMENTS_PAGE_VIEW_CONSULTANTS_COLUMN])) {
        columns.push(
          {
            Header: 'Consultants',
            accessor: 'consultants',
            Cell: ({ row }) => {
              if (row) {
                return (
                  row.original.consultants.map(line => <div>{line}</div>)
                )
              }
            },
            collapse: true,
            minWidth: 250,
            disableSortBy: true
          }
        )
      };

      columns.push(
        {
          Header: 'Request Status',
          accessor: 'requestStatus',
          minWidth: 100,
          style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
          Cell: ({ row }) => {
            if (row) {
              return (
                <Chip size="small" label={row.original.requestStatus} style={{ backgroundColor: getRequestStatusColor(row.original.requestStatus), color: "white", borderRadius: 5, height: 25, width: 90 }} />
              )
              // return row.original.requestStatus;
            }
          },
          collapse: true,
        }
      );

      columns.push(
        {
          Header: 'Engagement Status',
          accessor: 'engagementStatus',

          Cell: ({ row }) => {
            if (row) {
              if (isAccessGranted(isInternalEngagement ? [Privileges.INTERNAL_ENGAGEMENT_STATUS_UPDATE] : [Privileges.CUSTOMER_ENGAGEMENT_STATUS_UPDATE]) || row.original.username === getUserName()) {
                return (
                  <Select className={classes.formControl} defaultValue={row.original.engagementStatus}
                    // style={{ backgroundColor: getEngagementStatusColor(row.original.engagementStatus), color: "white", borderRadius:5, height: 25, width:100, padding:5 }} 
                    value={row.original.engagementStatus} onChange={(e) => { updateEngagementStatus(row.original, e) }}>

                    {engagementStatus.map(status => (
                      <MenuItem key={status.name} value={status.name}>
                        <span className={classes.statusDot} style={{ backgroundColor: getEngagementStatusColor(status.name) }} />
                        {status.name}</MenuItem>
                    ))}
                  </Select>

                )
              } else {
                return (
                  <Chip size="small" label={row.original.engagementStatus} style={{ backgroundColor: getEngagementStatusColor(row.original.engagementStatus), color: "white", borderRadius: 5, height: 25, width: 90, padding: 5 }} />
                )
                // row.original.engagementStatus              
              }
            }

            return {}

          },
          collapse: true,
          minWidth: 120,
          style: { display: 'flex', alignItems: 'center' },
        }
      );

      return columns;
    },
    [engagementStatus]
  );

  const getRequestStatusColor = (status) => {
    switch (status) {
      case RequestStatus.NEW:
        return STATUS_COLORS.CONFIRMED; // blue
      case RequestStatus.UPDATED:
        return STATUS_COLORS.PENDING; // orange
      case RequestStatus.IN_PROGRESS:
        return STATUS_COLORS.IN_PROGRESS; // yellow
      case RequestStatus.COMPLETED:
        return STATUS_COLORS.COMPLETED; // green
      case RequestStatus.CANCELLED:
        return STATUS_COLORS.CANCELLED; // gray
      default:
        return STATUS_COLORS.DEFAULT;//PINK
    }
  };

  const getEngagementStatusColor = (status1) => {
    let status = status1.trim()
    switch (status) {
      case EngagementStatus.PENDING:
        return STATUS_COLORS.PENDING; // orange
      case EngagementStatus.IN_PROGRESS:
        return STATUS_COLORS.IN_PROGRESS; // yellow
      case EngagementStatus.CANCELLED:
        return STATUS_COLORS.CANCELLED; // gray
      case EngagementStatus.COMPLETED:
        return STATUS_COLORS.COMPLETED; // green
      case EngagementStatus.CONFIRMED:
        return STATUS_COLORS.CONFIRMED; // red
      default:
        return STATUS_COLORS.DEFAULT;
    }
  };

  const updateEngagementStatus = (data, e) => {
    setSkipPageReset(true);

    let newStatus = e.target.value;
    let confirmDialogDetails = ENGAGEMENTS.ENGAGEMENTS_PAGE.MESSAGES.CONFIRM_ENGAGEMENT_STATUS_UPDATE;

    dispatchForm({
      type: ACTIONS.UPDATE_ENGAGEMENT_STATUS,
      confirmDialogDetails: {
        open: true,
        id: confirmDialogDetails.id,
        title: confirmDialogDetails.title,
        message: confirmDialogDetails.message.replace('$newStatus', newStatus),
        engagementId: data.engagementId,
        engagementStatus: newStatus,
        data: data
      },
    });
  }

  const updateRequestStatus = (id, newStatus) => {
    setSkipPageReset(true);

    if (newStatus === RequestStatus.COMPLETED || newStatus === RequestStatus.IN_PROGRESS) {
      let confirmDialogDetails = ENGAGEMENTS.ENGAGEMENTS_PAGE.MESSAGES.CONFIRM_REQUEST_STATUS_UPDATE;

      dispatchForm({
        type: ACTIONS.UPDATE_REQUEST_STATUS,
        confirmDialogDetails: {
          open: true,
          id: confirmDialogDetails.id,
          title: confirmDialogDetails.title,
          message: confirmDialogDetails.message.replace('$newStatus', newStatus),
          engagementId: id,
          status: newStatus
        },
      });
    }
  };

  useEffect(() => {
    setEngagementStatus([
      { name: EngagementStatus.CONFIRMED },
      { name: EngagementStatus.PENDING },
      { name: EngagementStatus.IN_PROGRESS },
      { name: EngagementStatus.COMPLETED },
      { name: EngagementStatus.CANCELLED }
    ]);
  }, []);

  // After data changes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  return (
    <TableComponent
      columns={columns}
      data={data ?? []}
      minRows={3}
      showPagination={true}
      pageSize={4}
      style={{ height: '100%' }}
      isLoading={isLoading}
      skipPageReset={skipPageReset}
      setTablePageSize={setTablePageSize}
      fetchData={fetchData}
      isResetPageIndex={isResetPageIndex}
      isManualProcessing={true}
      setFilterText={setFilterText}
      setSortBy={setSortBy}
      sortByCol={{id: 'startDate', desc: true}}
    // setSortBy
    // rows={data}
    // preSortedRows={data}
    />
  )
};

export default EngagementTable;
