import React, { useCallback, useState, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';

import { formatDateWithDashes, getDateObjectFromString, formatDateToStandard, getEndDateTime, getDateFromString } from '../utils/utils';
import ConfirmationDialog from '../ConfirmationDialog';
import AllocationsTimeline from '../allocations/AllocationsTimeline';
import { AppConfig } from '../../Config';
import { TEAM_ALLOCATIONS, DIALOG_TYPES } from '../utils/AppConstants';
import useHttp from '../utils/http';
import CSVUploadComponent from '../CSVUploadComponent';
import BackdropProgress from '../BackdropProgress';
import { useAllocationTypes } from "../DataContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
        height: '100%'
    },

    flexGrid: {
        flexGrow: 1,
    },

    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: '90%',
    },

    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ACTIONS = {
    CONFIRM_BULK_UPLOAD: 'CONFIRM_BULK_UPLOAD',
    CONFIRM_PARTIAL_UPLOAD: 'CONFIRM_PARTIAL_UPLOAD',
    PROCEED_WITH_CSV_ERRORS: 'PROCEED_WITH_CSV_ERRORS',
    DOWNLOAD_ERROR_FILE: 'DOWNLOAD_ERROR_FILE',
    SHOW_INFO: 'SHOW_INFO'
};

const BulkUpload = ({ header }) => {
    const classes = useStyles();

    const { handleRequest, handleComponentEncodedRequest } = useHttp();
    let allocationTypes = useAllocationTypes();

    const [showTimeline, setShowTimeline] = useState(false);
    const [isBackdropProgressOpen, setIsBackdropProgressOpen] = useState(false);
    const [isAddAllocationButtonVisible, setIsAddAllocationButtonVisible] = useState(false);
    const [isErrorFileButtonVisible, setIsErrorFileButtonVisible] = useState(false);

    const [processedData, setProcessedData] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [newAllocations, setNewAllocations] = useState([]);

    const [timelineStartDate, setTimelineStartDate] = useState(new Date());
    const [timelineEndDate, setTimelineEndDate] = useState(new Date());
    const [timelineEmails, setTimelineEmails] = useState([{}]);
    const [timelineItems, setTimelineItems] = useState([]);

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");

    const bulkUploadReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.CONFIRM_BULK_UPLOAD:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.CONFIRM_DIALOG
                }
            case ACTIONS.CONFIRM_PARTIAL_UPLOAD:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.YES_NO_DIALOG
                }
            case ACTIONS.PROCEED_WITH_CSV_ERRORS:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.YES_NO_DIALOG
                }
            case ACTIONS.DOWNLOAD_ERROR_FILE:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.YES_NO_DIALOG
                }
            case ACTIONS.SHOW_INFO:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.INFO_DIALOG
                }

            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails, dialogType, dialogName }, dispatchForm] = useReducer(bulkUploadReducer, { confirmDialogDetails: { open: false }, dialogType: '', dialogName: '' });

    // dataArr: Array of arrays (each record is an array)
    // returns an array of json objects
    const makeJsonObjectsFromCSV = (dataArr) => {
        let allocationObjectsArr = [];
        let erroneousRecordNumArr = [];

        dataArr.forEach((record, index) => {
            if (record.length === 5 && record[0] !== "") {
                let allocationObj = {};

                allocationObj["email"] = record[0].toLowerCase() + "@wso2.com";
                allocationObj["team"] = record[1];
                allocationObj["startDate"] = record[2];
                allocationObj["endDate"] = record[3];
                allocationObj["allocationTypeName"] = record[4];
                // allocationObj["valid"] = null;
                // allocationObj["uploaded"] = false;
                allocationObj["index"] = index;
                // allocationObj["error"] = null;

                allocationObjectsArr.push(allocationObj);
            } else {
                erroneousRecordNumArr.push(index + 1);
            }
        });

        if (erroneousRecordNumArr.length > 0) {
            setErrorList(oldList => [...oldList, `Incorrect number of columns / empty data in these lines: ${erroneousRecordNumArr.join()}`])
            console.error("Identified errors in following lines " + erroneousRecordNumArr.join());
        }

        return {
            allocationObjectsArr: allocationObjectsArr,
            erroneousRecordsLength: erroneousRecordNumArr.length
        }
    };

    const validateAllocations = (data) => {
        getEmailsList((employeeData) => {
            getAllocationsPerUserForValidate(data, employeeData, (data, employeeData, existingAllocationsMap) => {
                let { isValid, newAllocations, isOverlappedItemsAvail } = validateAndPreProcessAllocations(data, employeeData, existingAllocationsMap);

                setIsBackdropProgressOpen(false);

                if (isValid) {
                    bulkUploadConfirmation();
                } else {
                    if (newAllocations.length > 0) {
                        partialUploadConfirmation(isOverlappedItemsAvail);
                    } else {
                        uploadErrorInfo();
                    }

                    if (isOverlappedItemsAvail) {
                        setShowTimeline(true);
                    }
                }
            });
        });
    }

    const bulkUploadConfirmation = () => {
        let confirmDialogDetails = TEAM_ALLOCATIONS.BULK_UPLOAD.MESSAGES.CONFIRM_BULK_UPLOAD;

        dispatchForm({
            type: ACTIONS.CONFIRM_BULK_UPLOAD,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const partialUploadConfirmation = (isOverlappedItemsAvail) => {
        let confirmDialogDetails = TEAM_ALLOCATIONS.BULK_UPLOAD.MESSAGES.CONFIRM_PARTIAL_UPLOAD;

        dispatchForm({
            type: ACTIONS.CONFIRM_PARTIAL_UPLOAD,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: isOverlappedItemsAvail ? confirmDialogDetails.message : confirmDialogDetails.messageErrOnly
            },
        });
    }

    const uploadErrorInfo = () => {
        let confirmDialogDetails = TEAM_ALLOCATIONS.BULK_UPLOAD.MESSAGES.SHOW_INFO;

        dispatchForm({
            type: ACTIONS.SHOW_INFO,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const proceedWithCSVErrorsConfirmation = () => {
        let confirmDialogDetails = TEAM_ALLOCATIONS.BULK_UPLOAD.MESSAGES.PROCEED_WITH_CSV_ERRORS;

        dispatchForm({
            type: ACTIONS.PROCEED_WITH_CSV_ERRORS,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const downloadErrorFileConfirmation = () => {
        let confirmDialogDetails = TEAM_ALLOCATIONS.BULK_UPLOAD.MESSAGES.DOWNLOAD_ERROR_FILE;

        dispatchForm({
            type: ACTIONS.DOWNLOAD_ERROR_FILE,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const getAllocationsPerUserForValidate = async (data, employeeData, callbackFn) => {
        let existingAllocationsMap = {};
        const promises = [];
        let allocation;
        let minStartDate = null;
        let maxEndDate = null;
        let allocationStartDate;
        let allocationEndDate;
        let emails = [];

        // Get the min start date and max end date of the new allocations and retrieve the existing allocations
        // for that time period for all the users in one API call
        for (let i = 0; i < data.length; i++) {
            allocation = data[i];
            allocationStartDate = getDateObjectFromString(allocation.startDate);
            allocationEndDate = getDateObjectFromString(allocation.endDate);

            emails.push(allocation.email);

            if (minStartDate === null || minStartDate > allocationStartDate) {
                minStartDate = allocationStartDate;
            }
            if (maxEndDate === null || maxEndDate < allocationEndDate) {
                maxEndDate = allocationEndDate;
            }
        }

        const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationsByEmail;

        const body = {
            emails: emails,
            startDate: formatDateWithDashes(minStartDate),
            endDate: formatDateWithDashes(maxEndDate),
        }

        handleRequest(endPointUrl, 'POST', body, (allocations) => {
            allocations.forEach(item => {
                if (!existingAllocationsMap[item.email]) {
                    existingAllocationsMap[item.email] = [];
                }

                existingAllocationsMap[item.email].push(item);
            });

            callbackFn(data, employeeData, existingAllocationsMap);
        }, () => {
            setMessageBar("An error occurred in retrieving existing allocations", true, 'error');
        });
    }

    const getEmailsList = useCallback((callbackFn) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployees;
        const params = [`employeeStatuses=${encodeURIComponent("Active,Marked Leaver")}`, `excludedEmploymentTypes=Internship`].join('&');
        const requestUrl = `${endPointUrl}?${params}`;
        
        return handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
            let employeeData = [];
            let employee;

            data.forEach(item => {
                employee = {
                    email: item.workEmail,
                    team: item.team
                };

                employeeData.push(employee);
            });

            if (callbackFn) {
                callbackFn(employeeData);
            }
        })
    }, []);

    const validateAndPreProcessAllocations = (allocationList, employeeData, existingAllocationsMap) => {
        let isValidAll = true;
        let isValidAllocation;
        let validationErrorList = [];
        let allocationEmail;
        let overlappingEmails = [];
        let overlappingAllocations = [];
        let timelineStartDate = null;
        let timelineEndDate = null;
        let newAllocations = [];

        allocationList.forEach(allocation => {
            isValidAllocation = true;

            allocationEmail = allocation.email;

            if (!validateEmail(employeeData, allocationEmail)) {
                isValidAll = false;
                isValidAllocation = false;

                validationErrorList.push(`The email id is invalid for ${allocationEmail}`);
            } else if (!validateTeam(employeeData, allocationEmail, allocation.team)) {
                isValidAll = false;
                isValidAllocation = false;

                validationErrorList.push(`The email ${allocationEmail} is not in ${allocation.team} team`);
            }

            if (validateOverlappingAllocSameEmailInCSV(allocation, allocationList)) {
                isValidAll = false;
                isValidAllocation = false;

                validationErrorList.push(`The allocation is overlapped for ${allocationEmail} ${allocation.startDate} <-> ${allocation.endDate} in csv`);
            }

            // Check why engagement code validation has a hardcorded 'non-rnd' value in old code

            if (!validateAllocationType(allocation.allocationTypeName)) {
                isValidAll = false;
                isValidAllocation = false;

                validationErrorList.push(`The allocation type for allocation of ${allocationEmail} is wrong`);
            }

            // validateAndPreprocessStartEndDates
            let validatedResultWithExisting = validateAllocationWithExisting(allocationEmail, allocation.startDate, allocation.endDate, allocation.allocationTypeName, existingAllocationsMap[allocationEmail]);

            if (!validatedResultWithExisting.isValid) {
                isValidAll = false;

                if (!overlappingEmails.includes(allocationEmail)) {
                    overlappingEmails.push({
                        id: allocationEmail,
                        title: allocationEmail
                    });
                }

                overlappingAllocations.push(...validatedResultWithExisting.overlappingItems);
                validationErrorList.push(...validatedResultWithExisting.allocErrorList);

                if (!timelineStartDate && !timelineEndDate) {
                    timelineStartDate = validatedResultWithExisting.timelineStartDate;
                    timelineEndDate = validatedResultWithExisting.timelineEndDate;
                } else {
                    if (timelineStartDate > validatedResultWithExisting.timelineStartDate) {
                        timelineStartDate = validatedResultWithExisting.timelineStartDate;
                    }

                    if (timelineEndDate < validatedResultWithExisting.timelineEndDate) {
                        timelineEndDate = validatedResultWithExisting.timelineEndDate;
                    }
                }
            } else if (isValidAllocation) {
                newAllocations.push(allocation);
            }

        });

        setTimelineStartDate(timelineStartDate);
        setTimelineEndDate(timelineEndDate);
        setTimelineEmails(overlappingEmails);
        setTimelineItems(overlappingAllocations);

        setErrorList(prevList => [...prevList, ...validationErrorList]);
        setNewAllocations(newAllocations);

        return {
            isValid: isValidAll,
            newAllocations: newAllocations,
            isOverlappedItemsAvail: overlappingAllocations.length > 0
        }
    }

    const validateEmail = (employeeData, email) => {
        return employeeData.find(item => item.email === email);
    }

    const validateTeam = (employeeData, email, team) => {
        return true;
        // let employee = employeeData.find(item => item.email === email);

        // return employee && employee.team.toLowerCase() === team.toLowerCase();
    }

    const validateOverlappingAllocSameEmailInCSV = (allocation, allocationList) => {
        let isOverlapping = false;
        let allocationStartDate = getDateObjectFromString(allocation.startDate);
        let allocationEndDate = getDateObjectFromString(allocation.endDate);
        let allocListItemStartDate, allocListItemEndDate;

        for (let allocListItem of allocationList) {
            if (allocListItem.email === allocation.email && allocListItem.index !== allocation.index) {
                allocListItemStartDate = getDateObjectFromString(allocListItem.startDate);
                allocListItemEndDate = getDateObjectFromString(allocListItem.endDate);

                if ((allocationStartDate < allocListItemStartDate && allocationEndDate >= allocListItemStartDate) ||
                    (allocationStartDate >= allocListItemStartDate && allocationStartDate <= allocListItemEndDate)
                ) {
                    isOverlapping = true;
                    break;
                }
            }
        };

        return isOverlapping;
    }

    const validateAllocationType = (allocationTypeName) => {
        if (!allocationTypes) {
            setMessageBar("An error occurred in loading Allocation Types. Please reload and try again.", true, 'error');
            return false;
        }
        return allocationTypes.find(item => item.allocationTypeName === allocationTypeName);
    }

    const validateAllocationWithExisting = (email, startDate, endDate, allocationTypeName, existingAllocations) => {
        let newAllocStartDate = getDateObjectFromString(startDate);
        let newAllocEndDate = getDateObjectFromString(endDate);
        let existingAllocStartDate, existingAllocEndDate;
        let isValid = true;
        let allocErrorList = [];
        let isAllocationOverlapping, newItem, overlappingItem, overlappingItemId, itemId, existingAllocType;

        let overlappingItems = [];
        let selectedBgColor = "#bcbcbc";
        let textColor = "#000000";
        let newAllocColor = "#cc0000";
        let existingAllocColor = "#9fc5e8";

        let timelineStartDate = newAllocStartDate;
        let timelineEndDate = newAllocEndDate;
        let endDateTime;
        let tooltip = "";

        if (newAllocStartDate > newAllocEndDate) {
            let newError = `${email}: Start Date [${newAllocStartDate}] is greater than End Date [${newAllocEndDate}]`;
            setErrorList(oldList => [...oldList, newError]);
        }

        if (existingAllocations) {
            existingAllocations.forEach(allocation => {
                existingAllocType = allocation.allocationTypeName;

                let baseError = `${email}:`;
                // var baseErrorAlloc = user + ":" + idToTypeMap[prevAllocation["typeId"]];
                let endError = `with existing ${existingAllocType} allocation.`
                existingAllocStartDate = getDateObjectFromString(allocation.startDate);
                existingAllocEndDate = getDateObjectFromString(allocation.endDate);

                isAllocationOverlapping = false;

                if (existingAllocStartDate <= newAllocStartDate && existingAllocEndDate >= newAllocEndDate) {
                    // errorArray.push(baseErrorAlloc + ": Allocation fully overlaps");
                    allocErrorList.push(`${baseError} New Allocation fully overlaps ${endError}`);
                    isValid = false;
                    isAllocationOverlapping = true;
                } else if (existingAllocStartDate <= newAllocStartDate && existingAllocEndDate >= newAllocStartDate) {
                    // errorArray.push(baseErrorAlloc + ": The start date [" + startDate + "] overlaps at start"); // allocation overlaps at start
                    allocErrorList.push(`${baseError} New Allocation overlaps at start [${formatDateWithDashes(newAllocStartDate)}] ${endError}`);
                    isValid = false;
                    isAllocationOverlapping = true;
                } else if (existingAllocStartDate <= newAllocEndDate && existingAllocEndDate >= newAllocEndDate) {
                    allocErrorList.push(`${baseError} New Allocation overlaps at end [${formatDateWithDashes(newAllocEndDate)}] ${endError}`);
                    // errorArray.push(baseErrorAlloc + ": The start date [" + endDate + "] overlaps at end"); // allocation overlaps at end
                    isValid = false;
                    isAllocationOverlapping = true;
                } else if (existingAllocStartDate >= newAllocStartDate && existingAllocEndDate <= newAllocEndDate) {
                    // errorArray.push(baseErrorAlloc + ": The start date [" + startDate + "] and end date [" + endDate + "] overlaps"); // allocation overlap at middle
                    allocErrorList.push(`${baseError} New Allocation overlap at middle [${formatDateWithDashes(existingAllocStartDate)} and ${formatDateWithDashes(existingAllocEndDate)}] ${endError}`);
                    isValid = false;
                    isAllocationOverlapping = true;
                }

                if (isAllocationOverlapping) {
                    if (existingAllocStartDate < timelineStartDate) {
                        timelineStartDate = existingAllocStartDate;
                    }

                    if (existingAllocEndDate > timelineEndDate) {
                        timelineEndDate = existingAllocEndDate;
                    }

                    overlappingItemId = email + "_" + (existingAllocType ? existingAllocType : (Math.random() * 1000)) + "_" + new Date().getTime() + Math.random() * 1000;
                    endDateTime = getEndDateTime(new Date(existingAllocEndDate.getTime()));
                    tooltip = [`Type: ${allocation.allocationTypeName}`, `Eng. Code: ${allocation.engagementCode}`, `Start Date: ${getDateFromString(allocation.startDate)}`,
                    `End Date: ${getDateFromString(allocation.endDate)}`].join("\n");

                    overlappingItem = {
                        id: overlappingItemId,
                        group: email,
                        title: existingAllocType,
                        start_time: existingAllocStartDate.getTime(),
                        end_time: endDateTime,
                        bgColor: existingAllocColor,
                        selectedBgColor: selectedBgColor,
                        color: textColor,
                        isRemovable: false,
                        tooltip: tooltip
                    }

                    overlappingItems.push(overlappingItem);
                }
            })
        }

        if (!isValid) {
            itemId = email + "_" + (allocationTypeName ? allocationTypeName : (Math.random() * 1000)) + "_" + new Date().getTime() + Math.random() * 1000;
            endDateTime = getEndDateTime(new Date(newAllocEndDate.getTime()));
            tooltip = `Type: ${allocationTypeName}`;

            newItem = {
                id: itemId,
                group: email,
                title: allocationTypeName,
                start_time: newAllocStartDate.getTime(),
                end_time: endDateTime,
                bgColor: newAllocColor,
                selectedBgColor: selectedBgColor,
                color: textColor,
                isRemovable: false,
                tooltip: tooltip
            }

            overlappingItems.push(newItem);
        }

        return {
            isValid: isValid,
            allocErrorList: allocErrorList,
            overlappingItems: overlappingItems,
            timelineStartDate: timelineStartDate,
            timelineEndDate: timelineEndDate
        }
    }

    const handleOnDrop = (data) => {

        setErrorList([]);
        setIsAddAllocationButtonVisible(false);
        setIsErrorFileButtonVisible(false);
        setShowTimeline(false);

        if (data.data) {
            setIsBackdropProgressOpen(true);

            let processResult = makeJsonObjectsFromCSV(data.data);
            let processedData = processResult.allocationObjectsArr;
            let erroneousRecordsLength = processResult.erroneousRecordsLength;

            setProcessedData(processedData);

            if (processedData.length > 0) {
                if (erroneousRecordsLength > 0) {
                    proceedWithCSVErrorsConfirmation();
                } else {
                    validateAllocations(processedData);
                }
            } else {
                setIsBackdropProgressOpen(false);
                setMessageBar("Cannot proceed. Please recheck the csv file for empty data / incorrect number of columns", true, 'error');
            }
        } else {
            setMessageBar("An error occurred in processing the file", true, 'error');
        }
    };

    const onUploadRejected = () => {
        setMessageBar("The file format uploaded is incorrect. Please upload a file with csv format", true, 'error');
    }

    const handleOnRemoveFile = (data) => {
        setIsAddAllocationButtonVisible(false);
        setIsErrorFileButtonVisible(false);
        setShowTimeline(false);
    };

    const handleDialogBoxAction = (isConfirm) => {
        switch (confirmDialogDetails.id) {
            case 'confirmBulkUpload':
                return handleBulkUpload(isConfirm);

            case 'confirmPartialUpload':
                return handlePartialUpload(isConfirm);

            case 'proceedWithCSVErrors':
                return handleProceedWithCSVErrors(isConfirm);

            case 'downloadErrorFile':
                return handleDownloadErrorFile(isConfirm);
            case 'showInfo':
                return handleShowInfo(isConfirm);

            default:
                return;
        }
    };

    const handleBulkUpload = (isConfirm) => {
        dispatchForm({
            type: ACTIONS.CONFIRM_BULK_UPLOAD,
            confirmDialogDetails: {
            },
        });

        if (isConfirm) {
            submitBulkUpload();
        } else {
            setIsAddAllocationButtonVisible(true);
        }
    }

    const handlePartialUpload = (isConfirm) => {
        dispatchForm({
            type: ACTIONS.CONFIRM_PARTIAL_UPLOAD,
            confirmDialogDetails: {
            },
        });

        if (isConfirm) {
            submitBulkUpload(downloadErrorFileConfirmation());
        } else {
            downloadErrorFileConfirmation();
        }
    }

    const handleShowInfo = (isConfirm) => {
        dispatchForm({
            type: ACTIONS.SHOW_INFO,
            confirmDialogDetails: {
            },
        });

        if (isConfirm) {
            downloadErrorFileConfirmation();
        }
    }

    const handleProceedWithCSVErrors = (isConfirm) => {
        dispatchForm({
            type: ACTIONS.PROCEED_WITH_CSV_ERRORS,
            confirmDialogDetails: {
            },
        });

        if (isConfirm) {
            validateAllocations(processedData);
        } else {
            setIsBackdropProgressOpen(false);
            downloadErrorFileConfirmation();
        }
    }

    const handleDownloadErrorFile = (isConfirm) => {
        dispatchForm({
            type: ACTIONS.DOWNLOAD_ERROR_FILE,
            confirmDialogDetails: {
            },
        });

        if (isConfirm) {
            downloadErrorFile();
            // setIsAddAllocationButtonVisible(true);
        } else {
            setIsErrorFileButtonVisible(true);
            // setIsAddAllocationButtonVisible(true);
        }
    }

    const submitBulkUpload = useCallback(async (callbackFn) => {
        let requestUrl = AppConfig.baseUrl + AppConfig.bulkUploadAllocations;
        let allocationData = [], allocationLogData = [];
        let allocation, allocType, allocationLog;

        newAllocations.forEach(item => {
            allocType = allocationTypes.find(type => type.allocationTypeName === item.allocationTypeName);

            allocation = {
                email: item.email,
                startDate: formatDateWithDashes(new Date(item.startDate)),
                endDate: formatDateWithDashes(new Date(item.endDate)),
                allocationType: allocType && allocType.id,
                status: "Active",
                engagementId: "-1.0",
                engagementCode: allocType && allocType.defaultEngagementCode,
                isTravelRequired: "No",
                isRecurring: "No"
            };

            allocationLog = {
                email: item.email,
                startDate: formatDateWithDashes(new Date(item.startDate)),
                endDate: formatDateWithDashes(new Date(item.endDate)),
                allocationType: allocType && allocType.id,
                engagementCode: allocType && allocType.defaultEngagementCode
            }

            allocationData.push(allocation);
            allocationLogData.push(allocationLog);
        })

        handleRequest(requestUrl, 'POST', { newAllocations: allocationData, allocationLogs: allocationLogData }, (data) => {

            setMessageBar("Bulk upload is completed successfully", true, 'success');

            if (callbackFn) {
                callbackFn(); // Need this to ask downloading error file on partial upload
            }

            setIsAddAllocationButtonVisible(false);
        }, () => {
            setMessageBar("Error in Bulk upload", true, 'error');
        });
    }, [newAllocations]);

    const downloadErrorFile = () => {
        const element = document.createElement("a");
        const errorString = createErrorString();
        const file = new Blob([errorString], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "errorFile.txt";
        document.body.appendChild(element);
        element.click();
    }

    const createErrorString = () => {
        let errorString = errorList.join("\n");

        return errorString;
    }

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    return (
        <React.Fragment>
            {header &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" gutterBottom>
                            {header}
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Grid container spacing={1}>

                <Grid container item xs={12} spacing={1} style={{justifyContent:'space-around', marginBottom:10}}>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl}>
                            <CSVUploadComponent handleOnDrop={handleOnDrop} handleOnRemoveFile={handleOnRemoveFile} onUploadRejected={onUploadRejected} />
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid container item xs={12} spacing={1} style={{justifyContent:'flex-end'}}>
                    {isAddAllocationButtonVisible && (
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl}>
                                <Button variant="contained" size="medium" color="secondary" onClick={() => { bulkUploadConfirmation() }}>
                                    Add Allocations
                                </Button>
                            </FormControl>
                        </Grid>
                    )}

                    {isErrorFileButtonVisible && (
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl}>
                                <Button variant="contained" size="medium" color="secondary" onClick={() => { downloadErrorFile() }}>
                                    Download Error File
                                </Button>
                            </FormControl>
                        </Grid>
                    )}

                </Grid>

                {showTimeline && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormControl>
                            <AllocationsTimeline startDate={timelineStartDate} endDate={timelineEndDate} groups={timelineEmails} items={timelineItems} />
                        </FormControl>
                    </Grid>
                )}
            </Grid>

            <BackdropProgress open={isBackdropProgressOpen} />

            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={dialogType} />

            <Portal>
                <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                    <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Portal>
        </React.Fragment>
    );
};

export default BulkUpload;