import React, { useEffect, useState, useCallback, useContext, createContext } from 'react';
import { AppConfig, Privileges } from '../Config';
import useHttp from './utils/http';
import { isAccessGranted } from './utils/utils';

const AllocationTypesContext = createContext();
const JobBandDataContext = createContext();

export function useAllocationTypes() {
  return useContext(AllocationTypesContext);
}

export function useJobBandData() {
  return useContext(JobBandDataContext);
}

export function DataProvider({ children }) {
  const { handleRequest } = useHttp();


  const [allocationTypes, setAllocationTypes] = useState([]);
  const [jobBandData, setJobBandData] = useState([]);

  const getAllocationTypes = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationTypes;

    handleRequest(endPointUrl, 'GET', null, (types) => {
      let typesList = types.map(type => ({
        id: type.id,
        allocationTypeName: type.allocationTypeName,
        engagementTypeId: type.engagementTypeId,
        engagementTypeName: type.engagementTypeName,
        color: type.color,
        department: type.department,
        emailToAllocations: type.emailToAllocations,
        defaultEngagementCode: type.defaultEngagementCode,
        engagementCodeSuffix: type.engagementCodeSuffix,
        status: type.status
      }))

      setAllocationTypes(typesList);
    })
  }, []);

  const getEmployeeJobBandData = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployeeJobBandData;

    handleRequest(endPointUrl, 'GET', null, (data) => {
      setJobBandData(data);
    })
  }, []);

  useEffect(() => {
    if (isAccessGranted([
      Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE, Privileges.CUSTOMER_ENGAGEMENTS_PAGE,
      Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE, Privileges.TEAM_ALLOCATIONS_PAGE,
      Privileges.CONSULTANT_ALLOCATIONS_REPORT, Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE,
      Privileges.INTERNAL_ENGAGEMENTS_PAGE, Privileges.INTERNAL_ENGAGEMENT_EDIT,
      Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE])) {
      getAllocationTypes();
    }

    if (isAccessGranted([Privileges.CUSTOMER_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.INTERNAL_ENGAGEMENT_ALLOCATE_CONSULTANT, Privileges.TEAM_ALLOCATIONS_PAGE])) {
      getEmployeeJobBandData();
    }
  }, []);

  return (
    <AllocationTypesContext.Provider value={allocationTypes}>
      <JobBandDataContext.Provider value={jobBandData}>
        {children}
      </JobBandDataContext.Provider>
    </AllocationTypesContext.Provider>
  )

}