import { Fragment, useMemo, useState, useCallback, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

import useHttp from '../utils/http';
import { AppConfig } from '../../Config';
import TableComponent from '../TableComponent';
import ConfirmationDialog from '../ConfirmationDialog';
import { DIALOG_TYPES, ALLOCATION_TYPES_SETTINGS, ALLOCATION_TYPE_ACTIVE_STATUS } from "../utils/AppConstants";
import AddAllocationType from './AddAllocationType';

const ACTIONS = {
    ADD_NEW_ALLOCATION_TYPE: 'ADD_NEW_ALLOCATION_TYPE',
    UPDATE_ALLOCATION_TYPE: 'UPDATE_ALLOCATION_TYPE',
    DELETE_ALLOCATION_TYPE: 'DELETE_ALLOCATION_TYPE',
    SHOW_PAGE_INFO: 'SHOW_PAGE_INFO'
}

const DEFAULT_COLOR_CODE = '#00aabb';

const AllocationTypesSettings = ({ setMessageBar }) => {
    const { handleRequest } = useHttp();

    const allocationTypesReducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.ADD_NEW_ALLOCATION_TYPE:
                return {
                    ...state, allocationTypePopupDetails: action.allocationTypePopupDetails, title: "Add New Allocation Type", isEditMode: false
                }

            case ACTIONS.UPDATE_ALLOCATION_TYPE:
                return {
                    ...state, allocationTypePopupDetails: action.allocationTypePopupDetails, title: "Update Allocation Type", isEditMode: true
                }

            case ACTIONS.DELETE_ALLOCATION_TYPE:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.CONFIRM_DIALOG
                }

            case ACTIONS.SHOW_PAGE_INFO:
                return {
                    ...state, confirmDialogDetails: action.confirmDialogDetails, dialogType: DIALOG_TYPES.INFO_DIALOG
                }

            default:
                throw new Error('Should not get here');
        }
    };

    const [{ confirmDialogDetails, dialogType, allocationTypePopupDetails, title, isEditMode }, dispatchForm] = useReducer(allocationTypesReducer, { confirmDialogDetails: { open: false }, dialogType: '', allocationTypePopupDetails: {}, title: '', isEditMode: false });


    const columns = useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action',
                collapse: true,
                disableSortBy: true,
                maxWidth: 70,
                style: { display: 'flex', alignItems: 'center' },
                Cell: ({ row }) => {
                    return (
                        <div>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" onClick={() => { onClickEditAllocationType(row.original) }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => { confirmDeleteAllocationType(row.original) }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                Header: 'Engagement Type',
                accessor: 'engagementTypeName',
                collapse: true,
            },
            {
                Header: 'Department',
                accessor: 'department',
                collapse: true,
            },
            {
                Header: 'Allocation Type',
                accessor: 'allocationTypeName',
                collapse: true,
                minWidth: 200
            },
            {
                Header: 'Color Code',
                accessor: 'color',
                collapse: true,
                maxWidth: 70,
                Cell: ({ row }) => {
                    if (row) {
                        return (
                            <div>
                                <span style={{ backgroundColor: row.original.color, height: 15, width: 15, display: 'inline-block', marginRight: 10, verticalAlign: 'middle'}} /> 
                                <span>{row.original.color}</span>
                            </div>
                        )
                        // return row.original.requestStatus;
                    }
                },
            },
            {
                Header: 'Default Engagement Code',
                accessor: 'defaultEngagementCode',
                collapse: true,
            },
            {
                Header: 'Engagement Code Suffix',
                accessor: 'engagementCodeSuffix',
                collapse: true,
            },
            {
                Header: 'Notify To Allocations',
                accessor: 'emailToAllocations',
                collapse: true,
            }
        ], []
    );

    const [allocationTypes, setAllocationTypes] = useState([]);
    const [selectedAllocationTypeData, setSelectedAllocationTypeData] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);

    const getAllocationTypes = useCallback(async () => {
        let allocationTypesList = [];
        let allocationType;

        const endPointUrl = AppConfig.baseUrl + `${AppConfig.getAllocationTypes}?allocationTypeStatus=${ALLOCATION_TYPE_ACTIVE_STATUS}`;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(type => {
                allocationType = {
                    id: type.id,
                    allocationTypeName: type.allocationTypeName,
                    engagementTypeId: type.engagementTypeId,
                    engagementTypeName: type.engagementTypeName,
                    color: type.color,
                    department: type.department,
                    emailToAllocations: type.emailToAllocations,
                    defaultEngagementCode: type.defaultEngagementCode,
                    engagementCodeSuffix: type.engagementCodeSuffix
                }

                allocationTypesList.push(allocationType);
            });

            setAllocationTypes(allocationTypesList);
        }, () => {

        }, setIsDataLoading);
    }, []);

    useEffect(() => {
        getAllocationTypes();
    }, []);

    const handleAllocationTypeData = (property, value) => {
        setSelectedAllocationTypeData({
            ...selectedAllocationTypeData,
            [property]: value
        })
    };

    const confirmDeleteAllocationType = (selectedAllocationTypeData) => {
        let confirmDialogDetails = ALLOCATION_TYPES_SETTINGS.MESSAGES.DELETE_ALLOCATION_TYPE;

        dispatchForm({
            type: ACTIONS.DELETE_ALLOCATION_TYPE,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message,
                data: {
                    id: selectedAllocationTypeData.id
                }
            },
        });
    }

    const onClickAddBtn = () => {
        setIsSubmitBtnClicked(false);

        dispatchForm({
            type: ACTIONS.ADD_NEW_ALLOCATION_TYPE,
            allocationTypePopupDetails: {
                open: true,
            },
        });

        setSelectedAllocationTypeData({
            id: "",
            allocationTypeName: "",
            engagementTypeId: "",
            // engagementTypeName: "",
            color: DEFAULT_COLOR_CODE,
            department: "",
            emailToAllocations: "",
            defaultEngagementCode: "",
            engagementCodeSuffix: ""
        });
    }

    const onClickEditAllocationType = (data) => {
        setIsSubmitBtnClicked(false);

        dispatchForm({
            type: ACTIONS.UPDATE_ALLOCATION_TYPE,
            allocationTypePopupDetails: {
                open: true,
            },
        });

        setSelectedAllocationTypeData({
            ...selectedAllocationTypeData,
            ...data
        });
    }

    const handleAllocationTypeSubmission = (isConfirm, isEditMode) => {
        if (isConfirm) {
            setIsSubmitBtnClicked(true);

            if (isValidForm(selectedAllocationTypeData)) {
                dispatchForm({
                    type: ACTIONS.ADD_NEW_ALLOCATION_TYPE,
                    allocationTypePopupDetails: {
                    },
                });

                if (isEditMode) {
                    updateAllocationType();
                } else {
                    addNewAllocationType();
                }
            }
        } else {
            dispatchForm({
                type: ACTIONS.ADD_NEW_ALLOCATION_TYPE,
                allocationTypePopupDetails: {
                },
            });
        }
    }

    const handleDeleteAllocationType = (isConfirm) => {
        if (isConfirm) {
            const endPointUrl = AppConfig.baseUrl + AppConfig.deleteAllocationType.replace("$id", confirmDialogDetails.data.id);

            handleRequest(endPointUrl, 'DELETE', null, (data) => {
                getAllocationTypes();
                setMessageBar("Successfully deleted the Allocation Type", true, 'success');
            }, () => {
                setMessageBar("An error occurred while deleting the Allocation Type", true, 'error');
            })
        }

        dispatchForm({
            type: ACTIONS.DELETE_ALLOCATION_TYPE,
            confirmDialogDetails: {
            },
        });
    }

    const addNewAllocationType = () => {
        let endPointUrl = AppConfig.baseUrl + AppConfig.addNewAllocationType;
        let allocationTypeData = {
            allocationTypeName: selectedAllocationTypeData.allocationTypeName,
            engagementTypeId: selectedAllocationTypeData.engagementTypeId,
            color: selectedAllocationTypeData.color?.hex ? `#${selectedAllocationTypeData.color.hex}` : selectedAllocationTypeData.color,
            department: selectedAllocationTypeData.department,
            emailToAllocations: selectedAllocationTypeData.emailToAllocations,
            defaultEngagementCode: selectedAllocationTypeData.defaultEngagementCode,
            engagementCodeSuffix: selectedAllocationTypeData.engagementCodeSuffix
        }

        handleRequest(endPointUrl, 'POST', allocationTypeData, (data) => {
            getAllocationTypes();
            setMessageBar("Allocation Type added successfully", true, 'success');
        }, (customErrMsg) => {
            setMessageBar(customErrMsg ? customErrMsg : `An error occurred in adding Allocation Type`, true, 'error');
        });
    }

    const updateAllocationType = () => {
        let endPointUrl = AppConfig.baseUrl + AppConfig.updateAllocationType.replace("$id", selectedAllocationTypeData.id);
        let allocationTypeData = {
            allocationTypeName: selectedAllocationTypeData.allocationTypeName,
            engagementTypeId: selectedAllocationTypeData.engagementTypeId,
            color: selectedAllocationTypeData.color?.hex ? `#${selectedAllocationTypeData.color.hex}` : selectedAllocationTypeData.color,
            department: selectedAllocationTypeData.department,
            emailToAllocations: selectedAllocationTypeData.emailToAllocations,
            defaultEngagementCode: selectedAllocationTypeData.defaultEngagementCode,
            engagementCodeSuffix: selectedAllocationTypeData.engagementCodeSuffix
        }

        handleRequest(endPointUrl, 'PUT', allocationTypeData, (data) => {
            getAllocationTypes();
            setMessageBar("Allocation Type updated successfully", true, 'success');
        }, (customErrMsg) => {
            setMessageBar(customErrMsg ? customErrMsg : `An error occurred in updating Allocation Type`, true, 'error');
        });
    };

    const isValidForm = (data) => {
        let isValid = true;

        const requiredFields = [
            "engagementTypeId",
            "allocationTypeName",
            "defaultEngagementCode",
        ];

        for (let field of requiredFields) {
            if (data[field] === "" || data[field] === null || data[field] === undefined) {
                isValid = false;
                setMessageBar("You need to fill the required fields", true, 'error');
                break;
            }
        };

        return isValid;
    }

    const handleDialogBoxAction = (isConfirm) => {
        switch (confirmDialogDetails.id) {
            case 'deleteAllocationType':
                return handleDeleteAllocationType(isConfirm);
            case 'showInfo':
                return handleShowInfo();

            default:
                return;
        }
    };

    const showPageInfo = () => {
        let confirmDialogDetails = ALLOCATION_TYPES_SETTINGS.MESSAGES.SHOW_PAGE_INFO;

        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
                open: true,
                id: confirmDialogDetails.id,
                title: confirmDialogDetails.title,
                message: confirmDialogDetails.message
            },
        });
    }

    const handleShowInfo = () => {
        dispatchForm({
            type: ACTIONS.SHOW_PAGE_INFO,
            confirmDialogDetails: {
            },
        });
    }

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        Allocation Types
                        {/* <Tooltip> */}
                        <IconButton aria-label="info" onClick={() => { showPageInfo() }}>
                            <InfoIcon color='secondary' />
                        </IconButton>
                        {/* </Tooltip> */}
                        <Button variant="contained" size="large" color="secondary" onClick={() => { onClickAddBtn() }} style={{ float: 'right', marginRight: 10 }}>
                            Add New
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableComponent
                    columns={columns}
                    data={allocationTypes}
                    getCellProps={cellInfo => ({
                        // style: {
                        //     backgroundColor: (cellInfo.column.id === 'status' ? getStatusColor(cellInfo.value) : '#fff'),
                        // },
                    })}
                    minRows={3}
                    showPagination={true}
                    pageSize={4}
                    style={{ height: '100%' }}
                    isLoading={isDataLoading}
                />

            </Grid>
            <AddAllocationType title={title} data={selectedAllocationTypeData} open={Boolean(allocationTypePopupDetails && Object.values(allocationTypePopupDetails).length) && allocationTypePopupDetails.open} handleAllocationTypeData={handleAllocationTypeData} handleFormAction={handleAllocationTypeSubmission} isEditMode={isEditMode} isSubmitBtnClicked={isSubmitBtnClicked} setMessageBar={setMessageBar} />
            <ConfirmationDialog data={confirmDialogDetails} open={Boolean(confirmDialogDetails && Object.values(confirmDialogDetails).length) && confirmDialogDetails.open} handleSubmit={handleDialogBoxAction} dialogType={dialogType} />
        </Fragment>
    )
}

export default AllocationTypesSettings;