import { handleTokenFailure, getAccessToken } from './oauth';
import { INPUT_INVALID_MSG_GATEWAY } from './AppConstants';

const useHttp = () => {
    const MAX_TRIES = 4;

    // Default request handling where encoding is done for the whole url
    const handleRequest = async (url, method, body, successFn, failFn, loadingFn, currentTry) => {
        handleEncodedRequest(encodeURI(url), method, body, successFn, failFn, loadingFn, currentTry);
    }

    // Request handling where encoding is done for the url params only
    // This is used when the url contains query params which are not encoded properly by encodeURI (e.g. when a query param contains &)
    const handleComponentEncodedRequest = async (url, method, body, successFn, failFn, loadingFn, currentTry) => {
        handleEncodedRequest(url, method, body, successFn, failFn, loadingFn, currentTry);
    }

    const handleEncodedRequest = async (encodedUrl, method, body, successFn, failFn, loadingFn, currentTry) => {
        if (!currentTry) {
            currentTry = 1;
        }

        try {
            if (loadingFn) {
                loadingFn(true);
            }

            const response = await fetch(encodedUrl, {
                method: method,
                body: body ? JSON.stringify(body) : null,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + await getAccessToken()
                }
            });

            let responseBody = "";
            let isGatewayForbidden = false;

            // Assumptions
            // The only reason we may get 403 responses from Gateway is due to user input validation issues (rules blocking certain user inputs).
            // The other reasons may be due to code-level issues. But we assume they are already fixed after testing.
            // We only show the custom error msg for post / patch / put requests
            // We also assume that the gateway is sending a html page in response (therefore no json body and therefore handled in catch)
            try {
                responseBody = await response.json();

                // if (response.status === 403 && (responseBody?.success === undefined || responseBody?.success === null)) {
                //     isGatewayForbidden = true;
                // }
            } catch (e) {
                if (response.status === 403) {
                    isGatewayForbidden = true;
                }
            } finally {
                let customErrMsg = "";

                if (isGatewayForbidden) {
                    customErrMsg = INPUT_INVALID_MSG_GATEWAY;
                }

                if (response.ok) { // Check if the response was successful (status in the range 200-299)
                    successFn(responseBody);

                    if (loadingFn) {
                        loadingFn(false);
                    }
                } else {
                    if (response.status === 401 && currentTry < MAX_TRIES) {
                        handleTokenFailure(() => handleEncodedRequest(encodedUrl, method, body, successFn, failFn, loadingFn, ++currentTry));
                    } else if (currentTry < MAX_TRIES) {
                        handleEncodedRequest(encodedUrl, method, body, successFn, failFn, loadingFn, ++currentTry);
                    } else {
                        console.error(responseBody?.error || response.status);
                        if (failFn) {
                            failFn(customErrMsg);
                        }
                        if (loadingFn) {
                            loadingFn(false);
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err);
            if (failFn) {
                failFn();
            }
            if (loadingFn) {
                loadingFn(false);
            }
        }
    }

    return {
        handleRequest,
        handleComponentEncodedRequest
    };
};

export default useHttp;
