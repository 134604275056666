import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useHistory } from "react-router-dom";

import EngagementForm from './EngagementForm';
import Engagements from './Engagements';
import EngagementAllocations from '../engagementAllocations/EngagementAllocations';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';
import { isAccessGranted } from '../utils/utils';
import { Privileges } from '../../Config';

const useStyles = makeStyles((theme) => ({
    page: {
        padding: 20,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
}));

const tabProps = (index) => {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tab-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EngagementNew = ({isInternal}) => {
    const classes = useStyles();
    const history = useHistory();

    const [tab, setTab] = useState(history && history.location && history.location.state ? history.location.state.tab : 0);

    const [tabIndexes, setTabIndexes] = useState({
        newEngagementTab: 0,
        engagementsTab: 1,
        allocationsTab: 2,
    });

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        let initIndex = 0;
        let newEngagementTabIndex = -1;
        let engagementsTabIndex = -1;
        let allocationsTabIndex = -1;

        if (isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE])) {
            newEngagementTabIndex = initIndex;
            initIndex++;
        }

        if (isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENTS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENTS_PAGE])) {
            engagementsTabIndex = initIndex;
            initIndex++;
        }

        if (isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE])) {
            allocationsTabIndex = initIndex;
        }

        setTabIndexes({
            newEngagementTab: newEngagementTabIndex,
            engagementsTab: engagementsTabIndex,
            allocationsTab: allocationsTabIndex,
        });


    }, [])

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);

        if (type) {
            setAlertMessageType(type);
        }
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    return (
        <React.Fragment>
            <Paper square>
                <AppBar position="static">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="Engagement tabs"
                    >
                        {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE]) &&
                            <Tab label={
                                <Button className={classes.createNewButton} startIcon={<AddCircleOutlineIcon />} color="secondary">
                                    {isInternal? "Create" : "Create"}
                                </Button>
                            } {...tabProps(tabIndexes.newEngagementTab)} />
                        }

                        {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENTS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENTS_PAGE]) &&
                            <Tab label={
                                <Button startIcon={<AssignmentIcon />} color="secondary" onClick={() => { }}>
                                    {isInternal? "Internal Engagements" : "Customer Engagements"}
                                </Button>
                            } {...tabProps(tabIndexes.engagementsTab)} />
                        }
                        {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE]) &&
                            <Tab label={
                                <Button startIcon={<AssignmentIndIcon />} color="secondary" onClick={() => { }}>
                                {isInternal? "Internal Allocations" : "Engagement Allocations"}
                                </Button>
                            } {...tabProps(tabIndexes.allocationsTab)} />
                        }
                    </Tabs>
                </AppBar>
                {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE, Privileges.MARKETING_EVENT_CREATE_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_CREATE_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.newEngagementTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <EngagementForm isInternalEngagement={Boolean(isInternal)} formTitle={isInternal? "New Internal Engagement / Event" : "New Customer Engagement"} setTab={setTab} setMessageBar={setMessageBar} />
                            </Grid>
                        </div>}>

                    </TabPanel>
                }
                {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENTS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENTS_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.engagementsTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <Engagements isInternalEngagement={Boolean(isInternal)} />
                            </Grid>
                        </div>}>
                    </TabPanel >
                }
                {isAccessGranted(isInternal ? [Privileges.INTERNAL_ENGAGEMENT_ALLOCATIONS_PAGE] : [Privileges.CUSTOMER_ENGAGEMENT_ALLOCATIONS_PAGE]) &&

                    <TabPanel value={tab} index={tabIndexes.allocationsTab}
                        children={<div className={classes.page}>
                            <Grid container spacing={3}>
                                <EngagementAllocations isInternalEngagement={Boolean(isInternal)}/>
                            </Grid>
                        </div>}>
                    </TabPanel >
                }

                <Portal>
                    <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                        <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </Portal>

            </Paper>
        </React.Fragment>
    );
};

export default EngagementNew;