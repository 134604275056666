import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import FundingSourcePanelItem from "./FundingSourcePanelItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    formControlHalf: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
}));

const FundingSource = ({ engagementType, businessUnits, productUnits, regions, subRegions, addedFundingSources, setAddedFundingSources, onFundingSourceChange, isSubmitBtnClicked }) => {
    const classes = useStyles();

    const handleAddClick = () => {
        setAddedFundingSources([...addedFundingSources, { businessUnit: "", productUnit: "", region: "", subRegion: "", percentage: "" }]);
    };

    const handleRemoveClick = (index) => {
        const list = [...addedFundingSources];
        list.splice(index, 1);
        setAddedFundingSources(list);
    };

    return (
        <Fragment>

            {addedFundingSources.map((option, index) => (
                index === 0 ? (
                    <FundingSourcePanelItem option={option} index={index} businessUnits={businessUnits} productUnits={productUnits} regions={regions} subRegions={subRegions} addedFundingSources={addedFundingSources} engagementType={engagementType} onFundingSourceChange={onFundingSourceChange} handleRemoveClick={handleRemoveClick} handleAddClick={handleAddClick} isSubmitBtnClicked={isSubmitBtnClicked} />
                ) : (
                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="subtitle1" align="left" className={classes.root}>
                            </Typography>

                            <FundingSourcePanelItem option={option} index={index} businessUnits={businessUnits} productUnits={productUnits} regions={regions} subRegions={subRegions} addedFundingSources={addedFundingSources} engagementType={engagementType} onFundingSourceChange={onFundingSourceChange} handleRemoveClick={handleRemoveClick} handleAddClick={handleAddClick} isSubmitBtnClicked={isSubmitBtnClicked} />

                        </Grid>
                    </Fragment>
                )
            ))}


        </Fragment>
    )
}

export default FundingSource;