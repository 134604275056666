import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';

import { getPartnerConsultantEmail } from '../utils/utils';
import AllocationsTimeline from './AllocationsTimeline';
import { AppConfig } from '../../Config';
import EngagementDataPanel from "../engagements/EngagementDataPanel";
import useHttp from '../utils/http';
import AllocationsHeaderAction from './AllocationsHeaderAction';
import timezones from 'timezones-list';
import AllocationsTimelineExtended from './AllocationsTimelineExtended';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '10%',
        minWidth: 100,
    },
    flexGrid: {
        flexGrow: 1,
    },
    page: {
        padding: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        alignItems: 'center',
    },
    typeItem: {
        minWidth: 250,
    },
    createNewButton: {
        paddingRight: 8,
        paddingLeft: 8,
    },
    heading: {
        width: '100%',
        textAlign: 'left',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    paddedGrid: {
        paddingLeft: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    btnControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControlTimezone: {
        margin: theme.spacing(1),
        marginBottom: 0,
        minWidth: 300,
    }
}));

const AllocationsByPartner = ({ isInternalEngagement, isMarketingEvent, timelineStartDate, timelineEndDate, engStartDate, engEndDate, requestStatus, onClickAddBtn, allocationsItemListPartner, setAllocationsItemListPartner, isEngagementDataPanelAvail, data, header, headerActionBtnVisible, getAllocationsByEmail, onClickUpdateRequestStatus, isTimelineTimeZoneEnabled = false, timelineTimeZoneObj, handleTimelineTimeZoneChange, setTimelineStartDate, setTimelineEndDate, setOpenBackDropProgress, setMessageBar }) => {
    const classes = useStyles();
    const { handleRequest } = useHttp();
    let timer;

    const [selectedPartner, setSelectedPartner] = useState("");
    const [emails, setEmails] = useState([{}]); // Emails shown in timeline
    const [partners, setPartners] = useState([]);
    const [partnerConsultants, setPartnerConsultants] = useState([]);
    const [isTimelineRefreshed, setIsTimelineRefreshed] = useState(false);

    const getPartners = useCallback(async () => {
        let partnersList = [], partnerConsultantsList = [], partner, partnerConsultantEmail;

        const endPointUrl = AppConfig.baseUrl + AppConfig.getActivePartners;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                partnerConsultantEmail = getPartnerConsultantEmail(item.partner, item.firstName, item.lastName);

                partner = {
                    partner: item.partner,
                    title: partnerConsultantEmail,
                    id: partnerConsultantEmail
                }

                partnerConsultantsList.push(partner);

                if (!partnersList.includes(item.partner)) {
                    partnersList.push(item.partner);
                }
            });

            setPartnerConsultants(partnerConsultantsList);
            setPartners(partnersList);
        });
    }, []);

    const handleStartDateChange = (date) => {
        setTimelineStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setTimelineEndDate(date);
    };

    useEffect(() => {
        getPartners();
    }, [getPartners]);

    const onSelectedPartnerChange = (event, value) => {
        setSelectedPartner(value);

        let consultants = partnerConsultants.filter(item => item.partner === value);

        setEmails(consultants);
        setAllocationsItemListPartner([]);

        getAllocationsByEmail(consultants.map(email => email.id), timelineStartDate, timelineEndDate, [], undefined, true);

    };

    const onRefreshTimeline = (startDate, endDate) => {
        setIsTimelineRefreshed(true);
        setOpenBackDropProgress(true);

        if (emails && emails.length > 0 && Object.keys(emails[0]).length !== 0) {
            getAllocationsByEmail(emails.map(email => email.id), startDate, endDate, [], () => {
                setIsTimelineRefreshed(false);
            }, true);
        } else {
            timer = setTimeout(() => {
                setIsTimelineRefreshed(false);
                setOpenBackDropProgress(false);
            }, 100)
        }
    };

    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    return (
        <React.Fragment>
            {header &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" gutterBottom>
                            {header}
                            {headerActionBtnVisible &&
                                <AllocationsHeaderAction isInternalEngagement={isInternalEngagement} requestStatus={requestStatus} onClickUpdateRequestStatus={onClickUpdateRequestStatus} />
                            }
                        </Typography>
                    </Grid>
                </Grid>
            }

            {isEngagementDataPanelAvail &&
                <EngagementDataPanel data={data} isInternalEngagement={isInternalEngagement} isMarketingEvent={isMarketingEvent} />
            }
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormControl className={classes.formControl}>
                    <Autocomplete
                        id="partner-outlined"
                        options={partners}
                        getOptionLabel={(option) => option}
                        value={selectedPartner}
                        onChange={onSelectedPartnerChange}
                        // getOptionDisabled={(option) => selectedPartners.includes(option)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search by Partner"
                            />
                        )}
                    />
                </FormControl>
            </Grid>

            <AllocationsTimelineExtended timelineStartDate={timelineStartDate} timelineEndDate={timelineEndDate} engStartDate={engStartDate} engEndDate={engEndDate} onClickAddBtn={onClickAddBtn} groups={emails} items={allocationsItemListPartner} isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled} timezones={timezones} timelineTimeZoneObj={timelineTimeZoneObj} handleTimelineTimeZoneChange={handleTimelineTimeZoneChange} sidebarWidth={400}
                isSearchByDatesEnabled={true} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} onRefreshTimeline={onRefreshTimeline} isTimelineRefreshed={isTimelineRefreshed} setMessageBar={setMessageBar} />

        </React.Fragment>
    );
};

export default AllocationsByPartner;