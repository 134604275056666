import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { AppConfig, DATE_DISPLAY_FORMAT } from '../../Config';
import useHttp from '../utils/http';
import { useAllocationTypes } from "../DataContext";
import OrgStructureDropdowns from '../OrgStructureDropdowns';
import ConsultantAllocationsTable from './ConsultantAllocationsTable';
import { formatDateWithDashes, workdayCount, isEmptyObj, getDateObjectFromString, getRoundOffNumber, isEngineeringAlloc } from '../utils/utils';
import { engineerDesignationsForRnd } from '../utils/AppData';
import { useOrgStructure } from '../OrgStructureContext';
import { LEAVE_ENG_CODE, NO_VAL_STR } from '../utils/AppConstants';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: '0px 3px 12px #00000017',
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '90%',

    },
    page: {
        padding: 20,
        flexGrow: 1,
    },
}));

const ConsultantAllocations = () => {
    const classes = useStyles();
    const { handleRequest, handleComponentEncodedRequest } = useHttp();
    let allocationTypes = useAllocationTypes();
    let { loadingErrMsg } = useOrgStructure();

    const MILLISECONDS_FOR_DAY = 1000 * 60 * 60 * 24;
    const MILLISECONDS_FOR_HOUR = 1000 * 60 * 60;
    const HOURS_FOR_DAY = 8;

    const initialSearchFiltersState = {
        email: "",
        startDate: null,
        endDate: null,
        allocationTypes: [],
        bu: "",
        department: "",
        team: ""
    };

    const [searchFilters, setSearchFilters] = useState(initialSearchFiltersState);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    const [emails, setEmails] = useState([]);
    const [allocations, setAllocations] = useState([]);
    const [defaultAllocationsForTeams, setDefaultAllocationsForTeams] = useState({});
    const [engagementCodeData, setEngagementCodeData] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("error");

    const getEmails = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployees;
        const params = [`employeeStatuses=${encodeURIComponent("Active,Marked Leaver")}`, `excludedEmploymentTypes=Internship`].join('&');
        const requestUrl = `${endPointUrl}?${params}`;

        handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
            let emailOptions = [];

            data.forEach(dataItem => {
                emailOptions.push({ id: dataItem.workEmail, title: dataItem.workEmail })
            })

            setEmails(emailOptions);
        });
    }, []);

    const getAllocationsForReport = useCallback(async (searchFilters) => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationsForReport;
        const params = [
            `email=${searchFilters.email && encodeURIComponent(searchFilters.email.id)}`,
            `startDate=${formatDateWithDashes(searchFilters.startDate)}`,
            `endDate=${formatDateWithDashes(searchFilters.endDate)}`,
            `businessUnit=${searchFilters.bu && searchFilters.bu !== "-1" ? encodeURIComponent(searchFilters.bu) : ""}`,
            `department=${searchFilters.department && searchFilters.department !== "-1" ? encodeURIComponent(searchFilters.department) : ""}`,
            `team=${searchFilters.team && searchFilters.team !== "-1" ? encodeURIComponent(searchFilters.team) : ""}`
        ].join('&');

        let requestUrl = `${endPointUrl}?${params}`;
        let searchStartDate = new Date(searchFilters.startDate.setHours(0, 0, 0, 0));
        let searchEndDate = new Date(searchFilters.endDate.setHours(0, 0, 0, 0));

        if (searchFilters.allocationTypes && searchFilters.allocationTypes.length > 0) {
            let allocTypesParam = '';

            searchFilters.allocationTypes.forEach(typeObj => {
                if (typeObj) {
                    allocTypesParam = allocTypesParam + `&allocTypes=${typeObj.id}`
                }
            })

            requestUrl = requestUrl + allocTypesParam;
        }

        setIsDataLoading(true);
        setIsSearchDisabled(true);

        handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
            let engagementAllocations = {};
            let allocation;

            data.forEach(item => {
                allocation = item;

                if (!engagementAllocations[item.email]) {
                    engagementAllocations[item.email] = [];
                }

                engagementAllocations[item.email].push(allocation);
            });

            let reportDataArray = [];
            let timePeriodInDays = Math.floor((searchEndDate - searchStartDate) / MILLISECONDS_FOR_DAY + 1); // No. of dates with Start / End dates included (hence time_difference + 1)
            let timePeriodInWorkingDays = Math.floor(workdayCount(searchStartDate, searchEndDate));
            // let count = 1;

            let department = "";
            let businessUnit = "";
            let company = "";
            let engagementBusinessUnit = "";
            let engagementSalesPOD = "";

            // Calculate date counts for the users, who DO HAVE allocations in the given time period
            for (const [email, allocationsArr] of Object.entries(engagementAllocations)) {
                let totalDaysForEngineer = 0;
                let totalWorkingDaysForEngineer = 0;

                // let allocations = allocationsArr;
                let formattedAllocation = {};

                department = allocationsArr[0].department;
                businessUnit = allocationsArr[0].businessUnit;
                company = allocationsArr[0].company;

                allocationsArr.forEach(allocation => {
                    let start, end; // Dates for days calculation. start is the nearest date from the allocation start date and search start date. end is the farthest date from allocation end date and search end date
                    let isRecurring = allocation.isRecurring === "Yes" ? true : false;
                    let workingHoursPerDay = 0;

                    // Currently, engagement Business Unit and Sales POD are shown only if there's only one funding source
                    if (allocation.fundingSources && allocation.fundingSources.length === 1) {
                        engagementBusinessUnit = allocation.fundingSources[0].businessUnit;
                        engagementSalesPOD = allocation.fundingSources[0].subRegion;
                    }

                    formattedAllocation.engagementNature = allocation.engagementNature === null ? (isEngineeringAlloc(allocation.engagementId) ? 'N/A' : 'NOT_DEFINED') : allocation.engagementNature;
                    formattedAllocation.engagementBusinessUnit = engagementBusinessUnit === null ? (isEngineeringAlloc(allocation.engagementId) ? 'N/A' : 'NOT_DEFINED') : engagementBusinessUnit;
                    formattedAllocation.engagementSalesPOD = engagementSalesPOD === null ? (isEngineeringAlloc(allocation.engagementId) ? 'N/A' : 'NOT_DEFINED') : engagementSalesPOD;

                    let employeeLocation = allocation.location === 'UK' ? 'United Kingdom' : allocation.location === 'US' ? 'United States of America' : allocation.location;

                    formattedAllocation.country = allocation.country === null ? employeeLocation : allocation.country;

                    let initialEngagementCode = allocation.initialEngagementCode;
                    let engagementCode = allocation.engagementCode;
                    let allocationTypeName = allocation.allocationTypeName;
                    let engagementTypeName = allocation.engagementTypeName ? allocation.engagementTypeName : "";

                    if (initialEngagementCode) {
                        engagementCode = initialEngagementCode;
                    } else if (!engagementCode) {
                        engagementCode = allocation.defaultEngagementCodeForAllocType;
                    }

                    // If the consultant is on leave, for the report, it is considered as he's on his default allocation
                    if (engagementCode === LEAVE_ENG_CODE) {
                        let defaultAllocData = getDefaultAllocationTypeAndEngagementCode(defaultAllocationsForTeams, allocation.businessUnit, allocation.department, allocation.team);
                        engagementCode = defaultAllocData.defaultEngagementCode;
                        allocationTypeName = defaultAllocData.defaultAllocType;
                        engagementTypeName = defaultAllocData.engagementTypeName;
                    }

                    start = getDateObjectFromString(allocation.startDate) < searchStartDate ? searchStartDate : getDateObjectFromString(allocation.startDate);
                    end = getDateObjectFromString(allocation.endDate) > searchEndDate ? searchEndDate : getDateObjectFromString(allocation.endDate);

                    let numOfDays = Math.floor((end - start) / MILLISECONDS_FOR_DAY + 1);
                    let numOfWorkingDays = Math.floor(workdayCount(start, end));
                    let calendarHours = 0;
                    let workingHours = 0;
                    let totalDaysOfHourly = 0;
                    let totalWorkingDaysOfHourly = 0;

                    if (isRecurring && allocation.startTime && allocation.endTime) {
                        let today = new Date();

                        let startTime = today.setHours(allocation.startTime.split(':')[0], allocation.startTime.split(':')[1], 0);
                        let endTime = today.setHours(allocation.endTime.split(':')[0], allocation.endTime.split(':')[1], 0);

                        workingHoursPerDay = (endTime - startTime) / MILLISECONDS_FOR_HOUR;

                        calendarHours = numOfDays * workingHoursPerDay;
                        workingHours = numOfWorkingDays * workingHoursPerDay;
                    }

                    if (isRecurring) {
                        totalDaysOfHourly = getRoundOffNumber(calendarHours / HOURS_FOR_DAY);
                        totalWorkingDaysOfHourly = getRoundOffNumber(workingHours / HOURS_FOR_DAY);

                        totalDaysForEngineer += totalDaysOfHourly;
                        totalWorkingDaysForEngineer += totalWorkingDaysOfHourly;
                    } else {
                        totalDaysForEngineer += numOfDays;
                        totalWorkingDaysForEngineer += numOfWorkingDays;
                    }

                    formattedAllocation.engagementCode = engagementCode;
                    formattedAllocation.allocationTypeName = allocationTypeName;


                    if (engagementCode !== 'LEFT') {
                        reportDataArray.push({
                            ...formattedAllocation,
                            engagementTypeName: engagementTypeName,
                            email: allocation.email,
                            department: allocation.department,
                            businessUnit: allocation.businessUnit,
                            company: allocation.company,
                            calendarDays: isRecurring ? totalDaysOfHourly : numOfDays,
                            workingDays: isRecurring ? totalWorkingDaysOfHourly : numOfWorkingDays,
                            dailyHourly: isRecurring ? "Hourly" : "Daily",
                            workingHours: isRecurring ? workingHours : "",
                            isRecurring: isRecurring
                        });
                        // count++;
                    }
                });

                // Adding remaining days of the employee to default allocation
                if ((!searchFilters.allocationTypes || searchFilters.allocationTypes.length === 0) && allocationsArr.length > 0 && (totalDaysForEngineer < timePeriodInDays)) {
                    let defaultVals = getDefaultAllocationTypeAndEngagementCode(defaultAllocationsForTeams, allocationsArr[allocationsArr.length - 1].businessUnit, allocationsArr[allocationsArr.length - 1].department, allocationsArr[allocationsArr.length - 1].team);

                    reportDataArray.push({
                        engagementTypeName: defaultVals.engagementTypeName ? defaultVals.engagementTypeName : "",
                        engagementNature: defaultVals.engagementNature,
                        country: defaultVals.engagementCountry,
                        allocationTypeName: defaultVals.defaultAllocType,

                        // email: allocation.email,
                        // department: allocation.department,
                        // businessUnit: allocation.businessUnit,
                        // company: allocation.company,

                        email: email,
                        department: department,
                        businessUnit: businessUnit,
                        company: company,

                        engagementCode: defaultVals.defaultEngagementCode,
                        engagementBusinessUnit: defaultVals.engagementBusinessUnit,
                        engagementSalesPOD: defaultVals.engagementSalesPOD,
                        calendarDays: getRoundOffNumber(timePeriodInDays - totalDaysForEngineer),
                        workingDays: getRoundOffNumber(timePeriodInWorkingDays - totalWorkingDaysForEngineer),
                        dailyHourly: "Daily",
                        isRecurring: false,
                        workingHours: ""
                    });

                    // count++;
                }
            };

            // If the below conditions are true, that means no engagements are found for the given employee. Therefore adding
            // given time period to default allocation type.
            // (Note that if the allocation type is given as a search criteria, no need to add to the default allocation type)
            if (searchFilters.email && isEmptyObj(engagementAllocations) && (!searchFilters.allocationTypes || searchFilters.allocationTypes.length === 0)) {
                const endPointUrl = AppConfig.baseUrl + AppConfig.getEmployeeDataByEmail.replace("$email", searchFilters.email.id);

                handleRequest(endPointUrl, 'GET', null, (employeeData) => {
                    let employeeFinalDate = employeeData && employeeData.finalDayOfEmployment && getDateObjectFromString(employeeData.finalDayOfEmployment);

                    if (employeeFinalDate > searchStartDate) {
                        if (employeeFinalDate < searchEndDate) {
                            timePeriodInDays = Math.floor((employeeFinalDate - searchStartDate) / MILLISECONDS_FOR_DAY + 1);
                            timePeriodInWorkingDays = Math.floor(workdayCount(searchStartDate, employeeFinalDate));
                        }

                        if (data) {
                            let defaultVals = getDefaultAllocationTypeAndEngagementCode(defaultAllocationsForTeams, employeeData.businessUnit, employeeData.department, employeeData.team);

                            reportDataArray.push({
                                engagementTypeName: defaultVals.engagementTypeName ? defaultVals.engagementTypeName : "",
                                engagementNature: defaultVals.engagementNature,
                                country: defaultVals.engagementCountry,
                                allocationTypeName: defaultVals.defaultAllocType,
                                email: searchFilters.email.id,
                                department: employeeData.department,
                                businessUnit: employeeData.businessUnit,
                                company: employeeData.company,
                                engagementCode: defaultVals.defaultEngagementCode,
                                engagementBusinessUnit: defaultVals.engagementBusinessUnit,
                                engagementSalesPOD: defaultVals.engagementSalesPOD,
                                calendarDays: timePeriodInDays,
                                workingDays: timePeriodInWorkingDays,
                                dailyHourly: "Daily",
                                isRecurring: false,
                                workingHours: ""
                            });
                            // count++;
                        }
                    }

                    groupByAllocation(reportDataArray, engagementCodeData);

                }, () => {
                    setMessageBar("An Error occurred in loading some allocations for the report.", true, "error");
                });

                // If an email and allocation type is not provided,
                // Calculate date counts for the users, who DO NOT HAVE allocations in the given time period and add them to
                // default allocation
            } else if (!searchFilters.email && (!searchFilters.allocationTypes || searchFilters.allocationTypes.length === 0)) {
                const endPointUrl = AppConfig.baseUrl + AppConfig.getNonAllocatedEngineerData;
                const params = [
                    `startDate=${formatDateWithDashes(searchStartDate)}`, 
                    `endDate=${formatDateWithDashes(searchEndDate)}`, 
                    `businessUnit=${searchFilters.bu && searchFilters.bu !== "-1" ? encodeURIComponent(searchFilters.bu) : ""}`,
                    `department=${searchFilters.department && searchFilters.department !== "-1" ? encodeURIComponent(searchFilters.department) : ""}`,
                    `team=${searchFilters.team && searchFilters.team !== "-1" ? encodeURIComponent(searchFilters.team) : ""}`
                ].join("&");

                let requestUrl = `${endPointUrl}?${params}`;

                handleComponentEncodedRequest(requestUrl, 'GET', null, (data) => {
                    if (data) {
                        data.forEach(employee => {
                            // TODO: Check the possibility of adding "engineerDesignationsForRnd" to the query from backend
                            if (engineerDesignationsForRnd.includes(employee.jobRole)) {
                                let defaultVals = getDefaultAllocationTypeAndEngagementCode(defaultAllocationsForTeams, employee.businessUnit, employee.department, employee.team);

                                reportDataArray.push({
                                    engagementTypeName: defaultVals.engagementTypeName ? defaultVals.engagementTypeName : "",
                                    engagementNature: defaultVals.engagementNature,
                                    country: defaultVals.engagementCountry,
                                    allocationTypeName: defaultVals.defaultAllocType,
                                    email: employee.workEmail,
                                    department: employee.department,
                                    businessUnit: employee.businessUnit,
                                    company: employee.company,
                                    engagementCode: defaultVals.defaultEngagementCode,
                                    engagementBusinessUnit: defaultVals.engagementBusinessUnit,
                                    engagementSalesPOD: defaultVals.engagementSalesPOD,
                                    calendarDays: timePeriodInDays,
                                    workingDays: timePeriodInWorkingDays,
                                    dailyHourly: "Daily",
                                    isRecurring: false,
                                    workingHours: ""
                                });

                                // count++;
                            }
                        });

                        groupByAllocation(reportDataArray, engagementCodeData);
                    }
                }, () => {
                    setMessageBar("An Error occurred in loading some allocations for the report.", true, "error");
                });
            } else {
                groupByAllocation(reportDataArray, engagementCodeData);
            }
        }, () => {
            setMessageBar("An Error occurred in loading allocations for the report.", true, "error");
            setIsDataLoading(false);
            setIsSearchDisabled(false);
        });
    }, [defaultAllocationsForTeams, engagementCodeData]);

    const groupByAllocation = async (reportDataArray, engagementCodeData) => {
        let groupedReportDataArray = [];
        let alreadyEncountered = [];
        let uniqueKey, groupedItem, engagementCode;

        reportDataArray.forEach((item, i) => {
            uniqueKey = [item.engagementTypeName, item.allocationTypeName, item.email, item.engagementCode, item.isRecurring ? "hourly" : "daily"].join('~');

            if (!alreadyEncountered.includes(uniqueKey)) {
                alreadyEncountered.push(uniqueKey);

                groupedItem = { ...item };

                reportDataArray.forEach((itemL2, j) => {
                    if (i !== j && (uniqueKey === [itemL2.engagementTypeName, itemL2.allocationTypeName, itemL2.email, itemL2.engagementCode, itemL2.isRecurring ? "hourly" : "daily"].join('~'))) {

                        groupedItem.calendarDays = getRoundOffNumber(groupedItem.calendarDays + itemL2.calendarDays);
                        groupedItem.workingDays = getRoundOffNumber(groupedItem.workingDays + itemL2.workingDays);
                        groupedItem.workingHours = groupedItem.workingHours + itemL2.workingHours;
                    }
                });

                engagementCode = item.engagementCode;

                if (engagementCode !== 'NOT_DEFINED') {
                    let engCodeData = getMappingEngCodeData(engagementCodeData, engagementCode);
                    if (engCodeData) {
                        groupedItem.engagementGroup = engCodeData.engGroup ? engCodeData.engGroup : 'NOT AVAILABLE';
                        groupedItem.accountName = engCodeData.accountName ? engCodeData.accountName : 'NOT AVAILABLE';
                        groupedItem.glCode = engCodeData.glCode ? engCodeData.glCode : 'NOT AVAILABLE';
                    } else {
                        groupedItem.engagementGroup = 'NOT AVAILABLE';
                        groupedItem.accountName = 'NOT AVAILABLE';
                        groupedItem.glCode = 'NOT AVAILABLE';
                    }

                    groupedReportDataArray.push(groupedItem);
                } else {
                    groupedItem.engagementGroup = 'NOT AVAILABLE';
                    groupedItem.accountName = 'NOT AVAILABLE';
                    groupedItem.glCode = 'NOT AVAILABLE';

                    groupedReportDataArray.push(groupedItem);
                }
            }
        });

        let unsortedArr = [
            ...allocations,
            ...groupedReportDataArray
        ];

        setAllocations(unsortedArr.sort((a, b) => -a.engagementTypeName.localeCompare(b.engagementTypeName)));
        setIsDataLoading(false);
        setIsSearchDisabled(false);
    }

    // If there is an exact match for the engagement code, return the data
    // If there is no exact match, check if the "-" is present in the 6th position and if the 7th and 8th position matches,
    // then return the data. This is to handle the case where the exact engagement code is not present in the engagement code data
    const getMappingEngCodeData = (engagementCodeData, engagementCode) => {
        if (engagementCodeData[engagementCode]) {
            return engagementCodeData[engagementCode];
        }

        for (const engCode in engagementCodeData) {
            if (engCode.indexOf("-", 5) === 5 && engCode.substring(6, 8) === engagementCode.substring(6, 8)) {
                return engagementCodeData[engCode];
            } 
        }
    };

    const getEngagementCodeData = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getEngagementCodeData;
        let glCodeData = {};

        return handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                if (item.engagementCode) {
                    glCodeData[item.engagementCode] = item;
                }
            });

            setEngagementCodeData(glCodeData);
        }, () => {
            setMessageBar("An error occurred while retrieving engagement code groups / gl codes", true, "error");
        })
    }, []);

    const getDefaultAllocationsForTeams = useCallback(async () => {
        const endPointUrl = AppConfig.baseUrl + AppConfig.getDefaultAllocations;
        let defaultAllocations = {};
        let businessUnit, department, team;

        handleRequest(endPointUrl, 'GET', null, (data) => {
            data.forEach(item => {
                if (item) {
                    // If we are to have default allocations in BU / Department level also, then we'll need this implementation
                    // of using a string to represent the null/empty value, so that the map can be created properly
                    // This is to handle such future scenarios too
                    businessUnit = item.businessUnit ? item.businessUnit.toUpperCase() : NO_VAL_STR;
                    department = item.department ? item.department?.toUpperCase() : NO_VAL_STR;
                    team = item.team ? item.team?.toUpperCase() : NO_VAL_STR;
    
                    if (!defaultAllocations[businessUnit]) {
                        defaultAllocations[businessUnit] = {};
                    }
    
                    if (!defaultAllocations[businessUnit][department]) {
                        defaultAllocations[businessUnit][department] = {};
                    }
    
                    if (!defaultAllocations[businessUnit][department][team]) {
                        defaultAllocations[businessUnit][department][team] = {
                            defaultEngagementCode: item.defaultEngagementCode,
                            defaultAllocationType: item.defaultAllocTypeName,
                            engagementTypeName: item.engagementTypeName,
                        }
                    }
                }
            });

            setDefaultAllocationsForTeams(defaultAllocations);
        }, () => {
            setMessageBar("An error occurred while retrieving default allocations data for team", true, "error");

            getAllocationsForReport(getInitialSearchFilterState());
        })
    });

    const getDefaultAllocationTypeAndEngagementCode = (defaultAllocationsForTeams, businessUnit, department, team) => {
        // If we have employees without a BU / Dept / Team, we need to check if there's a default allocation for that scenario
        let buStrUpperCase = businessUnit ? businessUnit.toUpperCase() : NO_VAL_STR;
        let deptStrUpperCase = department ? department.toUpperCase() : NO_VAL_STR;
        let teamStrUpperCase = team ? team.toUpperCase() : NO_VAL_STR;

        if (defaultAllocationsForTeams[buStrUpperCase] && defaultAllocationsForTeams[buStrUpperCase][deptStrUpperCase] && defaultAllocationsForTeams[buStrUpperCase][deptStrUpperCase][teamStrUpperCase]) {
            let defaultAllocationData = defaultAllocationsForTeams[buStrUpperCase][deptStrUpperCase][teamStrUpperCase];

            return {
                defaultAllocType: defaultAllocationData.defaultAllocationType ? defaultAllocationData.defaultAllocationType : 'NOT_DEFINED',
                defaultEngagementCode: defaultAllocationData.defaultEngagementCode ? defaultAllocationData.defaultEngagementCode : 'NOT_DEFINED',
                engagementTypeName: defaultAllocationData.engagementTypeName ? defaultAllocationData.engagementTypeName : 'NOT_DEFINED',
                engagementNature: 'N/A',
                engagementCountry: 'N/A',
                engagementBusinessUnit: 'N/A',
                engagementSalesPOD: 'N/A'
            }
        } else {
            return {
                defaultAllocType: 'NOT_DEFINED',
                defaultEngagementCode: 'NOT_DEFINED',
                engagementTypeName: 'NOT_DEFINED',
                engagementNature: 'N/A',
                engagementCountry: 'N/A',
                engagementBusinessUnit: 'N/A',
                engagementSalesPOD: 'N/A'
            }
        }
    };

    const getInitialSearchFilterState = () => {
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        const initialSearchFiltersState = {
            email: "",
            startDate: startDate,
            endDate: endDate,
            allocationTypes: [],
            bu: "",
            department: "",
            team: ""
        };

        return initialSearchFiltersState;
    }

    const validateSearchFields = (filters) => {
        let isValid = true;
        let msg = "";

        if (isNaN(filters.startDate) || isNaN(filters.endDate)) {
            isValid = false;
            msg = "Start/End dates should be valid dates"
        } else if (!filters.startDate) {
            isValid = false;
            msg = "Start date cannot be empty"
        } else if (!filters.endDate) {
            isValid = false;
            msg = "End date cannot be empty"
        } else if (filters.startDate.getTime() > filters.endDate.getTime()) {
            isValid = false;
            msg = "End Date should be greater than Start Date"
        }

        if (!isValid) {
            setMessageBar(msg, true, 'error');
        }

        return isValid;
    }

    useEffect(() => {
        getEmails();
        getDefaultAllocationsForTeams();
        setSearchFilters(getInitialSearchFilterState());
        getEngagementCodeData();
    }, []);

    useEffect(() => {
        if (Object.keys(defaultAllocationsForTeams)?.length > 0 && Object.keys(engagementCodeData)?.length > 0) {
            getAllocationsForReport(getInitialSearchFilterState());
        }
    }, [defaultAllocationsForTeams, engagementCodeData]);

    useEffect(() => {
        if (loadingErrMsg) {
            setMessageBar(loadingErrMsg, true, 'error');
        }
    }, [loadingErrMsg])

    const setMessageBar = (message, isOpen, type) => {
        setAlertMessage(message);
        setIsMessageBarOpen(isOpen);
        setAlertMessageType(type);
    };

    const handleMessageBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsMessageBarOpen(false);
        setAlertMessage("");
    };

    const onEmailChange = (event, value) => {
        if (value) {
            setSearchFilters({
                ...searchFilters,
                email: value
            })
        }
    };

    const handleStartDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            startDate: date
        })
    };

    const handleEndDateChange = (date) => {
        setSearchFilters({
            ...searchFilters,
            endDate: date
        })
    };

    const onAllocationTypeChange = (event, values) => {
        setSearchFilters({
            ...searchFilters,
            allocationTypes: values
        })
    };

    const onSearchClick = () => {
        if (validateSearchFields(searchFilters)) {
            setAllocations([]);
            getAllocationsForReport(searchFilters);
        }
    }

    const resetFilters = () => {
        setSearchFilters(getInitialSearchFilterState());
    };

    return (
        <React.Fragment>
            <Portal>
                <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                    <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Portal>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" display="block" gutterBottom>
                        Consultant Allocations
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper square className={classes.page}>
                        <Grid container spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl} variant="outlined" size="small">
                                        <Autocomplete
                                            id="email-outlined"
                                            options={emails}
                                            getOptionLabel={(option) => option.title}
                                            value={searchFilters.email}
                                            onChange={onEmailChange}
                                            size="small"
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="By Email"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            size="small"
                                            format={DATE_DISPLAY_FORMAT}
                                            className={classes.formControl}
                                            label="Start Date"
                                            id="date-picker-inline"
                                            value={searchFilters.startDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            error={!searchFilters.startDate || isNaN(searchFilters.startDate)}
                                            autoOk={true}
                                            okLabel=''
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            inputVariant="outlined"
                                            size="small"
                                            format={DATE_DISPLAY_FORMAT}
                                            className={classes.formControl}
                                            label="End Date"
                                            id="date-picker-inline"
                                            value={searchFilters.endDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            error={!searchFilters.endDate || isNaN(searchFilters.endDate)}
                                            autoOk={true}
                                            okLabel=''
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <Autocomplete
                                            options={allocationTypes.sort((a, b) => -b.engagementTypeName.localeCompare(a.engagementTypeName))}
                                            groupBy={(option) => option.engagementTypeName}
                                            getOptionLabel={(option) => option.allocationTypeName}
                                            id="alloc-type"
                                            size="small"
                                            value={searchFilters.allocationTypes}
                                            multiple
                                            onChange={onAllocationTypeChange}
                                            renderInput={(params) => <TextField {...params} label="By Allocation Types" variant="outlined" />}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <OrgStructureDropdowns dropdownSize={3} size="small" selectedValues={searchFilters} setSelectedValues={setSearchFilters} isPartnerIncluded={true} />

                                <Grid item xs={1}>
                                    <FormControl className={classes.formControl}>
                                        <Button variant="contained" size="medium" color="secondary" disabled={isSearchDisabled} onClick={() => { onSearchClick() }} >
                                            Search
                                        </Button>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={1}>
                                    <FormControl className={classes.formControl} style={{ marginLeft: 80 }}>
                                        <Button variant="contained" size="medium" color="primary" onClick={() => { resetFilters() }}>
                                            Reset
                                        </Button>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <ConsultantAllocationsTable data={allocations} isLoading={isDataLoading} />
                </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default ConsultantAllocations;